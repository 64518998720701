import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
import TipTapEditor from './editor/TipTapEditor.js';

import { site } from '../config/config'
import { parseValue } from '../helpers/report_helper';

var writtenNumber = require('written-number');
var Articles = require('articles')






// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

class TherapistPatientReport extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	content: null,
      delete_confirm: false 
    }
    this.loginToken = this.loginToken.bind(this);
    this.savePatient = this.savePatient.bind(this);
    this.submit = this.submit.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.report = this.report.bind(this);
  }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
	savePatient(data) {
    let token = localStorage.getItem('token'),
        that = this;

   

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin/patient/' + that.props.match.params.patient_id;
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  saveFields() {
    let token = localStorage.getItem('token'),
        that = this;

   
    let data = {
      report_date: document.getElementById('report_date').value,
      report_status: document.getElementById('report_status').value
    }

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin/reports';
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  joinString(string, particle) {
    return [string.slice(0, -1).join(', '), string.slice(-1)[0]].join(string.length < 2 ? '' : ' '+particle+' ')
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!res.data) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
          let undef = 'undefined';
          let patient = parsePatient(res.data);
          let p = Object.assign({}, patient);
          let report_date = p.report_date;
          Object.keys(p).forEach(key => {
            if(key != 'report_date' && (typeof p[key] === 'undefined' || p[key] === null)) p[key] = undef;
            if(typeof p[key] === 'string' && p[key].trim() === '') p[key] = undef;
            if(typeof p[key] === 'string' && p[key].trim() === 'undefined') p[key] = undef;
          });
          let denies = [];
          let reports = [];
          let history = '';
          let map = {
            'social_history_trauma': 'trauma',
            'social_history_physical': 'physical abuse',
            'social_history_sexual': 'sexual abuse',
            'social_history_substance': 'substance abuse',
            'social_history_emotional': 'emotional abuse',
            'social_history_eating': 'eating disorders'
          };
          Object.keys(map).forEach(key => {
            if(patient[key] === 'reports') reports.push(map[key])
            if(patient[key] === 'denies') denies.push(map[key])
          })
          if(denies.length) {
            history += 'The patient denies any past or present '+that.joinString(denies, 'or')+'. ';
          }
          if(reports.length) {
            history += 'The patient reports a history of '+that.joinString(reports, 'and')+'. ';
          }

          let currently_seeing_nutritionist = '';

          if(p.currently_seeing_nutritionist == 'Yes') {
            currently_seeing_nutritionist = `The patient is currently consulting with a nutritionist or dietician. `;
          } else {
            currently_seeing_nutritionist = `The patient is not currently consulting with a nutritionist or dietician. `;
          }
            let test_results_keys = [
              'phq_15_raw_score',
              'phq_15_score',
              'gad_7_raw_score',
              'gad_7_score',
              'phq_9_raw_score',
              'phq_9_score',
              'anxiety_attacks',
              'dass_21_d_raw_score',
              'dass_21_d_adjusted_score',
              'dass_21_d_score',
              'dass_21_a_raw_score',
              'dass_21_a_adjusted_score',
              'dass_21_a_score',
              'dass_21_s_raw_score',
              'dass_21_s_adjusted_score',
              'dass_21_s_score',
              'eat_26_raw_score',
              'eat_26_score',
            ]
            let missing_results = [];
            test_results_keys.forEach(key => {
              if(!p.test_results || typeof p.test_results[key] === 'undefined') {
                p.test_results[key] = undef;
                missing_results[key] = true;
              }
            })
        
          
          let type_of_eater_denies = [];
          let type_of_eater_reports = [];
          let type_of_eater = '';
          let types_of_eater = p.type_of_eater ? p.type_of_eater.split(',') : [];

          console.log(types_of_eater);
          types_of_eater.push('lover of food eater');
          console.log(types_of_eater);
          p.types_of_eater.forEach(function(item) {
            if(types_of_eater.indexOf(item) !== -1) {
              type_of_eater_reports.push(item);
            } else {
              type_of_eater_denies.push(item);
            } 
          })
          if(type_of_eater_denies.length) {
            type_of_eater += 'The patient denies being a '+that.joinString(type_of_eater_denies, 'and')+'. ';
          }
          if(type_of_eater_reports.length) {
            type_of_eater += 'The patient reports being a '+that.joinString(type_of_eater_reports, 'and')+'. ';
          }
          let psych_factors = 'see no';
          if(patient.dass_psych_factors === 'see') psych_factors = 'see';
          if(patient.phq_psych_factors === 'see') psych_factors = 'see';

          let procedure = p.is_revision === 'yes' ? p.procedure.replace('Surgery','Revision') : p.procedure;

          let tobacco_quit_efforts = p.tobacco_quit_efforts;
          //let tobacco_quit_date = patient.tobacco_quit_date ? moment(p.tobacco_quit_date).format('MM/DD/YYYY') : undef;

          let defaultReport = `
            <h1>BEHAVIORAL ASSESSMENT BARIATRIC SURGERY</h1>
            <table className="fullwidth">
              <tbody>
                <tr>
                  <td>Patient Name</td>
                  <td>${p.first_name} ${p.last_name}</td>
                  <td>Date of Birth</td>
                  <td>${p.dob_string}</td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td>${p.age}</td>
                  <td>Date of Interview</td>
                  <td>${p.appointment_date_modified_nice}</td>
                </tr>
                <tr>
                  <td>Gender</td>
                  <td>${p.gender}</td>
                  <td>Date of Report</td>
                  <td>${report_date ? p.report_date : moment().format('MM/DD/YYYY')}</td>
                </tr>
                <tr>
                  <td>Surgery</td>
                  <td>${procedure}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p><u>Assessment Instruments:</u></p>
            <p><strong>PHQ-SADS | DASS-21 | EAT-26</strong></p>
            <p><u>Purpose of Assessment:</u> To determine, within the limits of psychological certainty: whether ${p.first_name} ${p.last_name} is comfortable with ${p.possessive} decision to proceed with bariatric surgery; whether the patient is prepared for the changes in diet which bariatric surgery will impose; whether the patient has reliable intimate relationships, supportive family dynamics and committed post-operative support following bariatric surgery; and whether the patient has sufficient information on which to base informed consent.</p>
            <p><u>Chief Complaint:</u> Obesity</p>
            <p><u>Client Background:</u> ${p.first_name} ${p.last_name} is a ${p.age} year old ${p.marital_status_display} ${p.gender_display} seeking weight loss surgery. The patient reports that ${p.possessive} highest level of education is ${Articles.articlize(p.education)}. The patient reports that ${p.pronoun} ${p.is_word} currently employed as ${Articles.articlize(p.occupation)}. The patient is ${p.height_feet} feet ${p.height_inches} inches tall and ${p.weight} pounds placing ${p.third} at a BMI of ${p.bmi.toFixed(2)}.</p>
            <p><u>Weight History:</u> The patient reports ${p.pronoun} ${p.has_word} struggled with ${p.possessive} weight${patient.weight_history ? ' '+p.weight_history : ''} and ${p.pronoun === 'they' ? 'believe' : 'believes'} that ${p.reasons} has led to ${p.possessive} current frame of ${p.weight} pounds. The patient states that ${p.pronoun} ${p.has_word} tried other ways to lose weight such as ${p.other_ways_to_lose_weight} but reports short-term success. The patient states that ${p.pronoun} ${p.is_word} uncomfortable in ${p.possessive} current situation and ${p.pronoun === 'they' ? 'want' : 'wants'} to be comfortable and healthy again.</p>
            <p><u>Eating Patterns:</u> The patient reports that breakfast is ${p.breakfast}. ${p.pronoun.toProperCase()} ${p.pronoun === 'they' ? 'report' : 'reports'} lunch is ${p.lunch}. ${p.pronoun.toProperCase()} ${p.pronoun === 'they' ? 'report' : 'reports'} that dinner is ${p.dinner}. The patient reports snacking on ${p.snacks} and drinking ${p.drinks}. ${p.pronoun.toProperCase()} ${p.pronoun === 'they' ? 'report' : 'reports'} eating ${p.portion_of_food}. ${type_of_eater}${currently_seeing_nutritionist}The patient states that ${p.pronoun} ${p.is_word} aware that ${p.possessive} eating patterns will need to change prior to surgery as well as post-surgery and states that ${p.pronoun} ${p.is_word} motivated to make these changes.</p>
            <p><u>Related Medical Issues:</u> The patient reports ${p.medical_issues}. Past surgeries include: ${p.past_surgeries}.</p>
            <p><u>Medication Compliance:</u> The patient reports that ${p.pronoun} ${p.how_often_miss_medications} miss${p.pronoun === 'they' ? '' : 'es'} or delay${p.pronoun === 'they' ? '' : 's'} medications. The patient remembers to take ${p.possessive} medication by ${p.how_remember_take_medications}. The patient reports ${p.compliant_conditions} to taking medications to manage any relevant chronic conditions.</p>
            <p><u>Family History of Obesity:</u> The patient ${p.family_history}.</p>
            <p><u>Social History:</u> ${history}${p.pronoun.toProperCase()} ${p.pronoun === 'they' ? 'state' : 'states'} ${p.pronoun} ${p.counseling_history} had counseling and ${p.pronoun === 'they' ? 'feel' : 'feels'} ${p.pronoun} ${p.has_word} the healthy coping skills and support system to address any issues should they arise either pre-op or post-op. ${patient.social_history_notes}. The patient consumes ${p.drinks_per_week} alcoholic drinks per week. The patient is aware of the recommendation to abstain from alcohol one year post-op.</p>
            <p><u>Personal History of Mental Illness:</u> The patient ${p.mental_illness} ${p.mental_illness === 'denies' ? 'any' : 'a'} personal history of mental illness. ${p.mental_illness === 'reports' ? ' '+p.mental_illness_diagnosis_medications : ''}${p.mental_illness === 'reports' ? ' The patient reports '+p.possessive+' symptoms '+p.mental_illness_symptoms_manageable+' well managed with the current medication regimen.' : ''} The patient ${p.suicidal_ideation} ${p.suicidal_ideation === 'denies' ? 'any past or present ' : ''}suicidal ideation.${p.suicidal_ideation.indexOf('reports') > -1 ? ' '+p.suicidal_ideation_notes : ''}</p>
            <p><u>Tobacco Use:</u> The patient ${p.tobacco_use_form} tobacco use. ${p.tobacco_use_form === 'reports historical' ? 'The patient quit smoking '+p.tobacco_quit_date : ''}${p.tobacco_use_form === 'reports current' ? 'The patient’s efforts to stop smoking include '+tobacco_quit_efforts : ''}</p>
            <p><u>PATIENT BEHAVIORAL ASSESSMENT SCORES</u></p>
            <p><u>Patient Health Questionnaire (PHQ-SADS):</u> The questionnaire is comprised of thirty-seven questions across three categories-somatic, anxiety and depression. The patient’s PHQ-15 score is ${missing_results.phq_15_raw_score ? undef : writtenNumber(p.test_results.phq_15_raw_score)} which indicates the level of the patient’s somatic symptom severity. The level of somatic symptom severity for this patient is ${p.phq_15_score ? p.phq_15_score.replace('Sympton','Symptom').toLowerCase() : p.test_results.phq_15_score.toLowerCase()}. The patient’s GAD-7 score is ${missing_results.gad_7_raw_score ? undef : writtenNumber(p.test_results.gad_7_raw_score)} which indicates the level of anxiety severity. The level of anxiety severity for this patient is ${p.gad_7_score ? p.gad_7_score.toLowerCase() : p.test_results.gad_7_score.toLowerCase()}. The patient ${p.panic_attacks} any past or present panic attacks. The patient’s PHQ-9 score is ${missing_results.phq_9_raw_score ? undef : writtenNumber(p.test_results.phq_9_raw_score)} which indicates the level of the patient’s depression severity. The level of depression severity of this patient is ${p.phq_9_score ? p.phq_9_score.toLowerCase() : p.test_results.phq_9_score.toLowerCase()}. I ${p.phq_psych_factors} psychological factors that would hinder the success of bariatric surgery.</p>
            <p><u>Depression, Anxiety and Stress Scale (DASS-21):</u> The DASS-21 is composed of twenty-one questions. The patient scored ${missing_results.dass_21_d_adjusted_score ? undef : Articles.articlize(writtenNumber(p.test_results.dass_21_d_adjusted_score))} on the depression scale. This score is in the ${p.dass_21_d_score ? p.dass_21_d_score.toLowerCase() : p.test_results.dass_21_d_score.toLowerCase()} range. The patient scored ${missing_results.dass_21_a_adjusted_score ? undef : Articles.articlize(writtenNumber(p.test_results.dass_21_a_adjusted_score))} on the anxiety scale. This score is in the ${p.dass_21_a_score ? p.dass_21_a_score.toLowerCase() : p.test_results.dass_21_a_score.toLowerCase()} range. The patient scored ${missing_results.dass_21_s_adjusted_score ? undef : Articles.articlize(writtenNumber(p.test_results.dass_21_s_adjusted_score))} on the stress scale. This score is in the ${p.dass_21_s_score ? p.dass_21_s_score.toLowerCase() : p.test_results.dass_21_s_score.toLowerCase()} range. I ${p.dass_psych_factors} psychological factors that would hinder the success of bariatric surgery.</p>
            <p><u>Eating Attitudes Test (EAT-26):</u> The EAT-26 is composed of twenty-six questions and screens for eating disorders and eating disorder behaviors. The patient scored ${missing_results.eat_26_raw_score ? undef : Articles.articlize(writtenNumber(p.test_results.eat_26_raw_score))} which is in the ${p.eat_26_score ? p.eat_26_score.replace(/score/ig, 'range').toLowerCase() : p.test_results.eat_26_score.replace(/score/ig, 'range').toLowerCase()}. The patient ${p.binge_eating_form} any binge eating behaviors. The patient ${p.self_induced_vomiting} self-induced vomiting. The patient ${p.laxative_use} any past or present laxative abuse. The patient ${p.eating_disorder_treatment} eating disorder treatment. I ${p.eating_disorder_behaviors} ${p.eating_disorder_behaviors === 'see' ? '' : 'any'} eating disorder behaviors that would hinder the success of bariatric surgery.</p>
            <p><u>Risks of Surgery:</u> The patient understands the risks, benefits, alternatives and potential complications of bariatric surgery which have been explained to ${p.third} by ${p.possessive} surgeon; the patient has researched and talked to other patients who have undergone the procedure; the patient wishes to proceed as ${p.pronoun} ${p.pronoun === 'they' ? 'believe' : 'believes'} that the risks of not undergoing the procedure are greater than the risks of undergoing the procedure; the patient believes that ${p.pronoun} ${p.has_word} enough information regarding the procedure to make an informed decision.</p>
            <p><u>Commitment to Postoperative Regimen:</u> The patient is committed to the post-operative care, diet and exercise program prescribed to ${p.third} by ${p.possessive} bariatric surgeon.</p>
            <p><u>Patient Expectations:</u> The patient expects to lose enough weight to achieve ${p.possessive} goal of ${p.weight_goal} pounds and to be able to live an active lifestyle again. ${p.pronoun.toProperCase()} would like to have more energy and maintain a healthy weight. The patient also expressed the desire to gain control over ${p.possessive} eating patterns and to maintain that control.</p>
            <p><u>Post-Operative Support:</u> The patient describes a reliable support network within ${p.possessive} ${p.support_network}. The patient states that ${p.pronoun} will attend support groups post-op. The patient reports that ${p.pronoun} ${p.has_word} the financial means to support any post-op nutritional requirements. A state licensed counselor was recommended but not mandatory to increase the chances of a successful surgical outcome.</p>
            <p><u>Recommendations:</u> ${p.first_name} ${p.last_name}'s mental health history suggests that ${p.pronoun} ${p.suitable_for_surgery === 'suitable' ? p.is_word : p.is_word+' not'} a good bariatric surgery candidate. The patient indicates that ${p.pronoun} ${p.has_word} been adequately informed regarding the risks of, the benefits of, the alternatives to, and the potential complications of the procedure. The patient asserts that ${p.pronoun} ${p.has_word} made an informed decision. The patient professes reasonable post-procedural expectations and describes reliable relationships that will support ${p.third} in ${p.possessive} weight loss journey. Arrangements for post-operative care, assistance and nutritional support have been made.</p>
        `;
        if(p.recommendation === 'support') {
          defaultReport += `<h3>I see no significant psychological factors that would hinder the success of bariatric surgery. I affirm that ${p.first_name} ${p.last_name} is a good candidate for bariatric surgery.</h3>`;
        } else {
          defaultReport += `<h3>I ${p.recommendation === 'do not support' ? 'see' : p.recommendation} significant psychological factors that would hinder the success of bariatric surgery. I ${p.recommendation} ${p.first_name} ${p.last_name}${p.last_name.slice(-1) === 's' ? "'" : "'s"} desire for bariatric surgery.</h3>`;
        }

        
        if(patient.procedure.indexOf('Bariatric Counseling') > -1) {
          if(!patient.session || patient.session == 1) {

            defaultReport = `
            <h4>Patient Name: ${p.first_name} ${p.last_name}</h4>
            <h4>DOB: ${p.dob_string}</h4>
            <h4>Date: ${report_date ? p.report_date : moment().format('MM/DD/YYYY')}</h4>
            <h1>Counseling Treatment Plan</h1>
            <h2>Symptom rating for level of functioning change (scale 1-5; 1-mild, 3-moderate, 5-severe)</h2>
            <table className="fullwidth">
              <tbody>
                <tr>
                  <td class="small">&nbsp;</td><td>Decrease in energy</td>
                  <td class="small">&nbsp;</td><td>Restlessness</td>
                  <td class="small">&nbsp;</td><td>Hopelessness</td>
                  <td class="small">&nbsp;</td><td>Excessive guilt</td>
                </tr>
                <tr>
                  <td class="small">&nbsp;</td><td>Panic attacks</td>
                  <td class="small">&nbsp;</td><td>Cruelty</td>
                  <td class="small">&nbsp;</td><td>Loss of pleasure</td>
                  <td class="small">&nbsp;</td><td>Depressed mood</td>
                </tr>
                <tr>
                  <td class="small">&nbsp;</td><td>Anxiety</td>
                  <td class="small">&nbsp;</td><td>Sleep disturbance</td>
                  <td class="small">&nbsp;</td><td>Withdrawn</td>
                  <td class="small">&nbsp;</td><td>Oppositional</td>
                </tr>
                <tr>
                  <td class="small">&nbsp;</td><td>Poor concentration</td>
                  <td class="small">&nbsp;</td><td>Indecisive</td>
                  <td class="small">&nbsp;</td><td>Mood swings</td>
                  <td class="small">&nbsp;</td><td>Violation of rules</td>
                </tr>
                <tr>
                  <td class="small">&nbsp;</td><td>Legal problems</td>
                  <td class="small">&nbsp;</td><td>Irritability</td>
                  <td class="small">&nbsp;</td><td>Helplessness</td>
                  <td class="small">&nbsp;</td><td>Eating disturbance</td>
                </tr>
                <tr>
                  <td class="small">&nbsp;</td><td>Impulsivity</td>
                  <td class="small">&nbsp;</td><td>Worrying</td>
                  <td class="small">&nbsp;</td><td>Aggression/rage</td>
                  <td class="small">&nbsp;</td><td>Tearfulness</td>
                </tr>
                <tr>
                  <td class="small">&nbsp;</td><td>Substance abuse</td>
                  <td class="small">&nbsp;</td><td>Ritualistic Behavior</td>
                  <td class="small">&nbsp;</td><td>Low self-esteem</td>
                  <td class="small">&nbsp;</td><td>Low motivation</td>
                </tr>
                <tr>
                  <td class="small">&nbsp;</td><td colspan="7">Other:</td>
                </tr>
                </tbody>
                </table>
                <p></p>
                <p>Changes in Psychosocial/ Psychological level of distress: Greater: ____ Less: ___ None: _____</p>
                <p>Changes in physical status: _____________________________________________________</p>
                <p>Reports received/ Ancillary services documented: ___________________________________</p>
                <p>Treatment Plan: Progress toward /modification of goals and objectives, with estimated completion dates:</p>
                <p>&nbsp;</p>
                <p>Sessions per month: ____ Client concurred: yes ___ no ___</p>
                <p>Dx Code (original): ________________________________________________________</p>
                <p>Dx Code (current): ________________________________________________________</p>
                <p>THERAPIST SIGNATURE:</p>
            `;
          } else {
            defaultReport = `
            <h1>Therapy Progress Note</h1>
            <p><b>Patient Name:</b> ${p.first_name} ${p.last_name}</p>
            <p><b>Date:</b> ${report_date ? p.report_date : moment().format('MM/DD/YYYY')}</p>
            <p><b>Session Number:</b> ${p.session}</p>
            <p></p>
            <p><b>Session Focus:</b></p>
            <p></p>
            <p><b>Therapeutic Intervention:</b></p>
            <p></p>
            <p><b>Planned Intervention:</b></p>
            <p></p>
            <p><b>Therapist Signature:</b></p>
            `;
          }
        }

        if(patient.procedure.indexOf('Opioid Risk') > -1) {
          defaultReport = `
          <h1>BEHAVIORAL ASSESSMENT</h1>
          <table className="fullwidth">
            <tbody>
              <tr>
                <td>Patient Name</td>
                <td>${p.first_name} ${p.last_name}</td>
                <td>Date of Birth</td>
                <td>${p.dob_string}</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>${p.age}</td>
                <td>Date of Interview</td>
                <td>${p.appointment_date_modified_nice}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>${p.gender}</td>
                <td>Date of Report</td>
                <td>${report_date ? p.report_date : moment().format('MM/DD/YYYY')}</td>
              </tr>
              <tr>
                <td>Type of Evaluation</td>
                <td>Opioid Risk Assessment</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p><u>Assessment Instruments:</u></p>
          <p>NIDA | CHRONIC PAIN ASSESSMENT QUESTIONNAIRE | PHQ-9 | DASS-21 | PAIN CATASTROPHIZING SCALE | OPIOID RISK TOOL</p>
          <p><u>Purpose of Assessment:</u> To determine, within the limits of psychological certainty: whether ${p.first_name} ${p.last_name} is at risk for opioid abuse and to enhance planning for safe and effective pain management, to reassure patients who are anxious about their risk that their history does not mean their pain will not be managed and to inform patients with a history of substance use disorder that safeguards will be used in their pain management plan.</p>
          <p><u>Chief Complaint/Symptoms:</u> Chronic Pain</p>
          <p><u>Client Background:</u> ${p.first_name} ${p.last_name} is a ${p.age} year-old ${p.marital_status_display} ${p.gender_display}. The patient states that ${p.possessive} highest level of education is: ${parseValue(Articles.articlize(p.education))}. The patient reports that ${p.pronoun} ${p.is_word} currently employed as ${Articles.articlize(p.occupation)}. The patient is ${p.height_feet} feet ${p.height_inches} inches tall and ${p.weight} pounds.</p>
          <p><u>Description of Pain:</u> Onset of pain: ${parseValue(p.onset_of_pain)}; Pain increasing conditions: ${parseValue(p.pain_increasing_conditions)}; Pain decreasing conditions: ${parseValue(p.pain_decreasing_conditions)}. Pain Site: ${parseValue(p.pain_site)}. Past Spinal Procedures: ${p.past_surgeries}. Patient reports ${parseValue(p.relief_from_past_procedures.toLowerCase())} relief from these past procedures. Current Pain Medications including over the counter and prescription: ${parseValue(p.current_pain_medications)}.</p>
          <p><u>Non-Spine Medical Problems:</u> ${parseValue(p.non_spine_medical_problems)}</p>
          <p><u>Typical Day Description:</u> The patient reports the following daytime activities: ${parseValue(p.daytime_activities)}. The patient reports: ${parseValue(p.hours_of_sleep_per_night)} hours of sleep per night. The patient ${parseValue(p.sleep_disturbances.toLowerCase())} report any sleep disturbances. The patient reports ${parseValue(p.appetite_last_30_days)} desire for food in the last 30 days. The patient reports ${parseValue(p.long_term_memory)} long-term memory. The patient reports ${parseValue(p.short_term_memory)} short-term memory. The patient reports a ${parseValue(p.sexual_pleasure)} level of sexual pleasure. The patient reports the following mood or moods: ${parseValue(p.average_daily_mood)}.</p>
          <p><u>Stresses:</u> The patient reports the following stresses: ${parseValue(p.current_stressors)}.</p>
          <p><u>Non-Medical Coping Mechanisms:</u> The patient reports the following coping mechanisms which reduce or control the pain: ${parseValue(p.non_medical_coping_mechanisms)}.</p>
          <p><u>Social History:</u> ${history} ${parseValue(p.social_history_notes)}</p>
          <p><u>Personal History of Mental Illness/Past psychiatric problems or treatment:</u> The patient ${p.mental_illness} any personal history of mental illness: ${parseValue(p.mental_illness_diagnosis_medications)}. The patient ${parseValue(p.suicidal_ideation)} suicidal ideation. ${p.suicidal_ideation_notes ? p.suicidal_ideation_notes + "." : ""} The patient ${parseValue(p.past_treatment)} any past inpatient or outpatient treatment. A state licensed counselor was recommended but not mandatory to increase the chances of a successful surgical outcome.</p>
          <p><u>Recreation Activities:</u> The patient reports the following current recreational activities: ${parseValue(p.current_recreational_activities)}. The patient reports the following past recreational activities including: ${parseValue(p.past_recreational_activities)}.</p>
          <p><u>Litigation Pending:</u> ${parseValue(p.litigation_pending)}.</p>
          <p><u>Personality Disorders:</u> The patient ${parseValue(p.personality_disorders)} any personality disorders.</p>
          <p><u>Tobacco Use:</u> The patient ${parseValue(p.tobacco_use_form)} tobacco use. ${p.tobacco_use_notes ? p.tobacco_use_notes : ""}</p>
          <p><u>PATIENT BEHAVIORAL ASSESSMENT SCORES</u></p>
          <p><u>NIDA SCREEN:</u>  This screening is composed of four distinct categories: Alcohol use, Tobacco use, Illegal Drugs use and Prescription Drugs for non-medical reasons use over the past year. The patient reports NO to use of any substances or prescription drug use for non-medical reasons which reinforces abstinence OR the patient’s substance involvement score is: 0-3 which indicates a low risk level, 4-26 which indicates a moderate risk level or 27 and above which indicates a high risk level. There are no known addictions reported.</p>
          <p><u>CHRONIC PAIN ASSESSMENT QUESTIONNAIRE:</u> This questionnaire assesses the two parts of chronic pain that change over time: Persistent Baseline Pain and Breakthrough Pain. The patient rated their baseline pain as:        . The patient reports feeling this pain in the following areas:                 . The patient reports that the pain feels like:            . The patient rated their breakthrough pain as:                       . The patient reports feeling this pain in the following areas:                . The patient reports that the pain feels like:                   .</p>
          <p><u>PHQ-9 ASSESSMENT:</u> The patient’s PHQ-9 score is: ${missing_results.phq_9_raw_score ? undef : writtenNumber(p.test_results.phq_9_raw_score)} which indicates the level of the patient’s depression severity. The level of depression severity of this patient is ${p.phq_9_score ? p.phq_9_score.toLowerCase() : p.test_results.phq_9_score.toLowerCase()}.</p>
          <p><u>Depression, Anxiety and Stress Scale (DASS-21):</u> The DASS-21 is composed of 21 questions. The patient scored a ${p.test_results.dass_21_d_adjusted_score} on the depression scale. This is in the ${p.dass_21_d_score.toLowerCase()} range. The patient scored a ${p.test_results.dass_21_a_adjusted_score} on the anxiety scale. This is in the ${p.dass_21_a_score.toLowerCase()} range. The patient scored a ${p.test_results.dass_21_s_adjusted_score} on the stress scale. This is in the ${p.dass_21_s_score.toLowerCase()} range.</p>
          <p><u>PAIN CATASTROPHIZING SCALE:</u> This scale consists of 13 Items across three subscales. The patient scored a: ${parseValue(p.test_results.pain_catastrophizing_score)} which ${parseValue(p.clinically_relevant_catastrophizing)} indicate a clinically relevant level of catastrophizing.</p>
          <p><u>OPIOID RISK TOOL:</u>  Administered to patients prior to beginning opioid therapy: The patient scored a <code>NUMBER</code> which indicates a <code>LOW/MODERATE/HIGH</code> risk for opioid abuse.</p>
          <p><u>Recommendations:</u>  ${p.first_name} ${p.last_name}${p.last_name.slice(-1) === 's' ? "'" : "'s"} mental health history suggests that ${p.pronoun} ${p.is_word} a suitable pain management candidate with a <code>LOW/MODERATE/HIGH</code> risk for opioid abuse. The patient indicates that ${p.pronoun} ${p.has_word} been adequately informed regarding the risks of, the benefits of, the alternatives to, and the potential complications of abusing opioids. Patient was informed to discuss ${p.possessive} future course of pain management treatment with ${p.possessive} physician. </p>
          <p>I see no significant psychological factors that would suggest ${p.first_name} ${p.last_name} is a high risk for opioid abuse. I support ${p.first_name} ${p.last_name}${p.last_name.slice(-1) === 's' ? "'" : "'s"} desire for opioid therapy.</p>
        `;
        }
        if(patient.procedure.indexOf('SCS') > -1 || patient.procedure.indexOf('Spinal Cord Stimulator') > -1) {
          let dass_21_factors = 'do not see';
          if(p.dass_a_psych_factors == 'see' || p.dass_d_psych_factors == 'see' || p.dass_s_psych_factors == 'see') {
            dass_21_factors = 'see';
          }
          defaultReport = `
          <h1>BEHAVIORAL ASSESSMENT</h1>
          <table className="fullwidth">
            <tbody>
              <tr>
                <td>Patient Name</td>
                <td>${p.first_name} ${p.last_name}</td>
                <td>Date of Birth</td>
                <td>${p.dob_string}</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>${p.age}</td>
                <td>Date of Interview</td>
                <td>${p.appointment_date_modified_nice}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>${p.gender}</td>
                <td>Date of Report</td>
                <td>${report_date ? p.report_date : moment().format('MM/DD/YYYY')}</td>
              </tr>
              <tr>
                <td>Surgery</td>
                <td>${p.scs_procedure_title}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <p><u>Assessment Instruments:</u></p>
          <p>NIDA | CHRONIC PAIN ASSESSMENT QUESTIONNAIRE | PHQ-9 | DASS-21 | PAIN CATASTROPHIZING SCALE</p>
          <p><u>Purpose of Assessment:</u> To determine, within the limits of psychological certainty: whether ${p.first_name} ${p.last_name} is comfortable with ${p.possessive} decision to proceed with the implantation of the ${p.scs_procedure}; whether the patient is prepared for the changes in lifestyle the implantation will impose; whether the patient has reliable intimate relationships, supportive family dynamics and committed post-operative support following the procedure; and whether the patient has sufficient information on which to base informed consent.</p>
          <p><u>Chief Complaint/Symptoms:</u> Chronic Pain</p>
          <p><u>Client Background:</u> ${p.first_name} ${p.last_name} is a ${p.age} year-old ${p.marital_status_display} ${p.gender_display} seeking ${p.scs_procedure}. The patient states that ${p.possessive} highest level of education is: ${Articles.articlize(p.education)}. The patient reports that ${p.pronoun} ${p.is_word} currently employed as ${Articles.articlize(p.occupation)}. The patient is ${p.height_feet} feet ${p.height_inches} inches tall and ${p.weight} pounds.</p>
          <p><u>Description of Pain:</u> Onset of pain: ${p.onset_of_pain}; Pain increasing conditions: ${p.pain_increasing_conditions}; Pain decreasing conditions: ${p.pain_decreasing_conditions}. Pain Site: ${p.pain_site}. Past Spinal Procedures: ${p.past_surgeries}. Patient reports ${p.relief_from_past_procedures.toLowerCase()} relief from these past procedures. Current Pain Medications including over the counter and prescription: ${p.current_pain_medications}.</p>
          <p><u>Non-Spine Medical Problems:</u> ${p.non_spine_medical_problems}</p>
          <p><u>Typical Day Description:</u> The patient reports the following daytime activities: ${p.daytime_activities}. The patient reports: ${p.hours_of_sleep_per_night} hours of sleep per night. The patient ${p.sleep_disturbances.toLowerCase()} report any sleep disturbances. The patient reports ${p.appetite_last_30_days} desire for food in the last 30 days. The patient reports ${p.long_term_memory} long-term memory. The patient reports ${p.short_term_memory} short-term memory. The patient reports a ${p.sexual_pleasure} level of sexual pleasure. The patient reports the following mood or moods: ${p.average_daily_mood}.</p>
          <p><u>Stresses:</u> The patient reports the following stresses: ${p.current_stressors}.</p>
          <p><u>Non-Medical Coping Mechanisms:</u> The patient reports the following coping mechanisms which reduce or control the pain: ${p.non_medical_coping_mechanisms}.</p>
          <p><u>Social History:</u> ${history} ${p.social_history_notes}</p>
          <p><u>Personal History of Mental Illness/Past psychiatric problems or treatment:</u> The patient ${p.mental_illness} any personal history of mental illness: ${p.mental_illness_diagnosis_medications}. The patient ${p.suicidal_ideation} suicidal ideation. ${p.suicidal_ideation_notes}. The patient ${p.past_treatment} any past inpatient or outpatient treatment. A state licensed counselor was recommended but not mandatory to increase the chances of a successful surgical outcome.</p>
          <p><u>Recreation Activities:</u> The patient reports the following current recreational activities: ${p.current_recreational_activities}. The patient reports the following past recreational activities including: ${p.past_recreational_activities}.</p>
          <p><u>Litigation Pending:</u> ${p.litigation_pending}.</p>
          <p><u>Personality Disorders:</u> The patient ${p.personality_disorders} any personality disorders.</p>
          <p><u>Tobacco Use:</u> The patient ${p.tobacco_use_form} tobacco use. ${p.tobacco_use_notes}</p>
          <p><u>PATIENT BEHAVIORAL ASSESSMENT SCORES</u></p>
          <p><u>NIDA SCREEN:</u>  This screening is composed of four distinct categories: Alcohol use, Tobacco use, Illegal Drugs use and Prescription Drugs for non-medical reasons use over the past year. The patient reports NO to use of any substances or prescription drug use for non-medical reasons which reinforces abstinence OR the patient’s substance involvement score is: 0-3 which indicates a low risk level, 4-26 which indicates a moderate risk level or 27 and above which indicates a high risk level. There are no known addictions reported. I do or do not see any factors that would hinder the success of a ${p.scs_procedure}.</p>
          <p><u>CHRONIC PAIN ASSESSMENT QUESTIONNAIRE:</u> This questionnaire assesses the two parts of chronic pain that change over time: Persistent Baseline Pain and Breakthrough Pain. The patient rated their baseline pain as:        . The patient reports feeling this pain in the following areas:                 . The patient reports that the pain feels like:            . The patient rated their breakthrough pain as:                       . The patient reports feeling this pain in the following areas:                . The patient reports that the pain feels like:                   . I see or do not see any factors that would hinder the success of a ${p.scs_procedure}.</p>
          <p><u>PHQ-9 ASSESSMENT:</u> The patient’s PHQ-9 score is: ${missing_results.phq_9_raw_score ? undef : writtenNumber(p.test_results.phq_9_raw_score)} which indicates the level of the patient’s depression severity. The level of depression severity of this patient is ${p.phq_9_score ? p.phq_9_score.toLowerCase() : p.test_results.phq_9_score.toLowerCase()}. I ${p.phq_psych_factors} ${p.phq_psych_factors === 'see' ? '' : 'any'} factors that would hinder the success of ${p.scs_procedure}.</p>
          <p><u>Depression, Anxiety and Stress Scale (DASS-21):</u> The DASS-21 is composed of 21 questions. The patient scored a ${p.test_results.dass_21_d_adjusted_score} on the depression scale. This is in the ${p.dass_21_d_score.toLowerCase()} range. The patient scored a ${p.test_results.dass_21_a_adjusted_score} on the anxiety scale. This is in the ${p.dass_21_a_score.toLowerCase()} range. The patient scored a ${p.test_results.dass_21_s_adjusted_score} on the stress scale. This is in the ${p.dass_21_s_score.toLowerCase()} range. I ${dass_21_factors}  ${p.dass_21_factors === 'see' ? '' : 'any'} factors that would hinder the success of a ${p.scs_procedure}.</p>
          <p><u>PAIN CATASTROPHIZING SCALE:</u> This scale consists of 13 Items across three subscales. The patient scored a: ${p.test_results.pain_catastrophizing_score} which ${p.clinically_relevant_catastrophizing} indicate a clinically relevant level of catastrophizing.</p>
          <p><u>Risks of Surgery:</u>  The patient understands the risks, benefits, alternatives and potential complications of the procedure which have been explained to ${p.third} by ${p.possessive} surgeon; the patient has researched and talked to other patients who have undergone the procedure; the patient wishes to proceed as ${p.pronoun} ${p.pronoun === 'they' ? 'believe' : 'believes'} that the risks of not undergoing the procedure are greater than the risks of undergoing the procedure; the patient believes that ${p.pronoun} ${p.has_word} enough information regarding the procedure to make an informed decision.</p>
          <p><u>Commitment to Postoperative Regimen:</u>  The patient is committed to the post-operative care and post-operative program prescribed to ${p.third} by ${p.possessive} provider.</p>
          <p><u>Patient Expectations:</u>  The patient expects ${p.expected_pain_relief} pain relief with this procedure. The patient expects to be able to live an active lifestyle again and to be able to contribute to society.</p>
          <p><u>Post-Operative Support:</u>  The patient describes a reliable support network within ${p.possessive}: ${p.support_network}. The patient states that ${p.pronoun} will attend support groups post-op as recommended by ${p.possessive} provider. The patient reports that ${p.pronoun} ${p.has_word} the financial means to support any post-op requirements. A state licensed counselor was recommended to increase the chances of a successful surgical/implantation outcome.</p>
          <p>Recommendations:  ${p.first_name} ${p.last_name}${p.last_name.slice(-1) === 's' ? "'" : "'s"} mental health history suggests that ${p.pronoun} ${p.is_word}${p.recommendation === 'do not support' ? ' not' : '' } a good ${p.scs_procedure} candidate. The patient indicates that ${p.pronoun} ${p.has_word} been adequately informed regarding the risks of, the benefits of, the alternatives to, and the potential complications of the procedure. The patient asserts that ${p.pronoun} ${p.has_word} made an informed decision. The patient professes reasonable post-procedural expectations and describes reliable relationships that will support ${p.third} in ${p.possessive} journey to reduce chronic pain. Arrangements for post-operative care and assistance have been made.</p>
        `;

        if(p.recommendation === 'support') {
          defaultReport += `<h3>I see no significant psychological factors that would hinder the success of a ${p.scs_procedure}. I affirm that ${p.first_name} ${p.last_name} is a good candidate for the procedure.</h3>`;
        } else {
          defaultReport += `<h3>I ${p.recommendation === 'do not support' ? 'see' : p.recommendation} significant psychological factors that would hinder the success of a ${p.scs_procedure}. I ${p.recommendation} ${p.first_name} ${p.last_name}${p.last_name.slice(-1) === 's' ? "'" : "'s"} desire for the procedure.</h3>`;
        }

        }
        defaultReport = defaultReport.replace(/undefined/gi, '<code>UNDEFINED</code>');
        	that.setState({
            content: patient.report_content || defaultReport,
            patient: patient,
        		therapists: res.therapists,
            level: res.level,
            user_id: res.user_id
        	})
          return patient;
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .then((patient) => {
        if(patient.report_submitted) {
          return fetch(window.server_url + '/admin/patients/download_report/' + this.props.match.params.patient_id, {
            method: 'GET',
            headers: {
              //'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'Authorization': 'Token '+token,
              'Accept': 'any',
            },
          })
        } else {
          return;
        }
      })
      .then(function (response) {
        if(!response) return;
         response.headers.forEach(function(val, key) { console.log(key + ' -> ' + val); });

          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              that.setState({fileError:response.statusText });
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        if(!res) return;
        console.log(res);
        
        if(res.filename) {
          that.setState({downloadFile: res.filename, loading: false});
        }
        
      })
      .catch(error => console.log(error) );  
  }
  getField(form, field) {
    let match;

    if(!form) return 'None';
    
    match = form.find(e => (e && e.name === field));
    if(match) return match.value;
  }
  update(key, value) {
    let _patient = Object.assign({}, this.state.patient);
    if(key === 'therapist') {
      let parts = value.split('|');
      _patient['therapist_id'] = parts[0];
      _patient['therapist'] = parts[1];
    } else {
      _patient[key] = value;
    }
    this.setState({
      patient: _patient
    })
  }
  handleEditorChange(content) {
    console.log('handleEditorChange', content);
    this.setState({ content: content });
  }
  submit() {
    this.savePatient({
      report_content: this.state.content,
      report_status: 'Complete',
      report_submitted: moment().format(),
      report_signed_by: this.state.user_id
    });
    
  }

  report(patient) {
    
    let report_statuses = patient.report_statuses.map(status => {
      return <option value={status} selected={status === patient.report_status}>{status}</option>
    });
    let report_fields;
    if(['super_admin', 'admin'].indexOf(this.state.level) > -1) {
      report_fields = (<table className="space">
        <tr>
          <th>Surgeon Name</th>
          <th>Report Status</th>
          <th>Report Date</th>
        </tr>
        <tr>
          <td>{patient.surgeon}</td>
          <td>
            <select id="report_status" onClick={e => {e.stopPropagation()}}>
              {report_statuses}
            </select>
          </td>
          <td>
            <Cleave 
              options={{
                date: true,
                datePattern: ['m', 'd', 'Y']
              }} 
              className="date-field" 
              id="report_date"
              placeholder="MM/DD/YYYY"
              value={moment(patient.report_date).format('MM/DD/YYYY')} 
              name="report_date" />
          </td>
        </tr>
        <tr>
          <td>
           <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.saveFields()}}>Save Info</a>
           </td>
         </tr>
      </table>);
    }
    if(['advantum'].indexOf(this.state.level) > -1) {
      return (
        <div className="page admin form detail report">
          <h1>Patient Report: {patient.first_name} {patient.last_name}</h1>
          {this.state.downloadFile ? <a className="btn left" id="download-file" href={this.state.downloadFile}>Download Report</a> : 'No report created yet'}
        </div>
      );

    }


    return (
        <div className="page admin form detail report">
          <h1>Patient Report: {patient.first_name} {patient.last_name}</h1>
          {report_fields}
          {patient.report_submitted ? '' : <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient({report_content: this.state.content})}}>Save Draft</a>}
          {this.state.level === 'therapist' || this.state.level === 'super_therapist' ? <a className="btn left" href={`/admin/patient/${patient._id}`}>Cancel</a> :  <a className="btn left" href={`/admin/reports`}>Back</a>}
          {patient.report_submitted ? <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient({report_submitted: null,report_status: '', report_signed_by: null}) }}>Revert to Draft</a> : ''}
          {patient.report_submitted ? '' : <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.submit()}}>Sign & Submit</a>}
          {this.state.downloadFile ? <a className="btn left" id="download-file" href={this.state.downloadFile}>Download Report</a> : ''}
          {this.state.delete_confirm ? <span class="left">Really delete this report and start over from system data? <a className="btn red" href="#" onClick={e => {e.preventDefault(); this.savePatient({report_submitted: null,report_status: '', report_content: '', report_signed_by: null})}}>Delete Report</a></span> : <a className="btn red left" href="#" onClick={e => {e.preventDefault(); this.setState({delete_confirm: true})}}>Delete Report</a>}
          

          <div class="editor">
          {patient.report_submitted ? 
            <div>
              <h3>Signed on {patient.report_submitted}</h3>
              <div className="detail-area" dangerouslySetInnerHTML={{__html: this.state.content}} /> 
            </div> : 
            <TipTapEditor content={this.state.content} onChange={this.handleEditorChange} />}
          </div>
        </div>
      );
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.patient) return '';
    else {
    	//let patient = this.state.patient;
    	let that = this;
  		

      if(['therapist', 'super_therapist', 'super_admin', 'admin', 'advantum'].indexOf(this.state.level) > -1) return this.report(this.state.patient);
      else {
        window.location.href = window.location.href.replace('_form','')
      }
    }
  }
}

export default TherapistPatientReport;