import React from 'react';
import moment from 'moment';
import { site } from './config/config'
import TagManager from 'react-gtm-module'

class OTPForm extends React.Component {  
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      error: null,
      validation: '',
      entered_code: '',
      patient: null,
      code_sent: false
    }
    this.setValidation = this.setValidation.bind(this);
    this.sendOTP = this.sendOTP.bind(this);
    this.submitOTP = this.submitOTP.bind(this);
  }
  async componentDidMount() {
    this.interval = setInterval(() => this.setState({ time: Date.now() }), 1000);
    if (this.props.referral_code) {
      const patient_res = await fetch(
        window.server_url +
          "/referral/complete/" +
          this.props.referral_code,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      let patient = await patient_res.json();

      if(patient && patient.success) this.setState({loading: false, patient: patient});
      else this.setState({loading: false, error: 'The referral code provided is invalid. Please contact us at (877) 583-5633'});
      
    }
   
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevState) {
   
  }
  async sendOTP() {
    this.setState({loading: true, time_requested: Date.now()})
    if (this.props.referral_code && this.state.validation !== '') {
      const res = await fetch(
        window.server_url +
          "/referral/otp/" +
          this.props.referral_code + 
          "/" + 
          this.state.validation,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      let response = await res.json();

      // if(patient && patient._id) {
      //   localStorage.setItem('patient_id', patient._id);
      //   localStorage.setItem('referral', true);
      // }
      if(response && response.success) {
        this.setState({expiry_minutes: response.expiry_minutes, code_ending: response.code_ending, loading: false})
      } else if(response.error && response.error == 'locked') {
        this.setState({loading: false, error: 'You have exceeded the maximum number of attempts. Please wait 20 minutes and try again'});
      } else this.setState({loading: false, error: 'The referral code provided is invalid. Please contact us at (877) 583-5633'});
      
    }
  }
  async submitOTP() {
    if (this.props.referral_code && this.state.entered_code) {
      this.setState({loading: true})
      const res = await fetch(
        window.server_url +
          "/referral/otp/validate/" +
          this.props.referral_code + 
          "/" + 
          this.state.entered_code,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      let response = await res.json();

      if(response.success && response.patient_id) {
        localStorage.setItem('patient_id', response.patient_id);
        localStorage.setItem('referral', true);
        window.location.href = '/';
      } else if(response.error && response.error == 'locked') {
        this.setState({loading: false, error: 'You have exceeded the maximum number of attempts. Please wait 20 minutes and try again'});
      } else if(response.error && response.error == 'invalid referral code') {
        this.setState({loading: false, error: 'The referral code provided is invalid. Please contact us at (877) 583-5633'});
      } else {
        this.setState({loading: false, error: 'The one-time password provided is invalid. Please try again or request a new code below.'});

      }
      
    }
  }
  setValidation(type) {
    this.setState({validation: type});
  }
  
  render() {
    let phone_option, email_option;

    if(this.state.patient) {
      // if(this.state.patient.phone) {
      //   phone_option = <a className={`otp-option ${this.state.validation === 'phone' ? 'active' : ''}`} onClick={e => {this.setValidation('phone')}}>Send a code via SMS to {this.state.patient.phone}</a>;
      // }
      if(this.state.patient.email) {
        email_option = <a className={`otp-option ${this.state.validation === 'email' ? 'active' : ''}`} onClick={e => {this.setValidation('email')}}>Send a code via email to {this.state.patient.email}</a>;
      }
    }

    if(this.state.loading) {
      return (
        <div className="modal search">
          <h1>Loading...</h1>
        </div>
      )
    } else if(this.state.expiry_minutes) {
      let error;

      if(this.state.error) {
        error = <div class="errors">{this.state.error}</div>;

      }
      let wait_to_resend = this.state.time_requested > Date.now() - 60000;
      let time_to_wait = Math.ceil((this.state.time_requested - (Date.now() - 60000)) / 1000);
      return (
        <div className="modal search">
          <h1>Please enter the code we sent you</h1>
          {error}
          <h3>We sent a code to {this.state.validation == 'phone' ? this.state.patient.phone : this.state.patient.email} ending in {this.state.code_ending}. This code will expire in {this.state.expiry_minutes} minutes</h3>
          <input type="text" onKeyUp={e => {this.setState({entered_code: e.target.value})}} onChange={e => {this.setState({entered_code: e.target.value})}} placeholder="Enter code" />
          <a onClick={this.submitOTP} class={`btn submit ${this.state.loading == true || this.state.entered_code.length == 0 ? 'disabled' : ''}`}>Submit</a>
          <a onClick={this.sendOTP} class={`otp-resend ${wait_to_resend ? 'disabled' : ''}`}>Re-send code {wait_to_resend ? `(please wait ${time_to_wait} seconds to re-send)` : ''}</a>
        </div>
      )
    } else if(this.state.error) {
      return (
        <div className="modal search">
          <h1>There was an error</h1>
          <h3>{this.state.error}</h3>
        </div>
      )

    } else {
      return (
        <div className="modal search">
          <h1>We need to confirm your identity</h1>
          <h3>Please select an option below:</h3>
          {phone_option}
          {email_option}
          <a onClick={this.sendOTP} class={`btn submit ${this.state.validation == '' ? 'disabled' : ''}`}>Send code</a>
        </div>
      )
    }
  }
}

export default OTPForm;