import moment from 'moment-timezone';
import { site } from '../config/config'


const parseTherapist = (therapist) => {
    therapist.created = moment(therapist.createdAt)
        .tz(site.timezone)
        .format('MM/DD/YYYY');
    let services = therapist.services.map(service => (
        service.name.split('-')[0]
    ));
    let credentials = therapist.services.map(service => (
        service.name.split('-')[1]
    ));
    therapist.states = services.filter((value, index, self) => ( 
        self.indexOf(value) === index
    )).sort().join(', ');
    therapist.credentials = credentials.filter((value, index, self) => ( 
        self.indexOf(value) === index && value != 'Cash'
    )).sort().join(', ');

    if(!therapist.services) therapist.services = [];
    if(!therapist.licenses) {
        therapist.licenses = therapist.states.split(', ').map(s => {
            return {
                state: s,
                license_number: ''
            }
        });
    }
    if(!therapist.credentialed_payors) {
        therapist.credentialed_payors = therapist.credentials.split(', ').map(c => {
            return {
                payor: c,
                par_id: '',
                effective_date: ''
            }
        });
    }
    if(!therapist.ltm) therapist.ltm = [];
    if(!therapist.invoices) therapist.invoices = [];

    
    if(therapist.emr_data) {
        let emr_data = therapist.emr_data[0];
        therapist.emr_data = null;

        therapist = Object.assign(therapist, emr_data);
    }
    if(!therapist.scheduling_rank) therapist.scheduling_rank = therapist.display_position;
    therapist.phone = therapist.sms_number;
    therapist.email = therapist.emails[0] ? therapist.emails[0].address : '';

    return therapist;
}
export default parseTherapist;