import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
import { site } from '../config/config'
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class NewAppointment extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	appointments: null,
      patient: null,
      loading: false,
      hide_confirm: false,
      therapists: null,
      level: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.searchAppointments = this.searchAppointments.bind(this);
    this.newAppointment = this.newAppointment.bind(this);
    this.bookAppointment = this.bookAppointment.bind(this);
    this.formRef = React.createRef();
  }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
  bookAppointment(patient, date, client_id) {
    let token = localStorage.getItem('token'),
        that = this;
    if(!this.state.insurance_id) {
      that.setState({
        loading: false,
        appointments: null,
        error: 'No insurance id set, please try search again.'
      })
      return;
    }

    let json = JSON.stringify({
      time: date.format(), 
      patient_id: patient._id,
      client_id: client_id,
      repeat_patient: true,
      // user: {
      //   first_name: patient.first_name,
      //   last_name: patient.last_name,
      //   phone: patient.phone,
      //   address_1: patient.address, 
      //   phone_type: 'todo', //populate using parsepatient on backend?
      //   city: patient.city,
      //   state: patient.state,
      //   zip: patient.zip,
      //   email: patient.email,

      // },
      // timezone: 'Central Time (US & Canada)',
      insurance_id: this.state.insurance_id
    });

    fetch(window.server_url + '/book_appointment', {
        method: 'POST',
        body: json,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);

        if(!res.data || !res.data.id) {
          that.setState({
            loading: false,
            appointments: null,
            error: 'Saving patient failed, please try again.'
          })
          
        } else {
          alert('Patient booked successfully, redirecting to new patient record.');
          window.location.href = '/admin/patient/'+patient._id;
        } 
      })
      .catch(error => console.log(error) );  
  }
  newAppointment(date) {
    let token = localStorage.getItem('token'),
        that = this;

    if(!window.confirm('Do you want to book a new appointment for this patient at '+date.format('MM/DD/YYYY')+'?')) return;
    
    if(!this.state.insurance_id) {
      that.setState({
        loading: false,
        appointments: null,
        error: 'No insurance id set, please try search again.'
      })

      return;
    }

    let json = JSON.stringify({
      patient_id: this.state.patient._id
    });



    fetch(window.server_url + '/admin/new_appointment', {
        method: 'POST',
        body: json,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);

        if(!res.patient) {
          that.setState({
            loading: false,
            appointments: null,
            error: 'Saving patient failed, please try again.'
          })

          that.setState({loading: false});

        } else {
          that.bookAppointment(res.patient, date, res.client_id);
        } 
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true, error: null});
  }
  searchAppointments() {
    let token = localStorage.getItem('token'),
        that = this;

    let data = new FormData(this.formRef.current);
    let json = JSON.stringify({
      from: moment(data.get('from')).format('YYYY-MM-DD'), 
      to: moment(data.get('to')).format('YYYY-MM-DD'),
      patient_id: this.state.patient._id,
      user: {
        state: this.state.patient.state,
        insurance: this.state.patient.insurance
      },
      therapist_book_id: this.state.patient.therapist_id
    });



    fetch(window.server_url + '/search_appointments', {
        method: 'POST',
        body: json,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);

        if(!res.data) {
          localStorage.removeItem('token');
          window.location.href = '/admin';
          return false;
        } else if(res.data.openings && res.data.openings.length) {
          that.setState({
            appointments: res.data.openings, 
            insurance_id: res.insurance_id, 
            loading: false
          });
        } else if(res.data.error) {
          let error_response = JSON.parse(res.data.error),
              error_message = error_response.errorMessage;
              that.setState({
                loading: false,
                error: error_response.errorMessage
              });

        } else {
          that.setState({
            loading: false,
            error: 'No appointments found.'
          })
        }
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true, error: null});
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!res.data) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
        	that.setState({
            patient: res.data,
            level: res.level
        	})
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  getField(form, field) {
    let match;

    if(!form) return 'None';
    
    match = form.find(e => (e && e.name === field));
    if(match) return match.value;
  }
  therapist(patient) {
    let content, 
        errors,
        that = this;

    if(this.state.appointments) {
      content = (
        <form ref={this.formRef}>
        <h3>Select an appointment to book:</h3>
        {this.state.appointments.map(function(result, index) {
          let date = moment(result).tz(site.timezone);

          return (
            <div key={index} className="result">
              {date.format('M/D/YYYY')} at {date.format('h:mm a z')}
              <a href="#" className={`btn purple choose`} onClick={e => {that.newAppointment(date)}}>Book</a>
            </div>
          )
        })}
        </form>
      )
    } else {
      if(this.state.error) {
        errors = <div className="errors">{this.state.error}</div>;
      }
      content = (
        <form ref={this.formRef}>
          <div className="errors-wrapper">{errors}</div>
          <h3>Search for appointments:</h3>
          <label>From</label>
          <Cleave 
            options={{
              date: true,
              datePattern: ['m', 'd', 'Y']
            }} 
            placeholder="MM/DD/YYYY" 
            name="from" />

          <label>To</label>
          <Cleave 
            options={{
              date: true,
              datePattern: ['m', 'd', 'Y']
            }} 
            placeholder="MM/DD/YYYY" 
            name="to" />

            <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.searchAppointments()}}>Search</a>
        </form>
      );
    }
    return (
        <div className="page admin form detail">
          <h1>New Appointment: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href={`/admin/patient/${patient._id}`}>Cancel</a>
          <div class="detail-area">
          {content}
          </div>
        </div>
      );
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.patient) return '';
    else {
    	//let patient = this.state.patient;
    	let that = this;
  		
      let patient = parsePatient(this.state.patient);  		

      if(['therapist', 'super_therapist', 'admin', 'advantum', 'super_admin'].indexOf(this.state.level) > -1) return this.therapist(patient);
      else {
        window.location.href = window.location.href.replace('_form','')
      }
    }
  }
}

export default NewAppointment;