// import { site } from '../config/config'

const parseAssessment = (_assessment) => {
    let assessment = Object.assign({}, _assessment);
    

    assessment.data_horizontal = {};
   // let index = 1;
    if(assessment.assessment && typeof assessment.assessment.forEach === 'function') {
        let repeat = 0;
        assessment.data_horizontal['email'] = assessment.email;
        assessment.assessment.forEach((question) => {

            let v;
            if(question.response) v = question.response.toString().replace(/\n/g, "\\n").replace(/"/g, '”');
            assessment.data_horizontal[question.title] = v;
          
        })
    }
   
    return assessment;
}
export default parseAssessment;