import React from 'react';
import moment from 'moment-timezone';
import {us_states, insurance_providers} from '../helpers/select_helper';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import DatePicker from "react-datepicker";
import { site } from '../config/config'

class Inventory extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      insurance_state: null,
      to: null,
      from: null,
      insurance_code: null,
      openings: null
    }
    this.filterResults = this.filterResults.bind(this);
    this.stateSelect = this.stateSelect.bind(this);
    this.insuranceSelect = this.insuranceSelect.bind(this);
  }
  componentDidMount() {
  	// if(!this.state.therapists) this.loginToken();
  }
  stateSelect() {
    return (
      <tr>
        <td><label>State</label></td>
        <td>
          <select onChange={e => this.setState({insurance_state: e.target.value})}>
            {us_states.map(state => {
              return <option value={state} selected={this.state.insurance_state === state}>{state}</option>
            })}
          </select>
        </td>
      </tr>

    )
  }
  insuranceSelect() {
    return (
      <tr>
        <td><label>Insurance</label></td>
        <td>
          <select onChange={e => this.setState({insurance_code: e.target.value})} >
            {Object.entries(insurance_providers).map(([name, code]) => {
              return <option value={code} selected={this.state.insurance_code === code}>{name}</option>
            })}
          </select>
        </td>
      </tr>

    )
  }
	filterResults() {
    let token = localStorage.getItem('token'),
        that = this;

    fetch(window.server_url + '/admin/get_inventory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          state: this.state.insurance_state,
          from: this.state.from,
          to: this.state.to,
          insurance: this.state.insurance_code
        })
      })
      .then(function (response) {
          if (!response.ok) {
             // localStorage.removeItem('token');
              window.location.href = '/admin';
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res) {
          //localStorage.removeItem('token');
          window.location.href = '/admin';
          return false;
        } else {

          that.setState({
            openings: res.openings.openings,
            appointments: res.appointments,
            loading: false
          })
        }
        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true})
  }

  render() {
    let col_titles,
        results,
        total,
        error;
    if (this.state.appointments && this.state.appointments.error) {
      error = JSON.parse(this.state.appointments.error);
      if (error.errorMessage) {
        results = (
          <tr>
            <td>{error.errorMessage}</td>
          </tr>
        )
      } else {
        results = (
          <tr>
            <td>There was an error with the request.</td>
          </tr>
        )

      }
    }
    else if (this.state.openings && this.state.appointments) {
      col_titles = [
        <td>State</td>,
        <td>Total</td>,
        <td>Booked</td>,
        <td>Open</td>,
        <td>% Open</td>
      ];
      total = this.state.openings.length + this.state.appointments.length
      results = [
        <tr>
          <td>{this.state.insurance_state}</td>
          <td>{total}</td>
          <td>{this.state.appointments.length}</td>
          <td>{this.state.openings.length}</td>
          <td>{Math.round((this.state.openings.length / total)*100)}%</td>
        </tr>,
        <tr>
          <td colspan="2">Available appointments</td>
        </tr>
      ]
      this.state.openings.forEach((opening) => {
        results.push(
          <tr>
            <td>{moment(opening).tz(site.timezone).format('MM/DD/YYYY')}</td>
            <td>{moment(opening).tz(site.timezone).format('h:mm:ss a z')}</td>
          </tr>
        )
      })
    }

    if(this.state.loading === true) {
      col_titles = null
      results = null
    }  

  	return (
    	<div className="page admin form">
        <h1>Fullslate Inventory Search</h1>
        <table class="space">
          {this.stateSelect()}
          <tr>
            <td>
              <label>Date from</label>
            </td>
            <td>
              <DatePicker minDate={new Date()} selected={this.state.from} onChange={value => this.setState({from: value})} />
            </td>
          </tr>
          <tr>
            <td>
              <label>Date to</label>
            </td>
            <td>
              <DatePicker minDate={new Date()} selected={this.state.to} onChange={value => this.setState({to: value})} />
            </td>
          </tr>
          {this.insuranceSelect()}
        </table>
	    	<a class={`btn ${this.state.loading ? 'disabled' : ''}`} onClick={e => {this.filterResults(e.target.value)}}>{this.state.loading ? 'Loading...' : 'Search'}</a>
	    	<br/>
        <br/>
        <div className="results-area">
        <table class="results space fullwidth">
          <tbody>
            <tr className="header">
              {col_titles}
            </tr>
            {results}
          </tbody>
        </table>
        </div>
	    </div>
    );
  }
}

export default Inventory;