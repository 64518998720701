import React from 'react';

class Admin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  parseJwt() {
    var base64Url = localStorage.getItem('token').split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  
  render() {
     
    if(this.parseJwt().level === 'super_admin') return (
      <div class="menu">
        <a href="/admin">Patients</a>
        <a href="/admin/therapists">Therapists</a>
        <a href="/admin/users">Users</a>
        <a href="/admin/reports">Reports</a>
        <a href="/admin/inventory">Inventory</a>
      </div>
    ) 
    else if(this.parseJwt().level === 'admin') return (
      <div class="menu">
        <a href="/admin">Patients</a>
        <a href="/admin/reports">Reports</a>
        <a href="/admin/inventory">Inventory</a>
      </div>
    ) 
    else if(this.parseJwt().level === 'advantum') return (
      <div class="menu">
        <a href="/admin">Patients</a>
        <a href="/admin/reports">Reports</a>
      </div>
    ) 


    else return '';
  }
}

export default Admin;