import React from 'react';
import Login from './Login';
import PatientList from './PatientList';
// import ReportList from './ReportList';
import TherapistList from './TherapistList';
import UserList from './UserList';
import Menu from './Menu';
import PatientDetail from './PatientDetail';
import TherapistPatientForm from './TherapistPatientForm';
import TherapistPatientReport from './TherapistPatientReport';
import TherapistDetail from './TherapistDetail';
import UserDetail from './UserDetail';
import Inventory from './Inventory';
import Reschedule from './Reschedule';
import AdminNotes from './AdminNotes';
import NewAppointment from './NewAppointment';
import DownloadReport from './DownloadReport';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

class Admin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    }
  }
  
  render() {
    if(!localStorage.getItem('token')) return <Login />;
    else return [
      <a href="#" onClick={e => {localStorage.clear(); window.location.href='/admin'}} className="logout">Log out</a>,
      <Menu />,
      <Router>
  			<div>
          <Route path="/admin/new_appointment/:patient_id" component={NewAppointment} />
          <Route path="/admin/admin_notes/:patient_id" component={AdminNotes} />
          <Route path="/admin/patient_form/:patient_id" component={TherapistPatientForm} />
          <Route path="/admin/reschedule/:patient_id" component={Reschedule} />
          <Route path="/admin/patient_report/:patient_id" component={TherapistPatientReport} />
          <Route path="/admin/download_report/:patient_id" component={DownloadReport} />
          <Route path="/admin/patient/:patient_id" component={PatientDetail} />
          <Route path="/admin/therapist/:therapist_id" component={TherapistDetail} />
          <Route path="/admin/users/add" component={UserDetail} />
          <Route path="/admin/user/:user_id" component={UserDetail} />
          <Route path="/admin" exact component={PatientList} />
          <Route path="/admin/therapists" exact component={TherapistList} />
				  <Route path="/admin/users" exact component={UserList} />
          <Route path="/admin/inventory" exact component={Inventory} />
          <Route path="/admin/reports" exact render={(routeProps) => <PatientList {...routeProps} report_view={true} />} />
		  	</div>
	  	</Router>
    ]
  }
}

export default Admin;