import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
import { site } from '../config/config'
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class AdminNotes extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	patient: null,
      loading: false,
      hide_confirm: false,
      therapists: null,
      level: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.savePatient = this.savePatient.bind(this);
    this.formRef = React.createRef();
  }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
	savePatient() {
    let token = localStorage.getItem('token'),
        that = this;

    let data = new FormData(this.formRef.current);
    let object = [];
    let index = 0;
    data.forEach((value, key) => {
      if(key === 'notes') index++;
      if(value.trim() !== '') {

        if(!object[index]) {
          object[index] = {}
        }
        let val = value;
        if(key === 'date') val = moment(value).hour(10)
        .tz(site.timezone)
        .format();
        object[index][key] = val;
      }
    });
    object = object.filter(Boolean);
    let json = JSON.stringify({admin_notes: object});

    console.log('object', json);



    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: json,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin/patient/' + that.props.match.params.patient_id;
        // if(!res.data) {
        //   localStorage.removeItem('token');
        //   window.location.href = '/admin';
        //   return false;
        // } else {
        //   // that.setState({
        //   //   patient: res.data
        //   // })
        // }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!res.data) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
        	that.setState({
            patient: res.data,
        		therapists: res.therapists,
            level: res.level
        	})
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  getField(form, field) {
    let match;

    if(!form) return 'None';
    
    match = form.find(e => (e && e.name === field));
    if(match) return match.value;
  }
  update(key, value) {
    let _patient = Object.assign({}, this.state.patient);
    if(key === 'therapist') {
      let parts = value.split('|');
      _patient['therapist_id'] = parts[0];
      _patient['therapist'] = parts[1];
    } else {
      _patient[key] = value;
    }
    this.setState({
      patient: _patient
    })
  }
  therapist(patient) {

    let notes = patient.admin_notes;
    let rows = notes.map(row => {

      if(row) return (
        <tr>
          <td className="notes-field">
            <textarea name='notes' defaultValue={row.notes} />
          </td>
          <td>
            <Cleave 
              options={{
                date: true,
                datePattern: ['m', 'd', 'Y']
              }} 
              placeholder="MM/DD/YYYY"
              value={moment(row.date).format('MM/DD/YYYY')} 
              name="date" />
          </td>
          <td>
            <input type="text" name='initials' defaultValue={row.initials} />
          </td>
        </tr>
      );
    });
    [1,2].forEach(i => {
      rows.push(
        <tr>
          <td className="notes-field">
            <textarea name='notes' />
          </td>
          <td>
            <Cleave 
              options={{
                date: true,
                datePattern: ['m', 'd', 'Y']
              }} 
              placeholder="MM/DD/YYYY"
              name="date" />
          </td>
          <td>
            <input type="text" name='initials' />
          </td>
        </tr>
      )
    });   

    return (
        <div className="page admin form detail">
          <h1>Patient Notes: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href={`/admin/patient/${patient._id}`}>Cancel</a>
          <div class="detail-area">
          <form ref={this.formRef}>
          <table className="fluid">
            <tbody>
              <tr>
                <td>Notes</td>
                <td>Date</td>
                <td>Initials</td>
              </tr>
              {rows}
            </tbody>
          </table>
          </form>
          </div>
        </div>
      );
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.patient) return '';
    else {
    	//let patient = this.state.patient;
    	let that = this;
  		
      let patient = parsePatient(this.state.patient);  		

      if(['therapist', 'super_therapist', 'admin', 'advantum', 'super_admin'].indexOf(this.state.level) > -1) return this.therapist(patient);
      else {
        window.location.href = window.location.href.replace('_form','')
      }
    }
  }
}

export default AdminNotes;