import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
import { site } from '../config/config'
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Reschedule extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	patient: null,
      loading: false,
      hide_confirm: false,
      therapists: null,
      level: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.savePatient = this.savePatient.bind(this);
    this.formRef = React.createRef();
  }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
	savePatient() {
    let token = localStorage.getItem('token'),
        that = this;

    let data = new FormData(this.formRef.current);
    let object = [];
    let index = 0;
    let appt = this.state.appointments.find(appt => parseInt(appt.id) === parseInt(data.get('fullslate_id')));

    object = object.filter(Boolean);
    let json = JSON.stringify({fullslate_id: data.get('fullslate_id'), fullslate_appt: appt});

    console.log('object', json);



    fetch(window.server_url + '/admin/reschedule/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: json,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin/patient/' + that.props.match.params.patient_id;
        // if(!res.data) {
        //   localStorage.removeItem('token');
        //   window.location.href = '/admin';
        //   return false;
        // } else {
        //   // that.setState({
        //   //   patient: res.data
        //   // })
        // }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/get_appointments/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!res.data) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else if(res.appointments) {
        	that.setState({
            appointments: res.appointments,
            patient: res.data,
        		therapists: res.therapists,
            level: res.level
        	})
        } else {
          that.setState({
            error: res.error,
            patient: res.data,
            therapists: res.therapists,
            level: res.level
          })

        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  getField(form, field) {
    let match;

    if(!form) return 'None';
    
    match = form.find(e => (e && e.name === field));
    if(match) return match.value;
  }
  update(key, value) {
    let _patient = Object.assign({}, this.state.patient);
    if(key === 'therapist') {
      let parts = value.split('|');
      _patient['therapist_id'] = parts[0];
      _patient['therapist'] = parts[1];
    } else {
      _patient[key] = value;
    }
    this.setState({
      patient: _patient
    })
  }
  admin(patient) {
 
    let error, appointments, that = this;

    if(this.state.error) {
      error = <div class="errors">{this.state.error_message}</div>;
      appointments = <option disabled="disabled">No appointments found</option>;
    } else if (this.state.appointments && this.state.appointments.length) {
      appointments = this.state.appointments.map(function(appointment) {
        
        let is_current = parseInt(that.state.patient.fullslate_id) === appointment.id;

        return <option selected={is_current} value={appointment.id}>{is_current ? '(current appointment) ' : ''}{moment(appointment.at).tz(site.timezone).format('YYYY-MM-DD h:mm a')} with {appointment.employee.name}</option>;
      });
    }
    return (
        <div className="page admin form detail">
          {error}
          <h1>Reschedule Patient: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href={`/admin/patient/${patient._id}`}>Cancel</a>
          <div class="detail-area">
          <form ref={this.formRef}>
            <select name="fullslate_id">
              {appointments}
            </select>
            <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>

          </form>
          </div>
        </div>
      );
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.patient) return '';
    else {
    	//let patient = this.state.patient;
    	let that = this;
  		
      let patient = parsePatient(this.state.patient);  		

      if(['admin', 'super_admin'].indexOf(this.state.level) > -1) return this.admin(patient);
      else {
        window.location.href = window.location.href.replace('reschedule','patient')
      }
    }
  }
}

export default Reschedule;