import React from "react";
import Cleave from "cleave.js/react";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import { site } from '../../config/config'
import { convertTime } from '../../helpers/time_helper'

const TherapistView = ({ level, appointments, patient, onUpdateField, onSavePatient, appt_details }) => {
  const { patient_id: patientID } = useParams();
  const counseling_options = patient.counseling_options
    ? patient.counseling_options.map((status) => {
        return (
          <option value={status} selected={status === patient.counseling}>
            {status}
          </option>
        );
      })
    : [];

  const invoice_tos = patient.invoice_tos
    ? patient.invoice_tos.map((status) => {
        return (
          <option value={status} selected={status === patient.invoice_to}>
            {status}
          </option>
        );
      })
    : [];
  const invoicing_statuses = patient.invoicing_statuses
    ? patient.invoicing_statuses.map((status) => {
        return (
          <option value={status} selected={status === patient.invoicing_status}>
            {status}
          </option>
        );
      })
    : [];
  const scheduling_statuses = patient.scheduling_statuses
    ? patient.scheduling_statuses.map((status) => {
        return (
          <option
            value={status}
            selected={status === patient.scheduling_status}
          >
            {status}
          </option>
        );
      })
    : [];

  let super_therapist_content = "";
  if (level === "super_therapist") {
    super_therapist_content = (
      <tr>
        <td>
          <strong>Counseling</strong>
        </td>
        <td colspan="2">
          <select
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              onUpdateField("counseling", e.target.value);
            }}
          >
            {counseling_options}
          </select>
        </td>
      </tr>
    );
  }

  let table = "";
  let csv_link = "";

  if (patient.data) {
    table = patient.data.map((row) => {
      return (
        <tr>
          <td>{row.key}</td>
          <td>{row.name}</td>
          <td>{row.value}</td>
        </tr>
      );
    });
    csv_link = (
      <CSVLink
        filename="patient-data"
        className="download-link"
        data={patient.data}
      >
        Download CSV
      </CSVLink>
    );
  }

  let results_table;
  let results = patient.test_results;

  if (results) {
    results_table = [
      <tr>
        <td>
          <strong>Test</strong>
        </td>
        <td>
          <strong>Raw Score</strong>
        </td>
        <td>
          <strong>Adjusted Score</strong>
        </td>
        <td>
          <strong>Result</strong>
        </td>
      </tr>,
    ];

    if (results.phq_15_score) {
      results_table.push(
        <tr>
          <td>
            <strong>PHQ-15</strong>
          </td>
          <td>{results.phq_15_raw_score}</td>
          <td></td>
          <td>{results.phq_15_score}</td>
        </tr>
      );
    }
    if (results.gad_7_score) {
      results_table.push(
        <tr>
          <td>
            <strong>GAD-7</strong>
          </td>
          <td>{results.gad_7_raw_score}</td>
          <td></td>
          <td>{results.gad_7_score}</td>
        </tr>
      );
    }

    if (results.phq_9_score) {
      results_table.push(
        <tr>
          <td>
            <strong>PHQ-9</strong>
          </td>
          <td>{results.phq_9_raw_score}</td>
          <td></td>
          <td>{results.phq_9_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td></td>
        </tr>
      );
    }
    if (results.whympi_interference_score) {
      results_table.push(
        <tr>
          <td>
            <strong>WHYMPI</strong>
          </td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Part I</strong>
          </td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Interference</strong>
          </td>
          <td>{results.whympi_interference_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Support</strong>
          </td>
          <td>{results.whympi_support_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Pain Severity</strong>
          </td>
          <td>{results.whympi_pain_severity_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Life Control</strong>
          </td>
          <td>{results.whympi_life_control_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Affective Distress</strong>
          </td>
          <td>{results.whympi_affective_distress_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Part II</strong>
          </td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Negative Responses</strong>
          </td>
          <td>{results.whympi_negative_responses_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Solicitous Responses</strong>
          </td>
          <td>{results.whympi_solicitous_responses_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Distracting Responses</strong>
          </td>
          <td>{results.whympi_distracting_responses_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Part III</strong>
          </td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Household Chores</strong>
          </td>
          <td>{results.whympi_household_chores_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Outdoor Work</strong>
          </td>
          <td>{results.whympi_outdoor_work_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Activities Away from Home</strong>
          </td>
          <td>{results.whympi_activities_away_from_home_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>Social Activities</strong>
          </td>
          <td>{results.whympi_social_activities_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>General Activity</strong>
          </td>
          <td>{results.whympi_general_activity_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td></td>
        </tr>
      );
    }
    if (typeof results.pain_catastrophizing_score !== "undefined") {
      results_table.push(
        <tr>
          <td>
            <strong>Pain Catastrophizing Scale</strong>
          </td>
          <td>{results.pain_catastrophizing_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td></td>
        </tr>
      );
    }

    if (results.dass_21_d_score) {
      results_table.push(
        <tr>
          <td>
            <strong>DASS-21</strong>
          </td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>d</strong>
          </td>
          <td>{results.dass_21_d_raw_score}</td>
          <td>{results.dass_21_d_adjusted_score}</td>
          <td>{results.dass_21_d_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>a</strong>
          </td>
          <td>{results.dass_21_a_raw_score}</td>
          <td>{results.dass_21_a_adjusted_score}</td>
          <td>{results.dass_21_a_score}</td>
        </tr>
      );
      results_table.push(
        <tr>
          <td>
            <strong>s</strong>
          </td>
          <td>{results.dass_21_s_raw_score}</td>
          <td>{results.dass_21_s_adjusted_score}</td>
          <td>{results.dass_21_s_score}</td>
        </tr>
      );
    }
    if (results.eat_26_score) {
      results_table.push(
        <tr>
          <td>
            <strong>EAT-26</strong>
          </td>
          <td>{results.eat_26_raw_score}</td>
          <td></td>
          <td>{results.eat_26_score}</td>
        </tr>
      );
    }
  }
  let cancellation_reason;
  if (["Cancelled", "No-Show"].indexOf(patient.scheduling_status) > -1) {
    let cancellation_reasons = patient.cancellation_reasons
      ? patient.cancellation_reasons.map((reason) => {
          return (
            <option
              value={reason}
              selected={reason === patient.cancellation_reason}
            >
              {reason}
            </option>
          );
        })
      : [];

    cancellation_reason = (
      <tr>
        <td>
          <strong>Cancellation reason</strong>
        </td>
        <td colspan="2">
          <select
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              onUpdateField("cancellation_reason", e.target.value);
            }}
          >
            {cancellation_reasons}
          </select>
        </td>
      </tr>
    );
  }
  let info_table = [
    <tr>
      <td>Occupation</td>
      <td>{patient.occupation}</td>
    </tr>,
    <tr>
      <td>Tobacco</td>
      <td>{patient.tobacco_use}</td>
    </tr>,
    <tr>
      <td>BMI</td>
      <td>{patient.bmi}</td>
    </tr>,
    <tr>
      <td>Mental Health Illness?</td>
      <td>{patient.mental_health_illness}</td>
    </tr>,
    <tr>
      <td>Other</td>
      <td>{patient.mental_health_illness_other}</td>
    </tr>,
    <tr>
      <td>Mental Health Medications?</td>
      <td>{patient.mental_health_medications}</td>
    </tr>,
    <tr>
      <td>Other</td>
      <td>{patient.mental_health_medications_other}</td>
    </tr>,
    <tr>
      <td>Other Weight Loss Surgeries?</td>
      <td>{patient.weight_loss_surgeries}</td>
    </tr>,
    <tr>
      <td>Other</td>
      <td>{patient.weight_loss_surgeries_other}</td>
    </tr>,
  ];
  if (results && results.anxiety_attacks) {
    info_table.push(
      <tr>
        <td>Anxiety attacks?</td>
        <td>{results.anxiety_attacks}</td>
      </tr>
    );
  }
  info_table.push(
    <tr>
      <td>Binge Eating</td>
    </tr>
  );

  let binge_eating;

  if (patient.binge_eating) binge_eating = patient.binge_eating;

  if (binge_eating && Array.isArray(binge_eating))
    binge_eating.forEach((item, key) => {
      info_table.push(
        <tr>
          <td>{item.name}</td>
          <td>{item.value}</td>
        </tr>
      );
    });

  return (
    <div className="page admin form detail">
      <h1>
        Patient Detail: {patient.first_name} {patient.last_name}
      </h1>
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          onSavePatient();
        }}
      >
        Save
      </button>
      <a className="btn left" href="/admin">
        Cancel
      </a>
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          onSavePatient(`/admin/patient_form/${patientID}`, true);
        }}
      >
        Patient Notes
      </button>

      <a className="btn left" href={`/admin/patient_report/${patientID}`}>
        Patient Report
      </a>
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          onSavePatient(`/admin/admin_notes/${patientID}`, true);
        }}
      >
        Admin Notes
      </button>
      <a
        className={`btn left${patient.fullslate_id ? "" : " disabled"}`}
        href={`/admin/new_appointment/${patientID}`}
      >
        New Appointment
      </a>
      <div class="detail-area">
        <table className="fullwidth">
          <tbody>
            <tr>
              <td>
                <input
                  style={{ display: "inline", marginTop: "10px" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("counseling_opt_in", e.target.checked);
                  }}
                  defaultChecked={patient.counseling_opt_in ? "checked" : ""}
                />
                &nbsp;
                <label style={{ display: "inline" }}>Counseling Opt-in</label>
              </td>
            </tr>
            <tr>
              {[
                "Therapist",
                "Scheduled Date",
                "Scheduled Time",
                "Phone Number",
                "State",
                "DOB",
                "Procedure",
                "Invoicing Status",
                "Invoice To",
                "Interview Status",
                "Report Status",
              ].map((item) => (
                <th key={item}>
                  <strong>{item}</strong>
                </th>
              ))}
            </tr>
            <tr>
              <td>{patient.therapist}</td>
              <td>{patient.appointment_date_modified_nice}</td>
              <td>{patient.appointment_time}</td>
              <td>
                <input
                  type="text"
                  onChange={(e) => onUpdateField("phone", e.target.value)}
                  defaultValue={patient.phone}
                />
              </td>
              <td>
                <input
                  type="text"
                  onChange={(e) => onUpdateField("state", e.target.value)}
                  defaultValue={patient.state}
                />
              </td>
              <td>
                <Cleave
                  options={{
                    date: true,
                    delimiter: '-',
                    datePattern: ["Y", "m", "d"],
                  }}
                  placeholder="YYYY-MM-DD"
                  value={patient.dob_string}
                  onBlur={(e) =>
                    onUpdateField(
                      "dob_string",
                      e.target.value
                    )
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  onChange={(e) => onUpdateField("procedure", e.target.value)}
                  defaultValue={patient.procedure}
                />
              </td>
              <td>
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("invoicing_status", e.target.value);
                  }}
                >
                  {invoicing_statuses}
                </select>
              </td>
              <td>
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("invoice_to", e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {invoice_tos}
                </select>
              </td>
              <td>
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("scheduling_status", e.target.value);
                  }}
                >
                  {scheduling_statuses}
                </select>
              </td>
              <td>
                {patient.report_status}
                <br />
                <input
                  style={{ display: "inline", marginTop: "10px" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("not_recommended", e.target.checked);
                  }}
                  defaultChecked={patient.not_recommended ? "checked" : ""}
                />
                &nbsp;
                <label style={{ display: "inline" }}>Not Recommended</label>
              </td>
            </tr>

           {appt_details}

            <tr>
              <td>
                <strong>APB Notes</strong>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <textarea
                  onChange={(e) => onUpdateField("apb_notes", e.target.value)}
                  defaultValue={patient.apb_notes}
                />
              </td>
            </tr>

            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <h3>Demographics</h3>
              </td>
            </tr>
            {cancellation_reason}
            <tr>
              <td>
                <strong>Address</strong>
              </td>
              <td colspan="2">
                {patient.address}
                <br />
                {patient.city}, {patient.state} {patient.zip}
              </td>
              <td colspan="2">
                <input
                  style={{ display: "inline", marginTop: "10px" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("remove_patient", e.target.checked);
                  }}
                  defaultChecked={patient.remove_patient ? "checked" : ""}
                />
                &nbsp;
                <label style={{ display: "inline" }}>Remove</label>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Surgeon</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("surgeon", e.target.value)}
                  defaultValue={patient.surgeon}
                />
              </td>
              <td colspan="2">
                <input
                  style={{ display: "inline", marginTop: "10px" }}
                  type="checkbox"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("do_not_contact", e.target.checked);
                  }}
                  defaultChecked={patient.do_not_contact ? "checked" : ""}
                />
                &nbsp;
                <label style={{ display: "inline" }}>Do not contact</label>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Age</strong>
              </td>
              <td colspan="2">{patient.age}</td>
            </tr>
            <tr>
              <td>
                <strong>Gender</strong>
              </td>
              <td colspan="2">{patient.gender}</td>
            </tr>
            <tr>
              <td>
                <strong>Language</strong>
              </td>
              <td colspan="2">{patient.patient_language}</td>
            </tr>
            <tr>
              <td>
                <strong>Spanish language provider preferred</strong>
              </td>
              <td colspan="2">{patient.spanish_provider_preferred}</td>
            </tr>
            {super_therapist_content}
            <tr>
              <td>
                <strong>Marital Status</strong>
              </td>
              <td colspan="2">
                {
                  patient.marital_status
                  // #TODO: add this in to parsePatient
                }
              </td>
            </tr>
            <tr>
              <td>
                <strong>Occupation</strong>
              </td>
              <td colspan="2">{patient.occupation}</td>
            </tr>
            <tr>
              <td>
                <strong>Height</strong>
              </td>
              <td colspan="2">
                {patient.height_feet}' {patient.height_inches}"
              </td>
            </tr>
            <tr>
              <td>
                <strong>Weight</strong>
              </td>
              <td colspan="2">{patient.weight} lb.</td>
            </tr>
            <tr>
              <td>
                <strong>BMI</strong>
              </td>
              <td colspan="3">{patient.bmi}</td>
            </tr>
            <tr>
              <td>
                <strong>Tobacco Use</strong>
              </td>
              <td colspan="3">{patient.tobacco_use}</td>
            </tr>
            <tr>
              <td>
                <strong>Informed Consent</strong>
              </td>
              <td colspan="3">{patient.informed_consent}</td>
            </tr>
            <tr>
              <td>
                <strong>Estimated Surgery Date</strong>
              </td>
              <td colspan="3">{patient.estimated_surgery_date}</td>
            </tr>
            <tr>
              <td>
                <strong>Testing Raw Data</strong>
              </td>
              <td colspan="3">{csv_link}</td>
            </tr>
            <tr>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Results</strong>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <table>{info_table}</table>
              </td>
              <td colspan="4">
                <table>{results_table}</table>
              </td>
            </tr>
            <tr>
              <td>
                <p>&nbsp;</p>
              </td>
            </tr>
            <tr>
              <td colspan="10">
                <table>
                  <tr>
                    <th>Key</th>
                    <th>Name</th>
                    <th>Value</th>
                  </tr>
                  {table}
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TherapistView;
