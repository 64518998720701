import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { useEditor, EditorContent } from '@tiptap/react'
import { Editor } from "@tiptap/core";
import StarterKit from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Underline from '@tiptap/extension-underline'

import MenuBar from './MenuBar.js';


const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, 
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Table.configure({
    resizable: true,
  }),
  Underline,
  TableRow,
  TableHeader,
  TableCell,

]

const TipTapEditor = ({content, onChange}) => {
      const editor = useEditor({
            extensions: extensions,
            content: content,
            onUpdate: ({ editor }) => {
                  onChange(editor.getHTML());
            }
      })

      if(!editor) {
            return null;
      }


  return (
      <>    
            <MenuBar editor={editor} />
            <EditorContent editor={editor} />
      </>
  )
}

export default TipTapEditor
