import React, { useEffect, useMemo, useState } from 'react'
import moment from "moment-timezone";

const label = {
  'payor_name': 'Payor Name',
  'contact_number': 'Contact Number',
  'rep_name': 'Rep Name',
  'reference': 'Reference',
  'plan_type': 'Plan Type',
  'provider_network': 'Provider Network Status',
  'group_network': 'Group Network Status',
  'mental_health': 'Mental Health',
  'telehealth': 'Telehealth',
  'verification_status': 'Verification Status',
  'deductible': 'Deductible',
  'deductible_annual_reset_date': 'Deductible Annual Reset Date',
  'deductible_applies': 'Deductible Applies',
  'deductible_remaining': 'Deductible Remaining',
  'ind_deductible_remaining': 'Individual Deductible Remaining',
  'fam_deductible_remaining': 'Family Deductible Remaining',
  'oon_benefits': 'OON Benefits',
  'oop_max': 'OOP Max Remaining',
  'oop_max_applies': 'OOP Max Applies',
  'ind_oop_max': 'Individual OOP Max Remaining',
  'fam_oop_max': 'Family OOP Max Remaining',
  'ins_copay': 'Copay',
  'copay_applies': 'Copay Applies',
  'co_ins_applies': 'Co Insurance Applies',
  'co_insurance': 'Co-insurance(%)',
  'submit_claim': 'Submit Claim',
  'invoice_url': 'Invoice URL',
  'verification_notes': 'Verification Notes',
}

const InvoiceModalContent = ({ patient, onUpdatePatient, onClose }) => {
  const [isOverride, setIsOverride] = useState(false)
  const [isOverrideDue, setIsOverrideDue] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [showClaim, setShowClaim] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [showSecondaryBill, setShowSecondaryBill] = useState(false)
  const [showInvoiceTo, setShowInvoiceTo] = useState(false)
  const [value, setValue] = useState({ ...patient })
  const [totalDue, setTotalDue] = useState(patient.copay || 0)
  const [dueDays, setDueDays] = useState(2)
  const [showSendInvoice, setShowSendInvoice] = useState(false)
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false)
  const [isSendingInvoice, setIsSendingInvoice] = useState(false)
  const [isSentInvoice, setIsSentInvoice] = useState(false)
  const [isSentEmail, setIsSentEmail] = useState(false)
  const [invoice, setInvoice] = useState(null)

  // const [description, setDescription] = useState('')

  const itemDescription = useMemo(() => {
    if (['Bariatric Counseling', 'Chronic Pain Counseling'].includes(patient.procedure)) {
      return `Advantage Point Behavioral Session ${patient.session}`
    }
    return 'Advantage Point Behavioral Assessment'
  }, [patient])

  const updatePatient = async (newValue) => {
    try {
      const token = localStorage.getItem("token");
      await fetch(
        `${window.server_url}/admin/patients/${patient._id}`,
        {
          method: "PUT",
          body: JSON.stringify(newValue),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error)
    }
  }

  const onConfirmDueAmount = async () => {
    if (isOverrideDue) {
      setIsOverrideDue(false)
    } else {
      setIsOverride(false)
      setShowResult(false)
      if ((patient.invoice_id || invoice) && parseInt(totalDue) !== 0) {
        setShowSendInvoice(true)
      } else {
        setShowPreview(true)
      }
    }

    onUpdatePatient((prev) => ({
      ...prev,
      ...value,
      invoice_to: value.invoice_to || 'Patient',
      copay: totalDue,
    }));
    setValue((prev) => ({
      ...prev,
      invoice_to: value.invoice_to || 'Patient',
      copay: totalDue,
    }));

    try {
      await updatePatient({
        ...value,
        invoice_to: value.invoice_to || 'Patient',
        copay: totalDue,
      })
    } catch (error) {
      console.log(error);
    }
  }

  const onCreateInvoice = async () => {
    if (totalDue > 0) {
      setShowPreview(false)
      setShowSendInvoice(true)
    }
    setIsCreatingInvoice(true)

    try {
      const token = localStorage.getItem("token");
      const res = await fetch(
        `${window.server_url}/admin/invoice/${patient._id}`,
        {
          method: "POST",
          body: JSON.stringify({ amountDue: totalDue, dueDays, itemDescription }),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
      if (parseInt(totalDue) === 0) {
        setIsSentEmail(true)
      }
      console.log(res)
      setInvoice(res.data)
    } catch (error) {
      console.log(error);
    }
    setIsCreatingInvoice(false)
  }

  const onSendInvoice = async () => {
    setIsSendingInvoice(true)

    onUpdatePatient((prev) => ({
      ...prev,
      ...value,
      invoicing_status: 'Unpaid',
    }));
    setValue((prev) => ({
      ...prev,
      invoicing_status: 'Unpaid',
    }));

    try {
      await updatePatient({
        ...value,
        invoicing_status: 'Unpaid',
      })
      const token = localStorage.getItem("token");
      await fetch(
        `${window.server_url}/admin/invoice/${patient._id}/send`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
      setIsSentInvoice(true)
      // window.location.reload()
    } catch (error) {
      console.log(error);
    }
    setIsSendingInvoice(false)
  }

  useEffect(() => {
    if (patient && !patient.invoice_due_days) {
      if (['Bariatric Counseling', 'Chronic Pain Counseling'].includes(patient.procedure)) {
        const dayDiff = moment(patient.appointment_date_modified).diff(moment(), 'days');
        setDueDays(dayDiff)
      } else {
        setDueDays(2)
      }
    }
  }, [patient])

  useEffect(() => {
    if (value && !value.copay) {
      let totalDue
      if (value.submit_claim === 'Yes') {
        if (value.deductible === 'Met' || value.deductible === 'None') {
          if (value.ins_copay !== 'N/A') {
            totalDue = value.ins_copay
          } else if (value.co_insurance !== 'N/A') {
            totalDue = value.co_insurance
          }
        } else if (value.deductible === 'Not-Met') {
          if (value.network === 'IN') {
            totalDue = 'MAX Allowable'
          } else {
            if (value.procedure.includes('Bariatric/Weight-loss Surgery')) {
              totalDue = 200
            } else {
              totalDue = 100
            }
          }
          // totalDue = value.deductible_remaining
        }
      } else if (value.invoice_to === 'Patient' && value.submit_claim === 'No') {
        if (value.procedure.includes('Bariatric/Weight-loss Surgery')) {
          totalDue = 200
        } else {
          totalDue = 100
        }
      }
      if (totalDue) {
        setTotalDue(totalDue)
      }
      // setValue(prev => ({
      //   ...prev,
      //   copay: totalDue,
      // }))
    }
  }, [value])

  const fields = Object.keys(label)
  const invoice_tos = value.invoice_tos
    ? value.invoice_tos.map((status) => {
        return (
          <option value={status} selected={status === value.invoice_to}>
            {status}
          </option>
        )
      })
    : []

  const isTotalAmountPercentage = useMemo(() => {
    if (value['co_ins_applies'] === 'Yes' && value.co_insurance && !isNaN(value.co_insurance)) {
      return true
    }
    return false
  }, [value])

  const hasSecondInsurance = useMemo(() => {
    return !!value.sec_plan_type
  }, [value])

  const renderTable = () => (
    <table className="detail-area-table" style={{ margin: '12px auto' }}>
      <tr>
        <th colSpan={2}>Primary Insurance</th>
        {hasSecondInsurance && <th colSpan={2}>Secondary Insurance</th>}
      </tr>
      {fields.map((field, key) => (
        <tr key={key}>
          <th>{label[field]}</th>
          <td>
            {isOverride ? (
              <input
                type="text"
                value={value[field]}
                onChange={(e) => setValue(prev => ({
                  ...prev,
                  [field]: e.target.value
                }))}
              />
            ) : value[field]}
          </td>
          {hasSecondInsurance && !['invoice_url', 'verification_notes'].includes(field) && (
            <>
              <th>{field !== 'submit_claim' ? label[field] : 'Bill Secondary'}</th>
              <td>
              {isOverride ? (
                <input
                  type="text"
                  value={value[`sec_${field !== 'submit_claim' ? field : 'bill_secondary'}`]}
                  onChange={(e) => setValue(prev => ({
                    ...prev,
                    [`sec_${field !== 'submit_claim' ? field : 'bill_secondary'}`]: e.target.value
                  }))}
                />
              ) : value[`sec_${field !== 'submit_claim' ? field : 'bill_secondary'}`]}
            </td>
            </>
          )}
        </tr>
      ))}
      <tr>
        <th>
          <strong>Total Amount Due</strong>
        </th>
        <td>
          {isTotalAmountPercentage ? `${totalDue}%` : totalDue}
        </td>
      </tr>
      {isOverride && (
        <tr>
          <th>Reason for override</th>
          <td>
            <textarea
              value={value.override_reason}
              onChange={(e) => setValue(prev => ({
                ...prev,
                override_reason: e.target.value,
              }))}
            ></textarea>
          </td>
        </tr>
      )}
    </table>
  )

  if (showSendInvoice) {
    return (
      <div className="detail-area popover">
        {isCreatingInvoice && (<p>Creating Invoice ...</p>)}
        {isSendingInvoice && (<p>Sending Invoice ...</p>)}
        {(patient.invoice_id || invoice) && <p>Invoice Created.</p>}
        {isSentInvoice && <p>Invoice Has Been Sent!</p>}
        <table className="detail-area-table" style={{ margin: '12px auto' }}>
          <tr>
            <th>Invoice ID</th>
            <td>{patient.invoice_id || (invoice && invoice.id)}</td>
          </tr>
          <tr>
            <th>Patient Name</th>
            <td>{`${patient.first_name} ${patient.last_name}`}</td>
          </tr>
          <tr>
            <th>Email Address</th>
            <td>{patient.email}</td>
          </tr>
          <tr>
            <th>Procedure</th>
            <td>{patient.procedure}</td>
          </tr>
          <tr>
            <th>Amount Due</th>
            <td>{totalDue}</td>
          </tr>
          <tr>
            <th>Item Description</th>
            <td>{itemDescription}</td>
          </tr>
          <tr>
            <th>Due Days</th>
            <td>
              {dueDays}
            </td>
          </tr>
          {patient.last_invoice_sent_at && (
            <tr>
              <th>Last Invoice Sent At</th>
              <td>{moment(patient.last_invoice_sent_at).toISOString()}</td>
            </tr>
          )}
        </table>
        {isSentInvoice ? (
          <p>
            <button
              className={`btn default center`}
              onClick={() => {
                window.location.reload()
              }}
            >
              Close
            </button>
          </p>
        ) : (
          <p>
            <button
              disabled={isCreatingInvoice || isSendingInvoice}
              className={`btn default center`}
              onClick={onClose}
            >
              Cancel
            </button>
            <button disabled={isCreatingInvoice || isSendingInvoice} className={`btn center`} onClick={onSendInvoice}>
              Send Invoice
            </button>
          </p>
        )}
      </div>
    )
  }

  if (showResult) {
    return (
      <div className="detail-area popover">
        {renderTable()}
        <p>
          <label style={{fontSize: 12}}>
            Amount Due: {isTotalAmountPercentage ? `${totalDue}%` : totalDue}
          </label>
        </p>
        {isOverrideDue && (
          <p>
            <input
              type="text"
              value={totalDue}
              onChange={(e) => setTotalDue(e.target.value)}
            />
          </p>
        )}
        <p>
          {!isOverrideDue && (
            <button
              className={`btn default center`}
              onClick={() => setIsOverrideDue(true)}
            >
              Override
            </button>
          )}
          <button disabled={typeof totalDue === 'undefined'} className={`btn center`} onClick={onConfirmDueAmount}>
            Confirm Amount Due
          </button>
        </p>
      </div>
    )
  }

  if (showPreview) {
    return (
      <div className="detail-area popover">
        <table className="detail-area-table" style={{ margin: '12px auto' }}>
          <tr>
            <th>Patient Name</th>
            <td>{`${patient.first_name} ${patient.last_name}`}</td>
          </tr>
          <tr>
            <th>Email Address</th>
            <td>{patient.email}</td>
          </tr>
          <tr>
            <th>Procedure</th>
            <td>{patient.procedure}</td>
          </tr>
          <tr>
            <th>Amount Due</th>
            <td>{totalDue}</td>
          </tr>
          <tr>
            <th>Item Description</th>
            <td>{itemDescription}</td>
          </tr>
          {totalDue > 0 && (
            <tr>
              <th>Due Days</th>
              <td>
                <input
                  type="number"
                  value={dueDays}
                  onChange={(e) => setDueDays(e.target.value)}
                />
              </td>
            </tr>
          )}
        </table>
        {totalDue === '0' && (
          <div>
            <h4>Email Preview</h4>
            <div style={{marginTop: 20}}>
              <h3>Hello {patient.first_name},</h3>
              {['Bariatric Counseling', 'Chronic Pain Counseling'].includes(patient.procedure) ? (
                <>
                  <p>Congratulations!</p>
                  <p>After verifying your insurance benefits and eligibility, you do not have any additional financial responsibility for your counseling session.</p>
                  <p>Please review your appointment confirmation details to ensure you are set up for a successful session with our therapist. Please note this is NOT just a phone call; we need you to click the link in your email (and text) messages at the time of your appointment, which is scheduled for {moment(patient.appointment_date_modified).format('MM/DD/YYYY')}.</p> 
                  <p>Thank you,</p>
                  <br/>
                  <p>Counseling at Advantage Point Behavioral</p>
                  <p>877-583-5633</p>
                  <p><a href="mailto:counseling@advantagepointbehavioral.com">counseling@advantagepointbehavioral.com</a></p>
                </>
              ) : (
                <>
                  <p>Congratulations!</p>
                  <p>After verifying your insurance benefits and eligibility, you do not have any additional financial responsibility for your psychological evaluation, testing, and consultation for your surgical clearance.</p>
                  <p>Please review your appointment confirmation details to ensure you are set up for a successful session with our therapist. Please note this is NOT just a phone call; we need you to click the link in your email (and text) messages at the time of your appointment, which is scheduled for {moment(patient.appointment_date_modified).format('MM/DD/YYYY')}. You will receive another email reminder 24 hours before, and a text reminder one hour prior to your appointment.</p> 
                  <p>Thank you, and do not hesitate to call us with any additional questions.</p>
                  <br/>
                  <p>Advantage Point Behavioral</p>
                  <p>877-583-5633</p>
                </>
              )}
            </div>
          </div>
        )}
        {isSentEmail && (
          <div style={{ paddingTop: 24, paddingBottom: 24 }}>
            <p>Email has been sent!</p>
          </div>
        )}
        {isSentEmail ? (
          <p>
            <button
              className={`btn default center`}
              onClick={() => {
                window.location.reload()
              }}
            >
              Close
            </button>
          </p>
        ) : (
          <p>
            <button
              disabled={isCreatingInvoice}
              className={`btn default center`}
              onClick={() => {
                setShowPreview(false)
                setShowResult(true)
              }}
            >
              Back
            </button>
            <button
              disabled={isCreatingInvoice}
              className={`btn center`}
              onClick={onCreateInvoice}
            >
              {totalDue > 0 ? 'Create Invoice' : 'Send Email'}
            </button>
          </p>
        )}
      </div>
    )
  }

  if (showClaim) {
    return (
      <div className="detail-area popover">
        {renderTable()}
        <p>
          <label style={{fontSize: 12}}>Submit Claim</label>
          <br />
          <select
            style={{border: '1px solid rgba(0, 0, 0, 0.5)'}}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => {
              setValue(prev => ({
                ...prev,
                submit_claim: e.target.value,
              }))
            }}
          >
            {['Yes', 'No', 'Review'].map(item => (
              <option key={item} value={item} selected={item === value.submit_claim}>
                {item}
              </option>
            ))}
          </select>
        </p>
        <p>
          <button
            className={`btn default center`}
            onClick={() => {
              setShowClaim(false)
              setShowInvoiceTo(true)
            }}
          >
            Back
          </button>
          <button className={`btn center`} onClick={() => {
            setShowResult(true)
          }}>
            Confirm
          </button>
        </p>
      </div>
    )
  }

  if (showSecondaryBill) {
    return (
      <div className="detail-area popover">
        {renderTable()}
        <p>
          <label style={{fontSize: 12}}>Bill Secondary?</label>
          <br />
          <select
            required
            style={{border: '1px solid rgba(0, 0, 0, 0.5)'}}
            onClick={(e) => {
              e.stopPropagation()
            }}
            value={value.sec_bill_secondary}
            onChange={(e) => {
              setValue(prev => ({
                ...prev,
                sec_bill_secondary: e.target.value,
              }))
            }}
          >
            <option value="">Select</option>
            <option value="No">No</option>
            <option value="Bill as secondary">Bill as secondary</option>
            <option value="Bill as primary">Bill as primary</option>
          </select>
        </p>
        <p>
          <button
            className={`btn default center`}
            onClick={() => setShowInvoiceTo(false)}
          >
            Back
          </button>
          <button
            disabled={!value.invoice_to || !value.sec_bill_secondary}
            className={`btn center`}
            onClick={() => {
              setShowClaim(true)
            }}
          >
            Confirm
          </button>
        </p>
      </div>
    )
  }

  if (showInvoiceTo) {
    return (
      <div className="detail-area popover">
        {renderTable()}
        <p>
          <label style={{fontSize: 12}}>Invoice To</label>
          <br />
          <select
            style={{border: '1px solid rgba(0, 0, 0, 0.5)'}}
            onClick={(e) => {
              e.stopPropagation()
            }}
            onChange={(e) => {
              setValue(prev => ({
                ...prev,
                invoice_to: e.target.value,
              }))
            }}
          >
            <option value="">Select</option>
            {invoice_tos}
          </select>
        </p>
        <p>
          <button
            className={`btn default center`}
            onClick={() => setShowInvoiceTo(false)}
          >
            Back
          </button>
          <button
            disabled={!value.invoice_to}
            className={`btn center`}
            onClick={() => {
              if (value.invoice_to !== 'Patient') {
                setTotalDue(0)
                setValue(prev => ({
                  ...prev,
                  submit_claim: 'No',
                }))
              }
              if (hasSecondInsurance) {
                setShowSecondaryBill(true)
              } else {
                setShowClaim(true)
              }
            }}
          >
            Confirm
          </button>
        </p>
      </div>
    )
  }

  return (
    <div className="detail-area popover">
      {renderTable()}
      <p>
        {!isOverride && (
          <button
            className={`btn default center`}
            onClick={() => setIsOverride(true)}
          >
            Override
          </button>
        )}
        <button className={`btn center`} onClick={() => {
          setIsOverride(false)
          setShowInvoiceTo(true)
        }}>
          Confirm
        </button>
      </p>
    </div>
  )
}

export default InvoiceModalContent
