import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import isEqual from 'lodash.isequal';
import Loading from "./Loading";
import SuperAdminView from "./patient/SuperAdminView";
import AdvantumView from "./patient/AdvantumView";
import AdminView from "./patient/AdminView";
import TherapistView from "./patient/TherapistView";
import VerificationView from "./patient/VerificationView";
import parsePatient from "./parsePatient";
import moment from "moment-timezone";
import { site } from '../config/config'
import { convertTime } from '../helpers/time_helper'

const PatientDetail = () => {
  const { patient_id: patientID } = useParams();

  const [patient, setPatient] = useState(null);
  const [originalPatient, setOriginalPatient] = useState(null);
  const [loading, setLoading] = useState(false);

  const [therapists, setTherapists] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [level, setLevel] = useState(null);

  const loginToken = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${window.server_url}/admin/patients/${patientID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) return false;
      const res = await response.json();

      if (!res.data) {
        localStorage.removeItem("token");
        window.location.href = "/admin";
        return false;
      } else {
        setPatient(res.data);
        setOriginalPatient(res.data);
        setTherapists(res.therapists);
        if(res.appts) setAppointments(res.appts);
        setLevel(res.level);
      }
    } catch (e) {
      console.log(e);
    }
  };


  const savePatient = async (href = '/admin', _confirm = false) => {
    if(_confirm && !window.confirm('This will save the current patient info, are you sure you want to proceed?')) {
      return false;
    }
 
    const token = localStorage.getItem("token");
    const updates = {};

    for (const key in patient) {
      if (!isEqual(originalPatient[key], patient[key])) {
        updates[key] = (patient[key])
      }
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${window.server_url}/admin/patients/${patientID}`,
        {
          method: "PUT",
          body: JSON.stringify(updates),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) return false;
      await response.json();
      window.location.href = href;
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  
  const deleteAccount = async (account_id) => {

    try {
      console.log(account_id);
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${window.server_url}/admin/delete_account/`,
        {
          method: "POST",
          body: JSON.stringify({account_id: account_id}),
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) return false;
      await response.json();
      window.location.reload();
      
    } catch (e) {
      console.log(e);
    }
  }

  const hidePatient = async () => {
    const token = localStorage.getItem("token");

    setLoading(true);
    try {
      const response = await fetch(
        `${window.server_url}/admin/patients/hide/${patientID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) return false;
      await response.json();
      window.location.href = "/admin";
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!patient) {
      loginToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const update = (key, value) => {
    const _patient = Object.assign({}, patient);
    if (key === "therapist") {
      const parts = value.split("|");
      _patient["therapist_id"] = parts[0];
      _patient["therapist"] = parts[1];
    } else {
      _patient[key] = value;
    }
    if (key === "copay") {
      _patient["who_updated_copay"] = localStorage.getItem("emr-user-email");
    }
    if (key === "verification_notes") {
      _patient["who_updated_verification_notes"] =
        localStorage.getItem("emr-user-email");
    }
    setPatient(_patient);
  };

  if (loading) {
    return <Loading />;
  }

  if (!patient) {
    return null;
  }

  let appt_details, appt_list, total_time;

  if(appointments && appointments.length > 0) {
    appt_list = appointments.map((appt) => (
      <tr>
        <td>
          {moment.tz(appt.start_time, 'UTC').tz(site.timezone).format('MM/DD/YYYY h:mm:ss a')}
        </td>
        <td>
          {moment.tz(appt.end_time, 'UTC').tz(site.timezone).format('MM/DD/YYYY h:mm:ss a')}
        </td>
        <td>
          {convertTime(appt.duration_seconds)}
        </td>
      </tr>
    ));

    total_time = appointments.reduce( function(a, b){
        return parseInt(a) + parseInt(b.duration_seconds);
    }, 0);

    appt_details = [
      <tr>
        <td>
          <strong>Doxy Appointment Start</strong>
        </td>
        <td>
          <strong>End</strong>
        </td>
        <td>
          <strong>Length</strong>
        </td>
      </tr>];

    appt_details = appt_details.concat(appt_list);

    appt_details.push(
      <tr>
        <td colspan="2"><strong>Total time:</strong></td>
        <td>{convertTime(total_time)}</td>
      </tr>
    );
  }

  const parsedPatient = parsePatient(patient);

  if (level === "super_admin") {
    return (
      <SuperAdminView
        therapists={therapists}
        appt_details={appt_details}
        patient={parsedPatient}
        onHidePatient={hidePatient}
        onSavePatient={savePatient}
        onUpdateField={update}
        onUpdatePatient={setPatient}
        onDeleteAccount={deleteAccount}
      />
    );
  }
  if (level === "advantum") {
    return (
      <AdvantumView
        therapists={therapists}
        appt_details={appt_details}
        patient={parsedPatient}
        onSavePatient={savePatient}
        onUpdateField={update}
        onUpdatePatient={setPatient}
      />
    );
  }
  if (level === "admin") {
    return (
      <AdminView
        therapists={therapists}
        appt_details={appt_details}
        patient={parsedPatient}
        onSavePatient={savePatient}
        onDeleteAccount={deleteAccount}
        onUpdateField={update}
        onUpdatePatient={setPatient}
      />
    );
  }
  if (level === "therapist" || level === "super_therapist") {
    return (
      <TherapistView
        level={level}
        patient={parsedPatient}
        appt_details={appt_details}
        appointments={appointments}
        onSavePatient={savePatient}
        onUpdateField={update}
      />
    );
  }
  if (level === "verification") {
    return (
      <VerificationView
        patient={parsedPatient}
        onSavePatient={savePatient}
        onUpdateField={update}
      />
    );
  }
  return null;
};

export default PatientDetail;
