import React from 'react';
import moment from 'moment';
import parseUser from './parseUser';
import {us_states} from '../helpers/select_helper';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';

class UserDetail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	filter: null,
      user: null,
      loading: true,
      new: true,
    	therapists: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.filterResults = this.filterResults.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.therapistSelect = this.therapistSelect.bind(this);
    this.apiKey = this.apiKey.bind(this);
    this.emailInput = React.createRef();
    this.nameInput = React.createRef();
    this.passwordInput = React.createRef();
    this.levelInput = React.createRef();
    this.therapistInput = React.createRef();
    this.stateInput = React.createRef();
    this.signatureInput = React.createRef();
  }
  componentDidMount() {
  	if(!this.state.user) this.loginToken();
  }
  filterResults(value) {
  	this.setState({filter: value});
  }
  update(key, value) {
    let _user = Object.assign({}, this.state.user);
    _user[key] = value;
    this.setState({user: _user});
  }
  therapistSelect(user) {
    if(user.level === 'therapist') {
      return (
        <tr>
          <td colSpan="1">Assign Fullslate Therapist</td>
          <td colSpan="4">
            <select onChange={e => {this.update('therapist_id', e.target.value)}} ref={this.therapistInput}>
              {this.state.therapists ? this.state.therapists.map(therapist => {
                return <option value={therapist.fullslate_id} selected={user.therapist_id === therapist.fullslate_id}>{therapist.first_name} {therapist.last_name}{therapist.inactive === true ? ' (inactive)' : ''}</option>
              }) : ''}
            </select>
          </td>
        </tr>
      )
    } else return '';
  }
  stateSelect(user) {
    if(user.level === 'therapist') {
      return (
        <tr>
          <td colSpan="1">Home State</td>
          <td colSpan="4">
            <select onChange={e => {this.update('home_state', e.target.value)}} ref={this.stateInput}>
              {us_states.map(state => {
                return <option value={state} selected={user.home_state === state}>{state}</option>
              })}
            </select>
          </td>
        </tr>

      )
    } else return '';
  }
  signatureSelect(user) {
    if(user.level === 'therapist') {
      return (
        <tr>
          <td colSpan="1">Signature Image</td>
          <td colSpan="4">
            <select onChange={e => {this.update('signature_image', e.target.value)}} ref={this.signatureInput}>
              <option value="">Select One</option>
              {this.state.signatures ? this.state.signatures.map(signature => {
                return <option value={signature} selected={user.signature_image === signature}>{signature}</option>
              }) : ''}
            </select>
          </td>
        </tr>
      )
    } else return '';
  }
  apiKey(user) {
    if(user.level === 'api' && user.api_key && user.api_key != '') {
      return (
        <tr>
          <td colSpan="1">API Key</td>
          <td colSpan="4"><input readOnly={true} value={user.api_key} /></td>
        </tr>
      )
    } else return '';
  }
	loginToken() {
    let token = localStorage.getItem('token'),
    		that = this,
        id;

    if(this.props.match.params.user_id && this.props.match.params.user_id != 'add') id = this.props.match.params.user_id;
    else id = 'add';

    fetch(window.server_url + '/admin/users/' + id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	window.location.href = '/admin/users';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data._id) {
        	// localStorage.removeItem('token');
        	 
          that.setState({
            new: true,
            loading: false,
            therapists: res.therapists,
            signatures: res.signatures
          });
        } else {
        	that.setState({
        		user: res.data,
            new: false,
            loading: false,
            therapists: res.therapists,
            signatures: res.signatures
        	})
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  updateUser() {
    let that = this,
        _user = {};


    if(this.state.user._id) _user.user_id = this.state.user._id;
    if(this.state.user.password && this.state.user.password.trim() !== '') _user.password = this.state.user.password;
    _user.email = this.state.user.email;
    _user.level = this.state.user.level;
    _user.therapist_id = this.state.user.level === 'super_therapist' ? null : this.state.user.therapist_id;
    _user.name = this.state.user.name;
    _user.home_state = this.state.user.home_state;
    _user.signature_image = this.state.user.signature_image;

    let data = {
      user: _user
    };

    let method;
    if(this.state.new) method = 'POST';
    else method = 'PUT';

    fetch(window.server_url + '/users', {
        method: method,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+localStorage.getItem('token'),
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data) {
          // localStorage.removeItem('token');
           window.location.href = '/admin/users';
          return false;
        } else {
          // that.setState({
          //   user: res.data
          // })
        }
      });
      this.setState({loading: true});
  }
  render() {
    if(this.state.loading === true) return <Loading />;

    console.log(this.state)
    let user;

  	if(this.state.user) {
      user = this.state.user;
    	let that = this;
  		
      user = parseUser(user);  		
    } else {
      user = {};
    }

    let levels = [
      {
        name: '',
        level: ''
      },
      {
        name: 'Admin',
        level: 'admin'
      },
      {
        name: 'Advantum',
        level: 'advantum'
      },
      {
        name: 'Super Admin',
        level: 'super_admin'
      },
      {
        name: 'Therapist',
        level: 'therapist'
      },
      {
        name: 'Super Therapist',
        level: 'super_therapist'
      },
      {
        name: 'Verification',
        level: 'verification'
      },
      {
        name: 'API Key',
        level: 'api'
      }
    ]
  	return (
    	<div className="page admin form detail">
	    	<h1>{this.state.new ? 'New User' : 'User Detail: '+user.email}</h1>
        <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.updateUser()}}>Save</a>
        <a className="btn left" href="/admin/users">Cancel</a>
        <div class="detail-area">
        <table className="fullwidth user-form mt-5">
          <tbody>
            <tr>
              <td colSpan="1">Email</td>
              <td colSpan="4"><input onChange={e => {this.update('email', e.target.value)}} ref={this.emailInput} value={user.email} /></td>
            </tr>
            <tr>
              <td colSpan="1">Name</td>
              <td colSpan="4"><input onChange={e => {this.update('name', e.target.value)}} ref={this.nameInput} value={user.name} /></td>
            </tr>
            <tr>
              <td colSpan="1">Level</td>
              <td colSpan="4">
                <select onChange={e => {this.update('level', e.target.value)}} ref={this.levelInput}>
                  {levels.map(status => {
                    return <option value={status.level} selected={user.level === status.level}>{status.name}</option>
                  })}
                </select>
              </td>
            </tr>
            {this.therapistSelect(user)}
            {this.stateSelect(user)}
            {this.signatureSelect(user)}
            {this.apiKey(user)}
            <tr>
              <td colSpan="1">Change Password</td>
              <td colSpan="4"><input onChange={e => {this.update('password', e.target.value)}} ref={this.passwordInput} type="password" defaultValue="" /></td>
            </tr>
          </tbody>
        </table>
        </div>
	    </div>
    );
    
  }
}

export default UserDetail;