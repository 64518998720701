import React from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { site } from './config/config'
import "react-datepicker/dist/react-datepicker.css";
import TagManager from 'react-gtm-module'

class Search extends React.Component {  
  constructor(props){
    super(props);
    this.state = {
      from: null,
      to: null,
      results: null,
      selected_time: null,
      contact_if_earlier_appt_available: this.props.user.contact_if_earlier_appt_available,
      insurance_id: null,
      booked: false,
      ready: false,
      diff: false,
      loading: false,
      early_appt_loading: false
    }
    this.clearResults = this.clearResults.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.confirmAppointment = this.confirmAppointment.bind(this);
    this.bookAppointment = this.bookAppointment.bind(this);
    this.setEarlyAppointment = this.setEarlyAppointment.bind(this);
  }
  async componentDidMount() {
    let data = {};

    if (localStorage.getItem("patient_id")) {
      let patient;

      const patient_res = await fetch(
        window.server_url +
          "/patients/" +
          localStorage.getItem("patient_id"),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      patient = await patient_res.json();
      // if patient was created over 3 days ago, reset localSTorage
      console.log(patient.data.createdAt, moment(patient.data.createdAt), moment(), moment(patient.data.createdAt).diff(moment(), 'days'));
      if(patient.data.createdAt && moment(patient.data.createdAt).diff(moment(), 'days') <= -3) {
        localStorage.clear();
        window.location.href = '/';
      }
      
    }
    // if(localStorage.getItem('results')) {
    //   data.results = JSON.parse(localStorage.getItem('results'));
    // }
    if(localStorage.getItem('selected_time')) {
      data.selected_time = localStorage.getItem('selected_time');
    }
    if(localStorage.getItem('booked')) {
      data.booked = localStorage.getItem('booked');
    }
    if(localStorage.getItem('insurance_id')) {
      data.insurance_id = localStorage.getItem('insurance_id');
    }
    this.setState(data);
  }
  componentDidUpdate(prevState) {
    if(this.state.booked && !prevState.booked) {
      localStorage.setItem('booked', this.state.booked);
    }
    if(this.state.selected_time && !prevState.selected_time) {
      localStorage.setItem('selected_time', this.state.selected_time);
    }
    // if(this.state.results && !prevState.results) {
    //   localStorage.setItem('results', JSON.stringify(this.state.results));
    // }
    // if(this.state.selected_time && !prevState.selected_time) {
    //   localStorage.setItem('selected_time', this.state.selected_time);
    // }
    if(this.state.insurance_id && !prevState.insurance_id) {
      localStorage.setItem('insurance_id', this.state.insurance_id);
    }
    // if(this.state.booked && !prevState.booked) {
    //   localStorage.clear();
    // }
    if((!this.state.to && !this.state.from) || this.state.to !== prevState.to || this.state.from !== prevState.from) {
      console.log(this.state.to, prevState.to);
      console.log(this.state.from, prevState.from);
      let ready = false;
      let offset = 2;
      let _to = this.state.to ? moment(this.state.to) : moment().add(offset + 2, 'days');
      let _from = this.state.from ? moment(this.state.from) : moment().add(offset, 'days');
      let _diff = _to.diff(_from, 'days');
      let too_much = Math.abs(_diff) > 90;

      if(_diff > 0 && !too_much) {
        ready = true;
      }
      if(this.state.ready !== ready || this.state.diff !== too_much) this.setState({ready: ready, diff: too_much})

    }
  }
  search(form) {
    var that = this;
    let offset = 2;
    var data = new FormData(form);
    var _from =this.state.from ? moment(this.state.from).format('YYYY-MM-DD') : moment().add(offset, 'days').format('YYYY-MM-DD');
    var _to = this.state.to ? moment(this.state.to).format('YYYY-MM-DD') : moment().add(offset+2, 'days').format('YYYY-MM-DD');

    fetch(window.server_url + '/search_appointments/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          patient_id: localStorage.getItem('patient_id'),
          user: this.props.user,
          from: _from,
          to: _to
        })
      })
      .then(function (response) {
          if (!response.ok) {
              throw Error(response.statusText);
          }
          return response.json();
      })
      .then(function (res) {
        if(!res || !res.data || !res.data.openings)  {
          that.setState({
            results: [],
            loading: false
          });
          return false;
        }
        let openings = res.data.openings.filter(function(result) {
          let date = moment(result);

          if(date.format('X') < moment().add(24, 'hours').format('X')) return false;

          return true;
        })
        that.setState({
          results: openings,
          insurance_id: res.insurance_id,
          loading: false
        })
      })
      .catch(error => console.log(error) );

      that.setState({
          loading: true,
          error_message: null
        })
  }
  bookAppointment() {
    var that = this;


    fetch(window.server_url + '/book_appointment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          user: this.props.user,
          timezone: new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset(),
          time: this.state.selected_time,
          insurance_id: this.state.insurance_id,
          patient_id: localStorage.getItem('patient_id')
        })
      })
      .then(function (response) {
          if (!response.ok) {
              throw Error(response.statusText);
          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        let error_message, 
            error_object, 
            specific_message = 'There was an issue booking your appointment. Please contact us at (877) 583-5633 or <a href="mailto:support@advantagepointbehavioral.com">support@advantagepointbehavioral.com</a> to complete booking.';


        if (res.error) {
          if(res.error.error) {
            error_object = JSON.parse(res.error.error);
            if(error_object && error_object.errorMessage) {
              error_message = error_object.errorMessage;
              if(error_message.indexOf('the selected time is no longer available') > -1) {
                specific_message = 'The selected time is no longer available. Please select another time, or contact us at (877) 583-5633 or <a href="mailto:support@advantagepointbehavioral.com">support@advantagepointbehavioral.com</a> to complete booking.';
              }
            }
          }
         that.setState({
            selected_time: null,
            results: null,
            error_message: specific_message,
            loading: false
          });
        } else if(!res.data.employee) {
          that.setState({
            selected_time: null,
            results: null,
            loading: false
          });
        } else {
        // #TODO: add error for this time is no longer available

          that.setState({
            booked: true,
            loading: false
          })
        }
      })
      .catch(error => console.log(error) );
       that.setState({
          loading: true
        })
  }
  setEarlyAppointment(value) {
    var that = this;


    fetch(window.server_url + '/set_early_appointment/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          user: this.props.user,
          patient_id: localStorage.getItem('patient_id'),
          value: value
        })
      })
      .then(function (response) {
          if (!response.ok) {
              throw Error(response.statusText);
          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.success) that.setState({
          early_appt_loading: false
        });
        // #TODO: add error for this time is no longer available
        else that.setState({
          early_appt_loading: false,
          contact_if_earlier_appt_available: res.value
        })
      })
      .catch(error => console.log(error) );
       that.setState({
          early_appt_loading: true
        })
  }
  searchForm() {
    let now = new Date();
    let offset = 2;

    let minDate = moment().add(offset, 'days').toDate();
    let selectedFromDate = this.state.from ? moment(this.state.from).toDate() : moment().add(offset, 'days').toDate();
    let selectedToDate = this.state.to ? moment(this.state.to).toDate() : moment().add(offset + 2, 'days').toDate();

    let error_message;

    if(this.state.error_message) {
      error_message = <div class="errors" dangerouslySetInnerHTML={{__html: this.state.error_message}} />;
    }
    console.log(minDate, selectedFromDate, selectedToDate);
    return (
      <div className="modal search">
        <form onSubmit={(e) => {e.preventDefault(); this.search(e.target)}}>
        {error_message}
        <h1>Book an appointment</h1>
        <h3>Search for appointments between:</h3>
        <div className="date-range">
          <DatePicker minDate={minDate} selected={selectedFromDate} onChange={value => this.setState({from: value})} name="from" />
          <span>and</span>
          <DatePicker selected={selectedToDate} onChange={value => this.setState({to: value})} name="to" />
        </div>
        {this.state.diff ? <p className="error">Please select a date range less than 90 days.</p> : ''}
        {this.state.results && this.state.results.length === 0 ? <p className="error">{site.no_appt_message}</p> : ''}
        <input type="submit" className={`btn search${this.state.ready && !this.state.loading ? '' : ' disabled'}`} value={this.state.loading ? 'Loading...' : 'Search'} />
        {site.allow_restart && !this.props.paid ? [<br />, <a className="btn margin-top" onClick={e=>{localStorage.clear(); window.location.reload()}}>Start Over</a>] : null}
        </form>
      </div>
    );
  }
  clearSelection() {
    localStorage.removeItem('selected_time');
    this.setState({selected_time: null});
  }
  clearResults() {
    localStorage.removeItem('results');
    this.setState({results: null});
  }
  confirmAppointment(time) {
    localStorage.setItem('selected_time', moment(time).format());

    this.setState({selected_time: moment(time).format()});
  }
  startOver() {
    localStorage.clear();
    window.location.href = '/';
  }
  searchResults() {
    let results = [];
    let that = this;

    this.state.results.forEach(function(result, index) {
      let date = moment(result);

      let _result = (
        <div key={index} className="result">
          {date.format('M/D/YYYY')} at {date.format('h:mm a')}
          <a href="#" className={`btn purple choose`} onClick={e => {that.confirmAppointment(result)}}>Select</a>
        </div>
      );
      results.push(_result);
    })
    return (
      <div className="modal search">
        <h1>Appointments</h1>
        <h3>Book an appointment below:</h3>
        <div className="results">{results}</div>
        <a className="btn back" onClick={this.clearResults}>Change Dates</a>
      </div>
    )
  }
  success() {
    let date = moment(this.state.selected_time);
    return (
      <div className="modal search confirm">
        <h1>Appointment confirmed</h1>
        <p>Your appointment has been confirmed.</p>
        <p>{date.format('M/D/YYYY')} at {date.format('h:mm a')}</p>        
        {site.success_message ? <p>{site.success_message}</p> : null}
        <p className={`early-appt ${this.state.early_appt_loading ? 'loading' : ''}`}><input name="early_appt" type="checkbox" checked={this.state.contact_if_earlier_appt_available == true} onClick={e => {this.setEarlyAppointment(e.target.checked)}} /> <label for="early_appt">Click here if you would like to be contacted if an earlier appointment becomes available.</label></p>
        <a className="btn next margin-bottom" href={`${site.domain}/verification/`}>Next Step: Verification</a>
        <a className="btn margin-left" onClick={e=>{localStorage.clear(); window.location.reload()}}>Start Over</a>
      </div>
    );
  }
  confirm() {
    let date = moment(this.state.selected_time);
    return (
      <div className="modal search confirm">
        <h1>Confirm appointment</h1>
        <p>You have selected the following appointment. Please review the information below, and click “confirm” to book the appointment.</p>
        <div className="results">
          <div className="result">
            {date.format('M/D/YYYY')} at {date.format('h:mm a')}
            <a href="#" className={`btn orange confirm${this.state.ready && !this.state.loading ? '' : ' disabled'}`} onClick={e => {this.bookAppointment()}}>{this.state.loading ? 'Loading...' : 'Confirm'}</a>
          </div>
        </div>
        <a className="btn back" onClick={this.clearSelection}>Back to results</a>
      </div>
    )

  }
  no_show_gate() {
    return (
      <div className="modal search confirm">
        <h1>Please contact us to complete scheduling.</h1>
        <p>Please call us at 877-583-5633 or email us at <a href="mailto:support@advantagepointbehavioral.com">support@advantagepointbehavioral.com</a><br/>
        so we can assist you in scheduling your appointment.</p>
      </div>
    )

  }
  render() {
    if(this.props.user && this.props.user.previous_no_show) return this.no_show_gate();
    else if(this.state.booked) return this.success();
    else if(this.state.selected_time) return this.confirm();
    else if(this.state.results && this.state.results.length) return this.searchResults();
    else return this.searchForm();
  }
}

export default Search;