import React from 'react';
import moment from 'moment-timezone';
import parseTherapist from './parseTherapist';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
import { site } from '../config/config'

class TherapistDetail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	filter: null,
      loading: true,
    	therapist: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.filterResults = this.filterResults.bind(this);
    this.update = this.update.bind(this);
    this.saveTherapist = this.saveTherapist.bind(this);
  }
  componentDidMount() {
  	if(!this.state.therapists) this.loginToken();
  }
  filterResults(value) {
  	this.setState({filter: value});
  }
  update(key, value) {
    let _therapist = Object.assign({}, this.state.therapist);
    if(key.indexOf('.') > -1) {
      key = key.split('.');
      if(!_therapist[key[0]]) _therapist[key[0]] = {};
      if(!_therapist[key[0]][key[1]] && key.length > 2) _therapist[key[0]][key[1]] = {};

      if(key.length == 2) _therapist[key[0]][key[1]] = value;
      if(key.length == 3) _therapist[key[0]][key[1]][key[2]] = value;
    } else {
      _therapist[key] = value;
    }
    this.setState({
      therapist: _therapist
    })
  }
  saveTherapist() {
    let token = localStorage.getItem('token'),
        that = this;

    fetch(window.server_url + '/admin/update_therapist/' + this.props.match.params.therapist_id, {
        method: 'PUT',
        body: JSON.stringify(this.state.therapist),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
            return false;
          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin/therapists';
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
	loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/get_therapist/' + this.props.match.params.therapist_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data) {
        	// localStorage.removeItem('token');
        	 window.location.href = '/admin';
        	return false;
        } else {
        	that.setState({
        		therapist: res.data,
            loading: false
        	})
        }
        
      })
      .catch(error => console.log(error) );  
  }
  render() {
    console.log(this.state)
    if(this.state.loading === true) return <Loading />;
    if(!this.state.therapist) return '';
    else {
    	let therapist = this.state.therapist;
    	let that = this;
  		
      therapist = parseTherapist(therapist);  		

    	return (
	    	<div className="page admin form detail">
		    	<h1>Therapist Detail: {therapist.first_name} {therapist.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.saveTherapist()}}>Save</a>
          <a className="btn left" href="/admin/therapists">Cancel</a>
          <div class="detail-area">
          <table className="fullwidth">
            <tbody>
              <tr>
                <th>
                  <strong>Therapist</strong>
                </th>
                <th>
                  <strong>States</strong>
                </th>
                <th>
                  <strong>Credentials</strong>
                </th>
                <th>
                  <strong>Patient PTD</strong>
                </th>
                <th>
                  <strong>Patient MTD</strong>
                </th>
                <th>
                  <strong>Patient YTD</strong>
                </th>{/*}
                <th>
                  <strong>Slots PTD</strong>
                </th>
                <th>
                  <strong>Slots MTD</strong>
                </th>
                <th>
                  <strong>Slots YTD</strong>
                </th>
              */ }
                <th>
                  <strong>Rank</strong>
                </th>
              </tr>
              <tr>
                <td>
                  <p>{therapist.first_name} {therapist.last_name}</p>
                </td>
                <td>
                  <p>{therapist.states}</p>
                </td>
                <td>
                  <p>{therapist.credentials}</p>
                </td>
                <td>
                  <p>{therapist.patient_ptd}</p>
                </td>
                <td>
                  <p>{therapist.patient_mtd}</p>
                </td>
                <td>
                  <p>{therapist.patient_ytd}</p>
                </td>
                {/*
                <td>
                  <p>{therapist.openings_ptd}</p>
                </td>
                <td>
                  <p>{therapist.openings_mtd}</p>
                </td>
                <td>
                  <p>{therapist.openings_ytd}</p>
                </td>
              */ }
                <td>
                  <input onChange={e => this.update('scheduling_rank', e.target.value)} defaultValue={therapist.scheduling_rank} />
                </td>
              </tr>
              <tr>
              <td>
              <p></p>
              </td>
              </tr>
              <tr>
                <td>
                  <strong>Credentials</strong>
                </td>
                <td colspan="2">
                  {therapist.credentials}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Phone</strong>
                </td>
                <td colspan="2">
                  {therapist.phone}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td colspan="2">
                  {therapist.email}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Address</strong>
                </td>
                <td colspan="2">
                  <textarea onChange={e => this.update('address', e.target.value)} defaultValue={therapist.address} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Time Zone</strong>
                </td>
                <td colspan="2">
                  <input onChange={e => this.update('timezone', e.target.value)} defaultValue={therapist.timezone} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>NPI #</strong>
                </td>
                <td colspan="2">
                  <input onChange={e => this.update('npi', e.target.value)} defaultValue={therapist.npi} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Taxonomy Code</strong>
                </td>
                <td colspan="2">
                  <input onChange={e => this.update('taxonomy_code', e.target.value)} defaultValue={therapist.taxonomy_code} />
                </td>
              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td><p><strong>State Licenses</strong></p></td>
                <td><p><strong>License #</strong></p></td>
              </tr>
              {therapist.licenses.map((license, index) => {
                return (
                  <tr>
                    <td>
                      <input onChange={e => this.update('licenses.'+index+'.state', e.target.value)} defaultValue={license.state} disabled />
                    </td>
                    <td>
                      <input onChange={e => this.update('licenses.'+index+'.license_number', e.target.value)} defaultValue={license.license_number} />
                    </td>
                  </tr>
                );
              })}
              <tr><td><p></p></td></tr>
              <tr>
                <td><p><strong>Credentialed Payors</strong></p></td>
                <td><p><strong>PAR ID</strong></p></td>
                <td><p><strong>Effective Date</strong></p></td>
              </tr>
              {therapist.credentialed_payors.map((c, index) => {
                return (
                  <tr>
                      <input onChange={e => this.update('credentialed_payors.'+index+'.payor', e.target.value)} defaultValue={c.payor} disabled />
                    <td>
                      <input onChange={e => this.update('credentialed_payors.'+index+'.par_id', e.target.value)} defaultValue={c.par_id} />
                    </td>
                    <td>
                      <Cleave 
                        options={{
                          date: true,
                          datePattern: ['m', 'd', 'Y']
                        }} 
                        placeholder="MM/DD/YYYY"
                        value={moment(c.effective_date).tz(site.timezone).format('MM/DD/YYYY')} 
                        onBlur={e => this.update('credentialed_payors.'+index+'.effective_date', moment(e.target.value).hour(10).format())} />
                    </td>
                  </tr>
                );
              })}    
              <tr><td><p></p></td></tr>
              <tr>
                <td><p><strong>LTM Monthly Volume</strong></p></td>
                <td><p><strong>Appointments</strong></p></td>
                <td><p><strong>Completes</strong></p></td>
                <td><p><strong>No-Shows</strong></p></td>
              </tr>  
              {therapist.ltm.map(month => {
                if(month === null) return false;
                return (
                  <tr>
                    <td>{month.name}</td>
                    <td>{month.appointments}</td>
                    <td>{month.completes}</td>
                    <td>{month.no_shows}</td>
                  </tr>
                );
              })}      
              <tr><td><p></p></td></tr>
              <tr>
                <td><p><strong>Invoices</strong></p></td>
                <td><p><strong>Amount</strong></p></td>
                <td><p><strong>Status</strong></p></td>
              </tr>  
              {therapist.invoices.map(invoice => {
                return (
                  <tr>
                    <td>{invoice.invoice_dates}</td>
                    <td>{invoice.amount}</td>
                    <td>{invoice.status}</td>
                  </tr>
                );
              })}      
            </tbody>
          </table>
          </div>
		    </div>
	    );
    }
  }
}

export default TherapistDetail;