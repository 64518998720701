import moment from 'moment'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const questions = [
  {
    id: 'patient_subscribed',
    question: 'Is the patient subscribed?',
    yes_no: true,
    action: {
      plan_type: true,
    },
  },
  {
    id: 'provider_network_status',
    question: 'What is the provider network status?',
    yes_no: true,
    options: ['IN', 'OON'],
  },
  {
    id: 'group_network_status',
    question: 'What is the group network status?',
    yes_no: true,
    options: ['IN', 'OON'],
  },
  {
    id: 'mental_health_benefit',
    question: 'Does this patient have mental health benefits?',
    yes_no: true,
  },
  {
    id: 'telehealth',
    question: 'Does this patient have telehealth?',
    yes_no: true,
  },
  {
    id: 'onn_benefit',
    question: 'Does this patient have ONN benefits?',
    yes_no: true,
  },
  {
    id: 'check_procedure',
    question: 'Check Procedure',
    yes_no: true,
    options: ['Bariatric Eval', 'Not Bariatic Eval'],
  },
  {
    id: 'need_bariatric_evaluation',
    question: 'Does this patient need Bariatirc Evaluation?',
    yes_no: true,
  },
  {
    id: 'deductible_applies',
    question: 'Does deductible apply?',
    yes_no: true,
    action: {
      remaining: true,
    },
  },
  {
    id: 'oop_max_applies',
    question: 'Does OOP Max apply?',
    yes_no: true,
    action: {
      oop_max: true,
    },
  },
  {
    id: 'copay_apply',
    question: 'Copay Applies?',
    yes_no: true,
    action: {
      copay: true,
    },
  },
  {
    id: 'coinsurance_apply',
    question: 'Coinsurance Applies?',
    yes_no: true,
    action: {
      coinsurance: true,
    },
  },
]

const labelMap = {
  'payor_name': 'Payor Name',
  'contact_number': 'Contact Number',
  'rep_name': 'Rep Name',
  'reference': 'Reference',
  'plan_type': 'Plan Type',
  'provider_network': 'Provider Network Status',
  'group_network': 'Group Network Status',
  'mental_health': 'Mental Health',
  'telehealth': 'Telehealth',
  'submit_claim': 'Submit Claim',
  'verification_status': 'Verification Status',
  'oon_benefits': 'OON Benefits',
  'oop_max': 'OOP Max Remaining',
  'oop_max_applies': 'OOP Max Applies',
  'ind_oop_max': 'Individual OOP Max Remaining',
  'fam_oop_max': 'Family OOP Max Remaining',
  'deductible': 'Deductible',
  'deductible_applies': 'Deductible Applies',
  'deductible_remaining': 'Deductible Remaining',
  'ind_deductible_remaining': 'Individual Deductible Remaining',
  'fam_deductible_remaining': 'Family Deductible Remaining',
  'ins_copay': 'Copay',
  'copay_applies': 'Copay Applies',
  'co_ins_applies': 'Co Insurance Applies',
  'co_insurance': 'Co-insurance(%)',
  'advantum_team_member': 'Advantum Team Member',
}

const notEditableFields = ['verification_date']

const EligibilityRunModalContent = ({ patient, onComplete, onClose }) => {
  const [insurance, setInsurance] = useState(null)
  const [confirmReset, setConfirmReset] = useState(false)
  const [curIndex, setCurIndex] = useState(null)
  const [nextIndex, setNextIndex] = useState(null)
  const [value, setValue] = useState({ ...patient })
  const [selection, setSelection] = useState({})
  const [stack, setStack] = useState([])
  const [isComplete, setIsComplete] = useState(false)
  const [isVerify, setIsVerify] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const prefix = insurance === 'primary' ? '' : 'sec_'

  const question = useMemo(() => {
    if (!curIndex) return null

    const quiz = questions.find((item) => item.id === curIndex)

    return quiz
  }, [curIndex])

  const onYes = useCallback(() => {
    console.log('On YES', curIndex)
    setSelection((prev) => ({
      ...prev,
      [curIndex]: true,
    }))
    let newValue = null, newNextIndex = null
    switch (curIndex) {
      case 'patient_subscribed':
        newNextIndex = 'provider_network_status'
        newValue = {
          [`${prefix}verification_status`]: 'Verified',
        }
        break
      case 'provider_network_status':
        newNextIndex = 'group_network_status'
        newValue = {
          [`${prefix}provider_network`]: 'IN',
          [`${prefix}network`]: 'IN',
        }
        break
      case 'group_network_status':
        newNextIndex = 'mental_health_benefit'
        newValue = {
          [`${prefix}group_network`]: 'IN',
        }
        break
      case 'mental_health_benefit':
        newNextIndex = 'telehealth'
        newValue = {
          [`${prefix}mental_health`]: 'Yes',
        }
        break
      case 'telehealth':
        if (value[`${prefix}provider_network`] === 'OON') {
          newNextIndex = 'onn_benefit'
          newValue = {
            [`${prefix}telehealth`]: 'Yes',
          }
        } else {
          if (value[`${prefix}mental_health`] === 'Yes') {
            newNextIndex = 'deductible_applies'
            newValue = {
              [`${prefix}telehealth`]: 'Yes',
              [`${prefix}submit_claim`]: 'Yes',
            }
          } else {
            // let copay = 100
            // if (patient.procedure === 'Bariatric/Weight-loss Surgery') {
            //   copay = 200
            // }
            newValue = {
              [`${prefix}telehealth`]: 'Yes',
              [`${prefix}submit_claim`]: 'No',
              // ins_copay: copay,
            }
          }
        }
        break
      case 'onn_benefit':
        newNextIndex = 'deductible_applies'
        if (value[`${prefix}provider_network`] === 'OON' && value[`${prefix}mental_health`] === 'Yes' && value[`${prefix}telehealth`] === 'Yes') {
          newValue = {
            [`${prefix}submit_claim`]: 'Yes',
            [`${prefix}oon_benefits`]: 'Yes',
          }
        } else {
          newValue = {
            [`${prefix}submit_claim`]: 'No',
            [`${prefix}oon_benefits`]: 'Yes',
          }
        }
        break
      case 'check_procedure':
        newValue = {
          [`${prefix}ins_copay`]: 200,
        }
        break
      case 'need_bariatric_evaluation':
        if (value[`${prefix}deductible_remaining`] > 200) {
          newValue = {
            [`${prefix}ins_copay`]: 200,
          }
        } else {
          newValue = {
            [`${prefix}ins_copay`]: value[`${prefix}deductible_remaining`],
          }
        }
        break
      case 'deductible_applies':
        newNextIndex = 'oop_max_applies'
        newValue = {
          [`${prefix}deductible_applies`]: 'Yes',
          [`${prefix}ind_deductible_remaining`]: '',
          [`${prefix}fam_deductible_remaining`]: '',
        }
        break
      case 'oop_max_applies':
        newNextIndex = 'copay_apply'
        newValue = {
          [`${prefix}oop_max_applies`]: 'Yes',
          [`${prefix}ind_oop_max`]: '',
          [`${prefix}fam_oop_max`]: '',
        }
        break
      case 'copay_apply':
        newNextIndex = 'coinsurance_apply'
        newValue = {
          [`${prefix}co_ins_applies`]: 'No',
          [`${prefix}co_insurance`]: 'N/A',
          [`${prefix}copay_applies`]: 'Yes',
        }
        break
      case 'coinsurance_apply':
        newValue = {
          [`${prefix}co_ins_applies`]: 'Yes',
        }
        break
      default:
        break
    }

    if (newNextIndex) {
      setNextIndex(newNextIndex)
    }
    if (newValue) {
      setValue(prev => ({
        ...prev,
        ...newValue,
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    curIndex,
    prefix,
    value.deductible_remaining,
    value.mental_health,
    value.provider_network,
    value.telehealth,
    value.sec_deductible_remaining,
    value.sec_mental_health,
    value.sec_provider_network,
    value.sec_telehealth
  ])

  const onNo = useCallback(() => {
    console.log('On NO', curIndex)
    setSelection((prev) => ({
      ...prev,
      [curIndex]: false,
    }))
    let newValue = null, newNextIndex = null
    switch (curIndex) {
      case 'patient_subscribed':
        newValue = {
          [`${prefix}verification_status`]: 'Review',
        }
        break
      case 'provider_network_status':
        newNextIndex = 'group_network_status'
        newValue = {
          [`${prefix}provider_network`]: 'OON',
          [`${prefix}network`]: 'OON',
        }
        break
      case 'group_network_status':
        newNextIndex = 'mental_health_benefit'
        newValue = {
          [`${prefix}group_network`]: 'OON',
        }
        break
      case 'mental_health_benefit':
        newNextIndex = 'telehealth'
        newValue = {
          [`${prefix}mental_health`]: 'No',
        }
        break
      case 'telehealth':
        if (value[`${prefix}provider_network`] === 'OON') {
          newNextIndex = 'onn_benefit'
          newValue = {
            [`${prefix}telehealth`]: 'No',
            [`${prefix}submit_claim`]: 'No',
          }
        } else {
          // let copay = 100
          // if (patient.procedure === 'Bariatric/Weight-loss Surgery') {
          //   copay = 200
          // }
          newValue = {
            [`${prefix}telehealth`]: 'No',
            [`${prefix}submit_claim`]: 'No',
            // ins_copay: copay,
          }
        }
        break
      case 'onn_benefit':
        // newNextIndex = 'check_procedure'
        newValue = {
          [`${prefix}submit_claim`]: 'No',
          [`${prefix}ins_copay`]: 100,
          [`${prefix}oon_benefits`]: 'No',
        }
        break
      case 'check_procedure':
        newValue = {
          [`${prefix}ins_copay`]: 100,
        }
        break
      case 'need_bariatric_evaluation':
        if (value[`${prefix}deductible_remaining`] > 100) {
          newValue = {
            [`${prefix}ins_copay`]: 100,
          }
        } else {
          newValue = {
            [`${prefix}ins_copay`]: value[`${prefix}deductible_remaining`],
          }
        }
        break
      case 'deductible_applies':
        newNextIndex = 'oop_max_applies'
        newValue = {
          [`${prefix}deductible_applies`]: 'No',
          [`${prefix}deductible_remaining`]: 'N/A',
          [`${prefix}ind_deductible_remaining`]: 'N/A',
          [`${prefix}fam_deductible_remaining`]: 'N/A',
          [`${prefix}deductible`]: 'None',
        }
        break
      case 'oop_max_applies':
        newNextIndex = 'copay_apply'
        newValue = {
          [`${prefix}oop_max_applies`]: 'No',
          [`${prefix}oop_max`]: 'N/A',
          [`${prefix}ind_oop_max`]: 'N/A',
          [`${prefix}fam_oop_max`]: 'N/A',
        }
        break
      case 'copay_apply':
        newNextIndex = 'coinsurance_apply'
        newValue = {
          [`${prefix}copay_applies`]: 'No',
          [`${prefix}ins_copay`]: 'N/A',
        }
        break
      case 'coinsurance_apply':
        newValue = {
          [`${prefix}co_ins_applies`]: 'No',
          [`${prefix}co_insurance`]: 'N/A',
        }
        break
      default:
        break
    }
    if (newNextIndex) {
      setNextIndex(newNextIndex)
    }
    if (newValue) {
      setValue(prev => ({
        ...prev,
        ...newValue,
      }))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    curIndex,
    prefix,
    value.deductible_remaining,
    value.provider_network,
    value.sec_deductible_remaining,
    value.sec_provider_network
  ])

  const updateOOPMax = () => {
    if (value[`${prefix}ind_oop_max`] !== undefined && value[`${prefix}fam_oop_max`] !== undefined) {
      let oopMax
      if (value[`${prefix}ind_oop_max`] !== 'N/A' && value[`${prefix}fam_oop_max`] !== 'N/A') {
        oopMax = Math.min(value[`${prefix}ind_oop_max`], value[`${prefix}fam_oop_max`])
      } else if (value[`${prefix}ind_oop_max`] !== 'N/A' && value[`${prefix}fam_oop_max`] === 'N/A') {
        oopMax = value[`${prefix}ind_oop_max`]
      } else if (value[`${prefix}ind_oop_max`] === 'N/A' && value[`${prefix}fam_oop_max`] !== 'N/A') {
        oopMax = value[`${prefix}fam_oop_max`]
      } else {
        oopMax = 'N/A'
      }
      setValue((prev) => ({
        ...prev,
        [`${prefix}oop_max`]: oopMax,
      }))
    }
  }

  const updateDeductible = () => {
    if (
      value[`${prefix}ind_deductible_remaining`] !== undefined &&
      value[`${prefix}fam_deductible_remaining`] !== undefined
    ) {
      if (
        parseInt(value[`${prefix}ind_deductible_remaining`]) === 0 ||
        parseInt(value[`${prefix}fam_deductible_remaining`]) === 0
      ) {
        setValue((prev) => ({
          ...prev,
          [`${prefix}deductible`]: 'Met',
          [`${prefix}deductible_remaining`]: 0,
        }))
      } else if (
        parseInt(value[`${prefix}ind_deductible_remaining`]) > 0 ||
        parseInt(value[`${prefix}fam_deductible_remaining`]) > 0
      ) {
        let deductibleRemaining
        if (
          value[`${prefix}ind_deductible_remaining`] !== 'N/A' &&
          value[`${prefix}fam_deductible_remaining`] !== 'N/A'
        ) {
          deductibleRemaining = Math.min(
            value[`${prefix}ind_deductible_remaining`],
            value[`${prefix}fam_deductible_remaining`]
          )
        } else if (
          value[`${prefix}ind_deductible_remaining`] !== 'N/A' &&
          value[`${prefix}fam_deductible_remaining`] === 'N/A'
        ) {
          deductibleRemaining = value[`${prefix}ind_deductible_remaining`]
        } else if (
          value[`${prefix}ind_deductible_remaining`] === 'N/A' &&
          value[`${prefix}fam_deductible_remaining`] !== 'N/A'
        ) {
          deductibleRemaining = value[`${prefix}fam_deductible_remaining`]
        }
        setValue((prev) => ({
          ...prev,
          [`${prefix}deductible`]: 'Not-Met',
          [`${prefix}deductible_remaining`]: deductibleRemaining,
        }))
      } else if (
        value[`${prefix}ind_deductible_remaining`] === 'N/A' &&
        value[`${prefix}fam_deductible_remaining`] === 'N/A'
      ) {
        setValue((prev) => ({
          ...prev,
          [`${prefix}deductible`]: 'None',
          [`${prefix}deductible_remaining`]: 'N/A'
        }))
      }
    }
  }

  const onBack = () => {
    setIsComplete(false)
    setCurIndex(() => {
      if (stack.length > 0) {
        return stack[stack.length - 1]
      }
      return null
    })
    setStack(prev => prev.slice(0, -1))
  }

  const onNext = () => {
    if (question.action && question.action.remaining) {
      updateDeductible()
    }
    if (question.action && question.action.oop_max) {
      updateOOPMax()
    }
    if (nextIndex && curIndex !== nextIndex) {
      setStack(prev => [...prev, curIndex])
      setCurIndex(nextIndex)
    } else {
      // onComplete(value);
      setIsComplete(true)
      // setValue({});
      // setCurIndex(null);
      // setNextIndex(null);
    }
  }

  const onConfirm = () => {
    onComplete(value)
    setValue({})
    setCurIndex(null)
    setNextIndex(null)
    setSelection({})
    setIsVerify(false)
    setIsComplete(false)
  }

  const renderValue = (fieldName) => {
    if (
      (value[fieldName] === '' || value[fieldName] === undefined) &&
      ['ind_oop_max', 'fam_oop_max', 'ind_deductible_remaining', 'fam_deductible_remaining',
      'sec_ind_oop_max', 'sec_fam_oop_max', 'sec_ind_deductible_remaining', 'sec_fam_deductible_remaining'].includes(fieldName)
    ) {
      return 'N/A';
    }
    return value[fieldName];
  }

  const renderVerifyFields = () => {
    const fields = Object.keys(labelMap);
    const halfIndex = Math.floor(fields.length / 2);
    const firstHalf = fields.slice(0, halfIndex);
    const secondHalf = fields.slice(halfIndex);
    return (
      <div>
        <p>
          Please verify the inputs entered before submitting the eligibility
          results
        </p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <table className="detail-area-table">
            {firstHalf.map((field, key) => {
              const fieldName = `${prefix}${field}`
              return (
                <tr>
                  <th>{labelMap[field]}</th>
                  <td>
                    {isEdit && !notEditableFields.includes(field) ? (
                      <input
                        type="text"
                        value={value[fieldName]}
                        onChange={(e) =>
                          setValue((prev) => ({ ...prev, [fieldName]: e.target.value }))
                        }
                      />
                    ) : (
                      renderValue(fieldName)
                    )}
                  </td>
                </tr>
              )
            })}
          </table>
          <table className="detail-area-table">
            {secondHalf.map((field, key) => {
              const fieldName = `${prefix}${field}`
              return (
                <tr>
                  <th>{labelMap[field]}</th>
                  <td>
                    {isEdit && !notEditableFields.includes(field) ? (
                      <input
                        type="text"
                        value={value[fieldName]}
                        onChange={(e) =>
                          setValue((prev) => ({ ...prev, [fieldName]: e.target.value }))
                        }
                      />
                    ) : (
                      renderValue(fieldName)
                    )}
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
        {isEdit && (
          <>
            <p>Please include in your Verification Notes reason for override</p>
            <textarea
              style={{
                border: '1px solid #ccc',
              }}
              placeholder="Verification Notes"
              onChange={(e) => {
                setValue((prev) => ({
                  ...prev,
                  verification_notes: e.target.value,
                  // verification_date: moment().format(),
                }))
              }}
              value={value.verification_notes}
            />
          </>
        )}
      </div>
    )
  }

  const isDisabled = useMemo(() => {
    if (typeof selection[curIndex] === 'undefined') return true
    if (question && question.action && question.action.copay && !value[`${prefix}ins_copay`])
      return true
    if (
      question &&
      question.action &&
      question.action.oop_max &&
      selection['oop_max_applies'] &&
      (!value[`${prefix}ind_oop_max`] || !value[`${prefix}fam_oop_max`])
    )
      return true
    if (
      question &&
      question.action &&
      question.action.remaining &&
      selection['deductible_applies'] &&
      (!value[`${prefix}ind_deductible_remaining`] || !value[`${prefix}fam_deductible_remaining`])
    )
      return true
    if (
      question &&
      question.action &&
      question.action.plan_type &&
      selection['patient_subscribed'] &&
      !value[`${prefix}plan_type`]
    )
      return true
    return false
  }, [selection, curIndex, question, value, prefix])

  const renderPatientInfo = () => (
    <table className="detail-area-table">
      <tr>
        <th>Patient Name:</th>
        <th>Date of Birth</th>
        <th>{prefix ? 'Secondary' : ''} Insurance</th>
        <th>{prefix ? 'Secondary' : ''} Insurance Number</th>
        <th>{prefix ? 'Secondary' : ''} Insurance Phone Number</th>
        <th>Procedure</th>
        <th>CPT Codes</th>
        <th>Group NPI</th>
        <th>Provider NPI</th>
      </tr>
      <tr>
        <td>
          {patient.first_name} {patient.last_name}
        </td>
        <td>{patient.dob_string}</td>
        <td>{patient[`${prefix ? 'secondary_' : ''}insurance`]}</td>
        <td>{patient[`${prefix ? 'secondary_' : ''}insurance_number`]}</td>
        <td>{patient[`${prefix ? 'secondary_' : ''}insurance_phone`]}</td>
        <td>{patient.procedure}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </table>
  )

  const onSelectInsurance = (type) => () =>{
    setInsurance(type)
  }

  const onReset = () => {
    setConfirmReset(true)
  }

  const onConfirmReverify = () => {
    const verificationValue = {
      payor_name: '',
      contact_number: '',
      rep_name: '',
      reference: '',
      plan_type: '',
      provider_network: '',
      group_network: '',
      network: '',
      mental_health: '',
      telehealth: '',
      submit_claim: '',
      verification_status: '',
      verification_date: '',
      oop_max: '',
      oop_max_applies: '',
      ind_oop_max: '',
      fam_oop_max: '',
      deductible: '',
      deductible_applies: '',
      deductible_remaining: '',
      ind_deductible_remaining: '',
      fam_deductible_remaining: '',
      ins_copay: '',
      copay: '',
      copay_applies: '',
      co_ins_applies: '',
      co_insurance: '',
      oon_benefits: '',
      advantum_team_member: '',
      allowable_amount: '',
      claim_to: '',
      amount_due: '',
    }
    let resetValue
    if (insurance === 'primary') {
      resetValue = {
        ...value,
        ...verificationValue,
      }
    } else if (insurance === 'secondary') {
      const secondaryResetVal = {}
      Object.keys(verificationValue).forEach(item => {
        secondaryResetVal[`sec_${item}`] = ''
      })
      resetValue = {
        ...value,
        ...secondaryResetVal,
      }
    }
    setValue(resetValue)
    onComplete(resetValue, false)
  }

  useEffect(() => {
    if (typeof selection[curIndex] !== 'undefined') {
      if (selection[curIndex] === true) {
        onYes()
      } else if (selection[curIndex] === false) {
        onNo()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curIndex, onNo, onYes])

  const planTypes = useMemo(() => {
    if (insurance === 'secondary') {
      return ['PPO', 'HMO', 'POS', 'EPO', 'Medicare', 'Medicaid', 'Medicare Supplement', 'Other']
    }
    return ['PPO', 'HMO', 'POS', 'EPO', 'Medicare', 'Medicaid', 'Medicare Advantage PPO', 'Medicare Advantage HMO', 'Other']
  }, [insurance])

  if (insurance === null) {
    return (
      <div className="detail-area popover">
        {renderPatientInfo()}
        <p>
          <button
            className={`btn center`}
            onClick={onSelectInsurance('primary')}
          >
            Primary Insurance
          </button>
          <button
            className={`btn default center`}
            onClick={onSelectInsurance('secondary')}
          >
            Secondary Insurance
          </button>
        </p>
      </div>
    )
  }

  if (insurance !== null && patient[`${prefix}verification_status`] === 'Verified') {
    return (
      <div className="detail-area popover">
        {renderPatientInfo()}
        {renderVerifyFields()}
        {confirmReset ? (
          <>
            <p>Re-verifying will clear all the previously submitted eligibility values. Are you sure you would like to proceed?</p>
            <p>
              <button
                className={`btn center`}
                onClick={onConfirmReverify}
              >
                Yes
              </button>
              <button
                className={`btn default center`}
                onClick={onClose}
              >
                No
              </button>
            </p>
          </>
        ) : (
          <p>
            <button
              className={`btn default center`}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={`btn center`}
              onClick={onReset}
            >
              Re-verify
            </button>
          </p>
        )}
      </div>
    )
  }

  if (curIndex === null) {
    return (
      <div className="detail-area popover">
        {renderPatientInfo()}
        <p>
          <input
            placeholder="Payor Name"
            value={value[`${prefix}payor_name`]}
            onChange={(e) =>
              setValue((prev) => ({
                ...prev,
                [`${prefix}payor_name`]: e.target.value,
              }))
            }
          />
        </p>
        <p>
          <input
            placeholder="Contact #"
            value={value[`${prefix}contact_number`]}
            onChange={(e) =>
              setValue((prev) => ({
                ...prev,
                [`${prefix}contact_number`]: e.target.value,
              }))
            }
          />
        </p>
        <p>
          <input
            placeholder="Rep Name"
            value={value[`${prefix}rep_name`]}
            onChange={(e) =>
              setValue((prev) => ({
                ...prev,
                [`${prefix}rep_name`]: e.target.value,
              }))
            }
          />
        </p>
        <p>
          <input
            placeholder="Reference #"
            value={value[`${prefix}reference`]}
            onChange={(e) =>
              setValue((prev) => ({
                ...prev,
                [`${prefix}reference`]: e.target.value,
              }))
            }
          />
        </p>
        <p>
          <button className={`btn default center`} onClick={() => setInsurance(null)}>
            Back
          </button>
          <button
            className="btn center"
            onClick={() => setCurIndex('patient_subscribed')}
          >
            Next
          </button>
        </p>
      </div>
    )
  }

  return (
    <div className="detail-area popover">
      {renderPatientInfo()}
      {!isComplete && <p>{question.question}</p>}
      {!isComplete && question.yes_no && (
        <p>
          <button
            className={`btn ${
              selection[curIndex] === true ? '' : 'default'
            } center`}
            onClick={onYes}
          >
            {question.options ? question.options[0] : 'Yes'}
          </button>
          <button
            className={`btn ${
              selection[curIndex] === false ? '' : 'default'
            } center`}
            onClick={onNo}
          >
            {question.options ? question.options[1] : 'No'}
          </button>
        </p>
      )}
      {selection[curIndex] && question.action && question.action.plan_type && (
        <>
          <p>
            <label>Plan Type:</label>
            <select
              value={value[`${prefix}plan_type`]}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  [`${prefix}plan_type`]: e.target.value,
                }))
              }
            >
              <option value="">Select</option>
              {planTypes.map(
                (item) => (
                  <option value={item}>{item}</option>
                )
              )}
            </select>
          </p>
        </>
      )}
      <p>
        {!isComplete &&
          selection[curIndex] &&
          question.action &&
          question.action.remaining && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <input
                  placeholder="Individual Deductible Remaining"
                  type="number"
                  value={value[`${prefix}ind_deductible_remaining`]}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      [`${prefix}ind_deductible_remaining`]: e.target.value,
                    }))
                  }
                />
                <div style={{marginLeft: 4, display: 'flex', alignItems: 'center'}}>
                  <input
                    id="ind_deductible_remaining"
                    type="checkbox"
                    checked={value[`${prefix}ind_deductible_remaining`] === 'N/A'}
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        [`${prefix}ind_deductible_remaining`]: e.target.checked ? 'N/A' : '',
                      }))
                    }
                  />
                  <label for="ind_deductible_remaining">N/A</label>
                </div>
              </div>
              <br />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <input
                  placeholder="Family Deductible Remaining"
                  type="number"
                  value={value[`${prefix}fam_deductible_remaining`]}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      [`${prefix}fam_deductible_remaining`]: e.target.value,
                    }))
                  }
                />
                <div style={{marginLeft: 4, display: 'flex', alignItems: 'center'}}>
                  <input
                    id="fam_deductible_remaining"
                    type="checkbox"
                    checked={value[`${prefix}fam_deductible_remaining`] === 'N/A'}
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        [`${prefix}fam_deductible_remaining`]: e.target.checked ? 'N/A' : '',
                      }))
                    }
                  />
                  <label for="fam_deductible_remaining">N/A</label>
                </div>
              </div>
            </>
          )}
        {!isComplete &&
          selection[curIndex] &&
          question.action &&
          question.action.oop_max && (
            <>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <input
                  placeholder="Individual OOP Max Remaining"
                  type="number"
                  value={value[`${prefix}ind_oop_max`]}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      [`${prefix}ind_oop_max`]: e.target.value,
                    }))
                  }
                />
                <div style={{marginLeft: 4, display: 'flex', alignItems: 'center'}}>
                  <input
                    id="ind_oop_max"
                    type="checkbox"
                    checked={value[`${prefix}ind_oop_max`] === 'N/A'}
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        [`${prefix}ind_oop_max`]: e.target.checked ? 'N/A' : '',
                      }))
                    }
                  />
                  <label for="ind_oop_max">N/A</label>
                </div>
              </div>
              <br />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <input
                  placeholder="Family OOP Max Remaining"
                  type="number"
                  value={value[`${prefix}fam_oop_max`]}
                  onChange={(e) =>
                    setValue((prev) => ({
                      ...prev,
                      [`${prefix}fam_oop_max`]: e.target.value,
                    }))
                  }
                />
                <div style={{marginLeft: 4, display: 'flex', alignItems: 'center'}}>
                  <input
                    id="fam_oop_max"
                    type="checkbox"
                    checked={value[`${prefix}fam_oop_max`] === 'N/A'}
                    onChange={(e) =>
                      setValue((prev) => ({
                        ...prev,
                        [`${prefix}fam_oop_max`]: e.target.checked ? 'N/A' : '',
                      }))
                    }
                  />
                  <label for="fam_oop_max">N/A</label>
                </div>
              </div>
            </>
          )}
        {!isComplete &&
          selection[curIndex] &&
          question.action &&
          question.action.copay && (
            <input
              placeholder="Copay"
              type="number"
              value={value[`${prefix}ins_copay`]}
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  [`${prefix}ins_copay`]: e.target.value,
                }))
              }
            />
          )}
        {!isComplete &&
          selection[curIndex] &&
          question.action &&
          question.action.coinsurance && (
            <>
              <label>Enter Co-Insurance(%)</label>
              <br />
              <input
                placeholder="Co-Insurance(%)"
                value={value[`${prefix}co_insurance`]}
                onChange={(e) =>
                  setValue((prev) => ({
                    ...prev,
                    [`${prefix}co_insurance`]: e.target.value,
                  }))
                }
              />
            </>
          )}
        {isComplete && !isVerify && (
          <input
            placeholder="Advantum Team Member"
            type="text"
            value={value[`${prefix}advantum_team_member`]}
            onChange={(e) =>
              setValue((prev) => ({
                ...prev,
                [`${prefix}advantum_team_member`]: e.target.value,
              }))
            }
          />
        )}
        {isComplete && !isVerify && (
          <>
            <br />
            <textarea
              style={{
                border: '1px solid #ccc',
              }}
              placeholder="Verification Notes"
              onChange={(e) =>
                setValue((prev) => ({
                  ...prev,
                  verification_notes: e.target.value,
                  verification_date: moment().format(),
                }))
              }
              value={value.verification_notes}
            />
          </>
        )}
        {isComplete && isVerify && renderVerifyFields()}
      </p>
      {isVerify && (
        <p>
          {isEdit ? (
            <button
              className={`btn default center`}
              onClick={() => {
                setIsEdit(false)
              }}
            >
              Cancel
            </button>
          ) : (
            <button
              className={`btn default center`}
              onClick={() => {
                setIsEdit(true)
              }}
            >
              Edit
            </button>
          )}
          <button className={`btn center`} onClick={onConfirm}>
            Confirm
          </button>
        </p>
      )}
      {isComplete && !isVerify && (
        <p>
          <button className={`btn default center`} onClick={onBack}>
            Back
          </button>
          <button
            className={`btn center`}
            onClick={() => {
              setIsVerify(true)
            }}
          >
            Next
          </button>
        </p>
      )}
      {!isComplete && !isVerify && (
        <p>
          <button className={`btn default center`} onClick={onBack}>
            Back
          </button>
          <button
            disabled={isDisabled}
            className={`btn center ${isDisabled ? 'disabled' : ''}`}
            onClick={onNext}
          >
            Next
          </button>
        </p>
      )}
    </div>
  )
}

export default EligibilityRunModalContent
