import React from 'react';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import parseTherapist from './parseTherapist';
import Login from './Login';
import Loading from './Loading';

class TherapistList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	filter: null,
      sort: 'scheduling_rank',
      asc: true,
      loading: true,
    	therapists: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.filterResults = this.filterResults.bind(this);
    this.colsSort = this.colsSort.bind(this);
    this.sort = this.sort.bind(this);
    this.syncTherapists = this.syncTherapists.bind(this);
  }
  componentDidMount() {
  	if(!this.state.therapists) this.loginToken();
  }
  filterResults(value) {
  	this.setState({filter: value});
  }
  syncTherapists() {
    let token = localStorage.getItem('token'),
        that = this;

    fetch(window.server_url + '/admin/sync_therapists', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
          if (!response.ok) {
             // localStorage.removeItem('token');
              window.location.href = '/admin';
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.success) {
          //localStorage.removeItem('token');
          window.location.href = '/admin';
          return false;
        } else {
          window.location.reload();
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      that.setState({loading: true})
  }
	loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/get_therapists', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
          if (!response.ok) {
             //	localStorage.removeItem('token');
		        	window.location.href = '/admin';
              throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data) {
        	//localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
          let _therapists = res.data.map(therapist => {
            return parseTherapist(therapist);
          })

        	that.setState({
        		therapists: _therapists,
            loading: false
        	})
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  colsSort(a,b) {
    let ret;
    let _a, _b;

    if(['createdAt', 'dob_string', 'verification_date', 'appointment_date', 'report_date'].indexOf(this.state.sort) > -1)
      ret = moment(b[this.state.sort]).format('X') - moment(a[this.state.sort]).format('X');
    else {
      _a = a[this.state.sort];
      _b = '' + b[this.state.sort];

      ret = _b.localeCompare(_a);
      console.log(a, b);
    }

    if(this.state.asc === true) ret = -ret;

    return ret;
  }
  sort(col) {
    if(this.state.sort === col) {
      this.setState({
        asc: !this.state.asc
      })
    } else {
      this.setState({
        sort: col,
        asc: false
      })
    }
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.therapists) return '';
    else {
      let columns = {
        first_name: {
          name: 'First Name',
          type: 'string'
        
        },
        last_name: {
          name: 'Last Name',
          type: 'string'
        
        },
        states: {
          name: 'States',
          type: 'string'
        },
        credentials: {
          name: 'Credentials',
          type: 'string'
        },
        patient_ptd: {
          name: 'Patient PTD',
          type: 'string'
        },
        patient_mtd: {
          name: 'Patient MTD',
          type: 'string'
        },
        patient_ytd: {
          name: 'Patient YTD',
          type: 'string'
        },
        current_invoice_dates: {
          name: 'Current Invoice',
          type: 'string'
        },
        current_invoice_amount: {
          name: 'Current Amount',
          type: 'string'
        },
        current_invoice_status: {
          name: 'Current Status',
          type: 'string'
        },
        previous_invoice_amount: {
          name: 'Previous Amount',
          type: 'string'
        },
        previous_invoice_status: {
          name: 'Previous Status',
          type: 'string'
        }
        
      };
    	let therapists = [];
    	let that = this;
    	this.state.therapists.sort(this.colsSort).forEach(therapist => {


        if(that.state.filter && that.state.filter.trim() !== '') {
          let shards = that.state.filter.toLowerCase().split(' ');
          let ok = true;
          shards.forEach(function(shard) {
            let shard_match = false;
            Object.keys(columns).forEach(function(column_name) {
              if(therapist[column_name] && typeof therapist[column_name] === 'string' && therapist[column_name].toLowerCase().indexOf(shard) > -1) shard_match = true;
            })
            if(!shard_match) ok = false;
          });
          if(!ok) return;
        }
        let cols = Object.keys(columns).map((name) => {
          let className = '';
          let column = columns[name];

          if(column.color_codes) {
            column.color_codes.forEach(code => {
              if(therapist[name] && therapist[name].toLowerCase().indexOf(code) > -1) {
                className += ' ' + code;
              }
            });
          }
          // if(column.editable) {
          //   let statuses = therapist[column.select_property].map(status => {
          //     return <option value={status} selected={status === therapist[name]}>{status}</option>
          //   });

          //   return (
          //     <td className={className}>
          //       <select onClick={e => {e.stopPropagation()}} onChange={e => {
          //         let data = {};
          //         data[name] = e.target.value;
          //         updateTherapist(therapist._id, data);
          //       }}>
          //         {statuses}
          //       </select>
          //     </td>
          //   );
          // }
          return <td className={className}>{therapist[name]}</td>;
        });
        therapists.push(
        <tr className="item" onClick={e => {window.location.href = '/admin/therapist/' + therapist.id}}>
            {cols}

            {
              /* <td>
              <select onClick={e => {e.stopPropagation()}} onChange={e => {changeInvoicingStatus(patient._id, e.target.value)}}>
                {statuses}
              </select>
            </td> */ 
          }
          </tr>);
    		
    	  			// forms.push(<CSVLink className="download-link" data={data}>Download patient intake form</CSVLink>);
    		
    	});
      let col_titles = Object.keys(columns).map((name) => {
        let className = '';
        let column = columns[name];
        console.log(column)

        return <th className={that.state.sort === name ? 'sort' : ''} onClick={e => {that.sort(name)}}>{column.name} {that.state.sort === name ? that.state.asc === true ? '^' : 'v' : ''}</th>
      });

    	return (
	    	<div className="page admin form">
          <h1>Therapists</h1>
		    	<a class={`btn sync-therapists ${this.state.loading ? ' disabled' : ''}`} onClick={this.syncTherapists}>{this.state.loading ? 'Syncing...' : 'Sync Therapists with Fullslate'}</a>
		    	<input name="search" placeholder="Filter therapists" onChange={e => {this.filterResults(e.target.value)}} />
		    	<br/>
          <div className="results-area">
          <table id="results">
            <tbody>
              <tr className="header">
                {col_titles}
              </tr>
              {therapists}
            </tbody>
          </table>
          </div>
		    </div>
	    );
    }
  }
}

export default TherapistList;