import "@babel/polyfill";
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';
import 'whatwg-fetch'

import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { site } from './config/config';
import App from './App';
import Admin from './admin/Admin';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

require('formdata-polyfill')

const AppRouter = () => (
  <Router>
  	<div>
	  <Route path="/" exact component={App} />
	  <Route path="/admin" component={Admin} />
  	</div>
  </Router>
);

window.preventLeave = function (e) {
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = '';
};

window.base_title = site.title;
window.server_url = process.env.REACT_APP_SERVER_URL || '/api';

ReactDOM.render(<AppRouter />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
