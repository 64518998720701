import moment from 'moment-timezone';
import { site } from '../config/config'
function roundToTwo(num) {    
    return +(Math.round(num + "e+2")  + "e-2");
}
String.prototype.convertToISODate = function() {
    var date = this.valueOf().split('/');

    return date[2]+'-'+date[0]+'-'+date[1];
}
function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}
const getField = (form, field) => {
  let match;

  if(!form) return 'None';
  
  match = form.find(e => (e && e.name.trim().replace(/\u2013|\u2014/g, "-") === field.trim().replace(/\u2013|\u2014/g, "-")));
  if(match) return match.value;
}
const getFieldByValue = (form, value) => {
  let match;

  if(!form) return 'None';
   // console.log('matching by value');
  match = form.find(e => {
   // console.log(e);
    return e && e.value && e.value[0] && e.value[0].trim().replace(/\u2013|\u2014/g, "-") === value.trim().replace(/\u2013|\u2014/g, "-")
    });
  if(match) return match.value[0];
}
const getFields = (form, field, number) => {
  let match;

  if(!form) return 'None';
  
  match = form.findIndex(e => (e && e.name.trim().replace(/\u2013|\u2014/g, "-") === field.trim().replace(/\u2013|\u2014/g, "-")));
  if(match) return form.slice(match, match+number);
}
const getSum = (form, total, field) => {
    let value = getField(form, field);

    if(value && value.indexOf('::') > -1) value = value.split('::')[0];
    else return total;

    //console.log('value/total/field', value, total, field);

    return parseInt(total) + parseInt(value);
}
const getScore = (score, metric) => {
    let ret = 'Error';
    metric.forEach((value) => {
        if(score >= value.min) ret = value.score;
    })
    return ret;
}
const getRawScores = (patient) => {

    let phq_15_questions = [
        'Stomach pain',
        'Back pain',
        'Pain in your arms, legs, or joints (knees, hips, etc.)',
        'Feeling tired or having little energy',
        'Trouble falling or staying asleep, or sleeping too much',
        'Menstrual cramps or other problems with your periods',
        'Pain or problems during sexual intercourse',
        'Headaches',
        'Chest pain',
        'Dizziness',
        'Fainting spells',
        'Feeling your heart pound or race',
        'Shortness of breath',
        'Constipation, loose bowels, or diarrhea',
        'Nausea, gas, or indigestion',

    ];
    let gad_7_questions = [
        'Feeling nervous anxiety or on edge',
        'Not being able to stop or control worrying',
        'Worrying too much about different things',
        'Trouble relaxing',
        'Being so restless that it is hard to sit still',
        'Becoming easily annoyed or irritable',
        'Feeling afraid as if something awful might happen',
    ];
    let anxiety_attack_question = 'In the last 4 weeks, have you had an anxiety attack; suddenly feeling fear or panic?';

    let phq_9_questions = [
        'Little interest or pleasure in doing things',
        'Feeling down, depressed, or hopeless',
        'Trouble falling or staying asleep, or sleeping too much',
        'Feeling tired or having little energy',
        'Poor appetite or overeating',
        'Feeling bad about yourself — or that you are a failure or have let yourself or your family down',
        'Trouble concentrating on things, such as reading the newspaper or watching television',
        'Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual',
        'Thoughts that you would be better off dead of or hurting yourself in some way',
    ];

    let dass_21_s_questions = [
        'I found it hard to wind down',
        'I tended to over-react to situations',
        'I felt that I was using a lot of nervous energy',
        'I found myself getting agitated',
        'I found it difficult to relax',
        'I was intolerant of anything that kept me from getting on with what I was doing',
        'I felt that I was rather touchy',
    ];

    let dass_21_a_questions = [
        'I was aware of dryness of my mouth',
        'I experienced breathing difficulty (e.g. excessively rapid breathing, breathlessness in the absence of physical exertion)',
        'I experienced trembling (e.g. in the hands)',
        'I was worried about situations in which I might panic and make a fool of myself',
        'I felt I was close to panic',
        'I was aware of the action of my heart in the absence of physical exertion (e.g. sense of heart rate increase, heart missing a beat)',
        'I felt scared without any good reason',
    ];

    let whympi_questions = [
        "Rate the level of your pain at the present moment",
        "In general, how much does your pain problem interfere with your day to day activities?",
        "Since the time you developed a pain problem, how much has your pain changed your ability to work?",
        "Check here, if you have retired for reasons other than your pain problem",
        "How much has your pain changed the amount of satisfaction or enjoyment you get from participating in social and recreational activities?",
        "How supportive or helpful is your spouse (significant other) to you in relation to your pain?",
        "Rate your overall mood during the past week.",
        "On the average, how severe has your pain been during the last week?",
        "How much has your pain changed your ability to participate in recreational and other social activities?",
        "How worried is your spouse (significant other) about you in relation to your pain problem?",
        "During the past week, how much control do you feel that you have had over your life?",
        "How much suffering do you experience because of your pain?",
        "How much has your pain changed your marriage and other family relationships?",
        "How much has your pain changed the amount of satisfaction or enjoyment you get from work?",
        "Check here, if you are not presently working",
        "How attentive is your spouse (significant other) to your pain problem?",
        "During the past week, how much do you feel that you've been able to deal with your problems?",
        "How much has your pain changed your ability to do household chores?",
        "During the past week, how irritable have you been?",
        "How much has your pain changed your friendships with people other than your family?",
        "During the past week, how tense or anxious have you been?",
        "Ignores me.",
        "Asks me what he/she can do to help.",
        "Reads to me.",
        "Expresses irritation at me.",
        "Takes over my jobs or duties.",
        "Talks to me about something else to take my mind off the pain.",
        "Expresses frustration at me.",
        "Tries to get me to rest.",
        "Tries to involve me in some activity",
        "Expresses anger at me.",
        "Gets me some pain medications.",
        "Encourages me to work on a hobby.",
        "Gets me something to eat or drink.",
        "Turns on the T.V. to take my mind off my pain",
        "Wash dishes. ",
        "Mow the lawn. ",
        "Go out to eat. ",
        "Play cards or other games. ",
        "Go grocery shopping. ",
        "Work in the garden. ",
        "Go to a movie.",
        "Visit friends. ",
        "Help with the house cleaning.",
        "Work on the car. ",
        "Take a ride in a car.",
        "Visit relatives.",
        "Prepare a meal. ",
        "Wash the car. ",
        "Take a trip.",
        "Go to a park or beach. ",
        "Do a load of laundry. ",
        "Work on a needed house repair."
    ];

    let whympi_interference_questions = [2,3,4,8,9,13,14,17,19];
    let whympi_support_questions = [5,10,15];
    let whympi_pain_severity_questions = [1,7,12];
    let whympi_life_control_questions = [11,16];

    let whympi_affective_distress_question_inverse = [6];
    let whympi_affective_distress_questions = [18,20];

    let whympi_negative_responses_questions = [1,4,7,10];
    let whympi_solicitous_responses_questions = [2,5,8,11,13,14];
    let whympi_distracting_responses_questions = [3,6,9,12];

    let whympi_household_chores_questions = [1,5,9,13,17];
    let whympi_outdoor_work_questions = [2,6,10,14,18];
    let whympi_activities_away_from_home_questions = [3,7,11,15];
    let whympi_social_activities_questions = [4,8,12,16];

    let whympi_general_activity_questions = whympi_household_chores_questions.concat(whympi_outdoor_work_questions).concat(whympi_activities_away_from_home_questions).concat(whympi_social_activities_questions);

    let pain_catastrophizing_questions = [
        "I worry all the time about whether the pain will end.",
        "I feel I can't go on.",
        "It's terrible and I think it's never going to get any better",
        "It's awful and I feel that it overwhelms me.",
        "I feel I can't stand it anymore",
        "I become afraid that the pain will get worse.",
        "I keep thinking of other painful events",
        "I anxiously want the pain to go away",
        "I can't seem to keep it our of my mind",
        "I keep thinking about how much it hurts.",
        "I keep thinking about how badly I want the pain to stop",
        "There's nothing I can do to reduce the intensity of the pain",
        "I wonder whether something serious may happen. ",
    ];

    let dass_21_d_questions = [
        "I couldn't seem to experience any positive feeling at all",
        "I found it difficult to work up the initiative to do things",
        "I felt that I had nothing to look forward to",
        "I felt down-hearted and blue",
        "I was unable to become enthusiastic about anything",
        "I felt I wasn't worth much as a person",
        "I felt that life was meaningless",
    ];

    let eat_26_questions = [
        'Am terrified about being overweight.',
        'Avoid eating when I am hungry.',
        'Find myself preoccupied with food.',
        'Have gone on eating binges where I feel that I may not be able to stop.',
        'Cut my food into small pieces.',
        'Aware of the calorie content of foods that I eat.',
        'Particularly avoid food with a high carbohydrate content (i.e. bread, rice, potatoes, etc.)',
        'Feel that others would prefer if I ate more.',
        'Vomit after I have eaten.',
        'Feel extremely guilty after eating.',
        'Am preoccupied with a desire to be thinner.',
        'Think about burning up calories when I exercise.',
        'Other people think that I am too thin.',
        'Am preoccupied with the thought of having fat on my body.',
        'Take longer than others to eat my meals.',
        'Avoid foods with sugar in them.',
        'Eat diet foods.',
        'Feel that food controls my life.',
        'Display self-control around food.',
        'Feel that others pressure me to eat.',
        'Give too much time and thought to food.',
        'Feel uncomfortable after eating sweets.',
        'Engage in dieting behavior.',
        'Like my stomach to be empty.',
        'Have the impulse to vomit after meals.',
        'Enjoy trying new rich foods.',
    ];

    patient.phq_15_scores = [
        {
            min: 0,
            score: "Minimal Symptom Severity"
        },
        {
            min: 5,
            score: "Mild Symptom Severity"
        },
        {
            min: 9,
            score: "Medium Symptom Severity"
        },
        {
            min: 13,
            score: "High Symptom Severity"
        },
    ];

    patient.gad_7_scores = [
        {
            min: 0,
            score: "Mild"
        },
        {
            min: 6,
            score: "Moderate"
        },
        {
            min: 11,
            score: "Moderately Severe Anxiety"
        },
        {
            min: 16,
            score: "Severe Anxiety"
        },
    ];

    patient.phq_9_scores = [
        {
            min: 0,
            score: "Minimal or none"
        },
        {
            min: 5,
            score: "Mild"
        },
        {
            min: 10,
            score: "Moderate"
        },
        {
            min: 15,
            score: "Moderately Severe"
        },
        {
            min: 20,
            score: "Severe"
        },
    ];

    patient.dass_21_d_scores = [
        {
            min: 0,
            score: "Normal"
        },
        {
            min: 10,
            score: "Mild"
        },
        {
            min: 14,
            score: "Moderate"
        },
        {
            min: 21,
            score: "Severe"
        },
        {
            min: 28,
            score: "Extremely Severe"
        },
    ];

    patient.dass_21_a_scores = [
        {
            min: 0,
            score: "Normal"
        },
        {
            min: 8,
            score: "Mild"
        },
        {
            min: 10,
            score: "Moderate"
        },
        {
            min: 15,
            score: "Severe"
        },
        {
            min: 20,
            score: "Extremely Severe"
        },
    ];

    patient.dass_21_s_scores = [
        {
            min: 0,
            score: "Normal"
        },
        {
            min: 15,
            score: "Mild"
        },
        {
            min: 19,
            score: "Moderate"
        },
        {
            min: 26,
            score: "Severe"
        },
        {
            min: 34,
            score: "Extremely Severe"
        },
    ];
    patient.eat_26_scores = [
        {
            min: 0,
            score: "Low Score"
        },
        {
            min: 20,
            score: "High Score"
        }
    ]

    let results = {};

    if(patient.form['regform-bariatric-2']) {

        results.phq_15_raw_score = phq_15_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-bariatric-2'], total, value);
        }, 0);
        results.phq_15_score = getScore(results.phq_15_raw_score, patient.phq_15_scores);

        results.gad_7_raw_score = gad_7_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-bariatric-2'], total, value);
        }, 0);
        results.gad_7_score = getScore(results.gad_7_raw_score, patient.gad_7_scores);

        results.phq_9_raw_score = phq_9_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-bariatric-2'], total, value);
        }, 0);
        results.phq_9_score = getScore(results.phq_9_raw_score, patient.phq_9_scores);
        if(!patient.phq_9_score) patient.phq_9_score = results.phq_9_score
        results.anxiety_attacks = getField(patient.form['regform-bariatric-2'], anxiety_attack_question);
    }
    if(patient.form['regform-scs-4']) {

        results.phq_9_raw_score = phq_9_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-scs-4'], total, value);
        }, 0);
        results.phq_9_score = getScore(results.phq_9_raw_score, patient.phq_9_scores);
        if(!patient.phq_9_score) patient.phq_9_score = results.phq_9_score
        results.anxiety_attacks = getField(patient.form['regform-scs-4'], anxiety_attack_question);
    }
    if(patient.form['regform-scs-7']) {

        results.pain_catastrophizing_score = pain_catastrophizing_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-scs-7'], total, value);
        }, 0);
    }
    if(patient.form['regform-scs-5']) {

        results.whympi_interference_score = whympi_interference_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_interference_questions.length;

        results.whympi_interference_score = roundToTwo(results.whympi_interference_score);

        results.whympi_support_score = whympi_support_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_support_questions.length;

        results.whympi_support_score = roundToTwo(results.whympi_support_score);


        results.whympi_pain_severity_score = whympi_pain_severity_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_pain_severity_questions.length;

        results.whympi_pain_severity_score = roundToTwo(results.whympi_pain_severity_score);

        results.whympi_life_control_score = whympi_life_control_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_life_control_questions.length;
        
        results.whympi_life_control_score = roundToTwo(results.whympi_life_control_score);

        results.whympi_affective_distress_score = whympi_affective_distress_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0);
        results.whympi_affective_distress_score += (6 - whympi_affective_distress_question_inverse.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0));
        results.whympi_affective_distress_score /= (whympi_affective_distress_questions.length + whympi_affective_distress_question_inverse.length);

        results.whympi_affective_distress_score = roundToTwo(results.whympi_affective_distress_score);


        results.whympi_negative_responses_score = whympi_negative_responses_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_negative_responses_questions.length;

        results.whympi_negative_responses_score = roundToTwo(results.whympi_negative_responses_score);

        results.whympi_solicitous_responses_score = whympi_solicitous_responses_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_solicitous_responses_questions.length;

        results.whympi_solicitous_responses_score = roundToTwo(results.whympi_solicitous_responses_score);

        results.whympi_distracting_responses_score = whympi_distracting_responses_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_distracting_responses_questions.length;

        results.whympi_distracting_responses_score = roundToTwo(results.whympi_distracting_responses_score);

        results.whympi_household_chores_score = whympi_household_chores_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_household_chores_questions.length;

        results.whympi_household_chores_score = roundToTwo(results.whympi_household_chores_score);

        results.whympi_outdoor_work_score = whympi_outdoor_work_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_outdoor_work_questions.length;

        results.whympi_outdoor_work_score = roundToTwo(results.whympi_outdoor_work_score);

        results.whympi_activities_away_from_home_score = whympi_activities_away_from_home_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_activities_away_from_home_questions.length;

        results.whympi_activities_away_from_home_score = roundToTwo(results.whympi_activities_away_from_home_score);

        results.whympi_social_activities_score = whympi_social_activities_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_social_activities_questions.length;

        results.whympi_social_activities_score = roundToTwo(results.whympi_social_activities_score);

        results.whympi_general_activity_score = whympi_general_activity_questions.reduce((total, value, index) => {
            let v = whympi_questions[value + 1];
            return getSum(patient.form['regform-scs-5'], total, v);
        }, 0) / whympi_general_activity_questions.length;

        results.whympi_general_activity_score = roundToTwo(results.whympi_general_activity_score);


    }
    if(patient.form['regform-scs-6']) {

        results.dass_21_d_raw_score = dass_21_d_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-scs-6'], total, value);
        }, 0);
        results.dass_21_d_adjusted_score = results.dass_21_d_raw_score * 2;
        results.dass_21_d_score = getScore(results.dass_21_d_adjusted_score, patient.dass_21_d_scores);
        if(!patient.dass_21_d_score) patient.dass_21_d_score = results.dass_21_d_score

        results.dass_21_a_raw_score = dass_21_a_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-scs-6'], total, value);
        }, 0);
        results.dass_21_a_adjusted_score = results.dass_21_a_raw_score * 2;
        results.dass_21_a_score = getScore(results.dass_21_a_adjusted_score, patient.dass_21_a_scores);
        if(!patient.dass_21_a_score) patient.dass_21_a_score = results.dass_21_a_score

        results.dass_21_s_raw_score = dass_21_s_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-scs-6'], total, value);
        }, 0);
        results.dass_21_s_adjusted_score = results.dass_21_s_raw_score * 2;
        results.dass_21_s_score = getScore(results.dass_21_s_adjusted_score, patient.dass_21_s_scores);
        if(!patient.dass_21_s_score) patient.dass_21_s_score = results.dass_21_s_score
    }

    if(patient.form['regform-bariatric-3']) {

        results.dass_21_d_raw_score = dass_21_d_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-bariatric-3'], total, value);
        }, 0);
        results.dass_21_d_adjusted_score = results.dass_21_d_raw_score * 2;
        results.dass_21_d_score = getScore(results.dass_21_d_adjusted_score, patient.dass_21_d_scores);

        results.dass_21_a_raw_score = dass_21_a_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-bariatric-3'], total, value);
        }, 0);
        results.dass_21_a_adjusted_score = results.dass_21_a_raw_score * 2;
        results.dass_21_a_score = getScore(results.dass_21_a_adjusted_score, patient.dass_21_a_scores);

        results.dass_21_s_raw_score = dass_21_s_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-bariatric-3'], total, value);
        }, 0);
        results.dass_21_s_adjusted_score = results.dass_21_s_raw_score * 2;
        results.dass_21_s_score = getScore(results.dass_21_s_adjusted_score, patient.dass_21_s_scores);

        if(!patient.dass_21_d_score) patient.dass_21_d_score = results.dass_21_d_score
        if(!patient.dass_21_a_score) patient.dass_21_a_score = results.dass_21_a_score
        if(!patient.dass_21_s_score) patient.dass_21_s_score = results.dass_21_s_score
    }

    if(patient.form['regform-bariatric-4']) {
        results.eat_26_raw_score = eat_26_questions.reduce((total, value, index) => {
            return getSum(patient.form['regform-bariatric-4'], total, value);
        }, 0);
        results.eat_26_score = getScore(results.eat_26_raw_score, patient.eat_26_scores);
    }
   // console.log('results:',results);


    return results;

}
const parsePatient = (_patient) => {
    let patient = Object.assign({}, _patient);
    if(patient.dob) {
        if(typeof patient.dob.indexOf === 'function' && patient.dob.indexOf('T00:00:00.000Z')) patient.dob = patient.dob.replace('T00:00:00.000Z','');
        patient.dob = moment.tz(patient.dob, site.timezone)
        .hour(10)
        .format('MM/DD/YYYY');
    }
    if(patient.insured_dob) {
        console.log('insured_dob', patient.insured_dob)
        const insured_dob_moment = moment.tz(patient.insured_dob, site.timezone);

        patient.insured_dob = insured_dob_moment.isValid() ? insured_dob_moment.format('MM/DD/YYYY') : '';
        console.log(patient.insured_dob)
    }
    if(patient.form && patient.form['regform-first'] && typeof patient.form['regform-first'] === 'object' && typeof patient.form['regform-first'].find === 'function') {
        console.log('procedure debug: ', patient.first_name, patient.last_name);
        if(!patient.first_name) patient.first_name = getField(patient.form['regform-first'], 'First Name');
        if(!patient.last_name) patient.last_name = getField(patient.form['regform-first'], 'Last Name');
        if(!patient.phone) patient.phone = getField(patient.form['regform-first'], 'Phone Number');
        if(!patient.address) patient.address = getField(patient.form['regform-first'], 'Street Address');
        if(!patient.state) patient.state = getField(patient.form['regform-first'], 'State');
        if(!patient.city) patient.city = getField(patient.form['regform-first'], 'City');
        if(!patient.zip) patient.zip = getField(patient.form['regform-first'], 'Zip Code');
        if(patient.form['regform-bariatric-1'])
            patient.age = getField(patient.form['regform-bariatric-1'], 'Age');
        else if(patient.form['regform-scs-1'])
            patient.age = getField(patient.form['regform-scs-1'], 'Age');

        if(!patient.occupation) patient.occupation = getField(patient.form['regform-bariatric-1'], 'Occupation');
        if(!patient.marital_status) patient.marital_status = getField(patient.form['regform-bariatric-1'], 'Marital Status');
        if(!patient.marital_status || patient.marital_status === 'None') patient.marital_status = getField(patient.form['regform-scs-1'], 'Marital Status');
        if(!patient.marital_status || patient.marital_status === 'None') patient.marital_status = getField(patient.form['regform-first'], 'Marital Status');
        if(!patient.email) patient.email = getField(patient.form['regform-first'], 'Email Address');

        if(patient.form['regform-bariatric-1']) {
            patient.height_feet = parseInt(getField(patient.form['regform-bariatric-1'], 'Feet'));
            patient.height_inches = parseInt(getField(patient.form['regform-bariatric-1'], 'Inches'));
        } else if(patient.form['regform-scs-1']) {
            patient.height_feet = parseInt(getField(patient.form['regform-scs-1'], 'Feet'));
            patient.height_inches = parseInt(getField(patient.form['regform-scs-1'], 'Inches'));
        }
        patient.tobacco_use = getField(patient.form['regform-bariatric-1'], 'Do you smoke tobacco?');
        if(!patient.tobacco_use_form && patient.tobacco_use) {
            if(patient.tobacco_use === 'Yes') patient.tobacco_use = 'reports';
            else patient.tobacco_use = 'denies';
        }

        let suicide_question = getField(patient.form['regform-bariatric-4'], 'Have you recently thought of or attempted suicide?');
        if(!patient.suicidal_ideation && suicide_question) patient.suicidal_ideation = suicide_question === 'Yes' ? 'reports' : 'denies';
        patient.informed_consent = getFieldByValue(patient.form['regform-bariatric-5'], 'I CONSENT TO USING TELEHEALTH SERVICES');
        patient.out_of_state_insurance = getFieldByValue(patient.form['regform-insurance'], 'Click here if you have out-of-state insurance.');
        patient.confirm_proceed = getField(patient.form['regform-insurance'], 'Unfortunately, we do not accept the Medicaid plan in your state. The cash price for your evaluation will be $200. We understand if you would like to pursue an in-network solution instead, rather than complete the full registration. If you move forward, please note that you will be invoiced the cash price. Please confirm if you would like to proceed.');
        if(!patient.confirm_proceed) patient.confirm_proceed = getField(patient.form['regform-insurance'], 'Unfortunately, we do not accept the Medicaid plan in your state. The cash price for your evaluation will be $100. We understand if you would like to pursue an in-network solution instead, rather than complete the full registration. If you move forward, please note that you will be invoiced the cash price. Please confirm if you would like to proceed.');

        patient.how_did_hear = getField(patient.form['regform-insurance'], 'How did you hear about us?');

        if(!patient.informed_consent || patient.informed_consent !== 'I CONSENT TO USING TELEHEALTH SERVICES') patient.informed_consent = getFieldByValue(patient.form['regform-scs'], 'I CONSENT TO USING TELEHEALTH SERVICES');
        if(!patient.informed_consent || patient.informed_consent !== 'I CONSENT TO USING TELEHEALTH SERVICES') patient.informed_consent = getFieldByValue(patient.form['regform-spinal-fusion'], 'I CONSENT TO USING TELEHEALTH SERVICES');
        
        if(!patient.out_of_state_insurance || patient.out_of_state_insurance !== 'Click here if you have out-of-state insurance.') patient.out_of_state_insurance = 'No';
        if(patient.out_of_state_insurance === 'Click here if you have out-of-state insurance.') patient.out_of_state_insurance = 'Yes';

        if(!patient.informed_consent || patient.informed_consent !== 'I CONSENT TO USING TELEHEALTH SERVICES') patient.informed_consent = 'No';
        if(patient.informed_consent === 'I CONSENT TO USING TELEHEALTH SERVICES') patient.informed_consent = 'Yes';
        if(patient.form['regform-bariatric-1']) {
            patient.weight = parseInt(getField(patient.form['regform-bariatric-1'], 'Weight (in lbs.)'));

        } else if(patient.form['regform-scs-1']) {
            patient.weight = parseInt(getField(patient.form['regform-scs-1'], 'Weight (in lbs.)'));

        }

        patient.bmi = (patient.weight / Math.pow(((patient.height_feet * 12)+patient.height_inches), 2)) * 703;

        if(patient.form['regform-post-op-1']) {
            patient.same_provider = getField(patient.form['regform-post-op-1'], 'Would you like to schedule with the same therapist?');
        }
        if(patient.form['regform-pre-op-1']) {
            patient.same_provider = getField(patient.form['regform-pre-op-1'], 'Would you like to schedule with the same therapist?');
        }
        if(patient.form['regform-chronic-pain-1']) {
            patient.same_provider = getField(patient.form['regform-chronic-pain-1'], 'Would you like to schedule with the same therapist?');
        }
        
        
        patient.mental_health_illness = getField(patient.form['regform-bariatric-1'], 'Have you ever been diagnosed with any of the following mental health illnesses?');
        if(!patient.mental_health_illness) {
            patient.mental_health_illness = 'None';
            if(!patient.mental_illness) patient.mental_illness = 'denies';
        } else {
            if(!patient.mental_illness) patient.mental_illness = 'reports';
        }

        patient.mental_health_illness_other = getField(patient.form['regform-bariatric-1'], 'Other mental health illness');
        if(!patient.mental_health_illness_other || patient.mental_health_illness_other.trim() == '') {
            patient.mental_health_illness_other = 'None';
        }

        patient.mental_health_medications = getField(patient.form['regform-bariatric-1'], 'Have you ever been prescribed of the following mental health medications?');
        if(!patient.mental_health_medications) {
            patient.mental_health_medications = 'None';
        }

        patient.mental_health_medications_other = getField(patient.form['regform-bariatric-1'], 'Other mental health medication');
        if(!patient.mental_health_medications_other || patient.mental_health_medications_other.trim() == '') {
            patient.mental_health_medications_other = 'None';
        }

        patient.weight_loss_surgeries = getField(patient.form['regform-bariatric-1'], 'Have you ever had any previous weight loss surgeries?');
        if(!patient.weight_loss_surgeries) {
            patient.weight_loss_surgeries = 'None';
        } 

        patient.weight_loss_surgeries_other = getField(patient.form['regform-bariatric-1'], 'Other weight loss surgery');
        patient.estimated_surgery_date = getField(patient.form['regform-bariatric-1'], 'Estimated Surgery Date');
        if(!patient.estimated_surgery_date) patient.estimated_surgery_date = getField(patient.form['regform-bariatric-1'], '<b><i>Estimated</i></b> Surgery Date');

        if(patient.estimated_surgery_date) {
            patient.estimated_surgery_date = moment.tz(patient.estimated_surgery_date, site.timezone).format('YYYY-MM-DD');
            if(patient.estimated_surgery_date == 'Invalid date') patient.estimated_surgery_date = '';
        }

        
        if(!patient.weight_loss_surgeries_other || patient.weight_loss_surgeries_other.trim() == '') {
            patient.weight_loss_surgeries_other = 'None';
        }

        if(!patient.past_surgeries) patient.past_surgeries = patient.weight_loss_surgeries != 'None' ? patient.weight_loss_surgeries : patient.weight_loss_surgeries_other;

        patient.binge_eating = getFields(patient.form['regform-bariatric-4'], 'Have you gone on eating binges where you feel that you may not be able to stop? (Eating much more than most people would eat under the same circumstances)', 9);
        if(!patient.binge_eating_form && patient.binge_eating) patient.binge_eating_form = patient.binge_eating === 'Yes' ? 'endorses' : 'denies'; 
    
        patient.test_results = getRawScores(patient);

        if(!patient.self_induced_vomiting) patient.self_induced_vomiting = getField(patient.form['regform-bariatric-4'], 'Have you ever made yourself sick (vomited) to control your weight or shape?') === 'Yes' ? 'endorses' : 'denies';
        if(!patient.laxative_use) patient.laxative_use = getField(patient.form['regform-bariatric-4'], 'Have you ever used laxatives, diet pills or diuretics (water pills) to control your weight or shape?') === 'Yes' ? 'endorses' : 'denies'
        if(!patient.eating_disorer_treatment) patient.eating_disorer_treatment = getField(patient.form['regform-bariatric-4'], 'Have you ever been treated for an eating disorder?') === 'Yes' ? 'endorses' : 'denies'

        if(!patient.insurance) patient.insurance =  getField(patient.form['regform-insurance'], 'Insurance Name');

        if(!patient.insurance_number) patient.insurance_number =  getField(patient.form['regform-insurance'], 'Insurance ID Number');
        patient.name_on_card =  getField(patient.form['regform-insurance'], 'Name as it appears on your insurance card');
        patient.policy_holder =  getField(patient.form['regform-insurance'], 'Are you the insurance policy holder?');
        if(!patient.insurance_phone) patient.insurance_phone =  getField(patient.form['regform-insurance'], 'Provider customer service phone number.  (Found on the back of the insurance card)');

        if(!patient.claim_to) patient.claim_to =  getField(patient.form['regform-insurance'], 'Claims Address (Found on the back of the insurance card)');
        
        if(!patient.panic_attacks) patient.panic_attacks =  getField(patient.form['regform-bariatric-2'], 'In the last 4 weeks, have you had an anxiety attack; suddenly feeling fear or panic?');

        patient.spanish_provider_preferred =  getField(patient.form['regform-insurance'], 'Would you prefer a Spanish speaking provider if they are available?');
        
        patient.secondary_insurance =  getField(patient.form['regform-insurance'], 'Secondary Insurance - Insurance Name');
        patient.secondary_insurance_other_name =  getField(patient.form['regform-insurance'], 'Secondary Insurance Name');
        patient.secondary_insurance_number =  getField(patient.form['regform-insurance'], 'Secondary Insurance - Insurance ID Number');
        patient.secondary_insurance_phone =  getField(patient.form['regform-insurance'], 'Secondary Insurance - Provider customer service phone number.  (Found on the back of the insurance card)');

        
        if(!patient.dob) {
            const dob_moment = moment.tz(getField(patient.form['regform-insurance'], 'Date of Birth') + ' 10:00', site.timezone)
            patient.dob = dob_moment.isValid() ? dob_moment.hour(10).format('MM/DD/YYYY') : '';
        }

        if(!patient.dob_string) {
            patient.dob_string = getField(patient.form['regform-insurance'], 'Date of Birth').convertToISODate();
        }

        if(!patient.gender) patient.gender =  getField(patient.form['regform-bariatric-1'], 'Gender');

        if(!patient.gender || patient.gender === 'None') patient.gender =  getField(patient.form['regform-first'], 'Gender');
        if(!patient.gender || patient.gender === 'None') patient.gender =  getField(patient.form['regform-insurance'], 'Gender');
        patient.secondary_insurance = getField(patient.form['regform-insurance'], 'Secondary Insurance - Insurance Name');
        if(!patient.surgeon) patient.surgeon = getField(patient.form['regform-insurance'], 'Clinic / Surgeon');
        if(!patient.surgeon) patient.surgeon = getField(patient.form['regform-insurance'], 'Practice and/or Surgeon');
        if(!patient.surgeon) patient.surgeon = getField(patient.form['regform-insurance'], 'Referring Doctor');
        if(!patient.surgeon) patient.surgeon = getField(patient.form['regform-insurance'], 'Referring Doctor - Pain Doctor, Bariatric Surgeon, etc. <b><i>where the report should be sent</i></b> (NOT your primary care physician).');

        if(!patient.surgeon_phone) patient.surgeon_phone = getField(patient.form['regform-insurance'], 'Practice and/or Surgeon Phone Number');
        if(!patient.surgeon_phone) patient.surgeon_phone = getField(patient.form['regform-insurance'], 'Referring Doctor’s Phone Number');
        
        if(!patient.insured_name) patient.insured_name = getField(patient.form['regform-insurance'], 'Insured Name');

        if(!patient.bcbs_type) patient.bcbs_type = getField(patient.form['regform-insurance'], 'What type of Blue Cross Blue Shield do you have?');
        if(!patient.bluecard_holder) patient.bluecard_holder = getField(patient.form['regform-insurance'], 'Are you a BlueCard holder? (identified with a suitcase logo on the bottom right of your card)');
        if(!patient.bluecard_type) patient.bluecard_type = getField(patient.form['regform-insurance'], 'What type of plan is indicated inside the suitcase logo?');
        if(!patient.insured_dob) {
            const insured_dob_moment = moment.tz(getField(patient.form['regform-insurance'], 'Insured date of birth') + ' 10:00', site.timezone)
            patient.insured_dob = insured_dob_moment.isValid() ? insured_dob_moment.hour(10).format('MM/DD/YYYY') : '';
        }
        if(patient.procedure) {
            patient.is_counseling = ['Chronic Pain Counseling', 'Bariatric Counseling'].indexOf(patient.procedure) > -1;
        }
        // if(!patient.counseling_type) {
        //     let had_surgery = getField(patient.form['regform-post-op-2'], 'Did you have bariatric/weight-loss surgery?');
        //     if(had_surgery) {
        //         if(had_surgery.indexOf('Yes') > -1) {
                    
        //         }
        //     }
        // }
        
        console.log('procedure');
        if(!patient.procedure) {
        console.log('!procedure');
            patient.procedure = getField(patient.form['regform-insurance'], 'Procedure');

           if(patient.form['regform-post-op-1']) {
                patient.procedure = 'Bariatric Counseling';
            }
            if(patient.form['regform-chronic-pain-1']) {
                patient.procedure = 'Chronic Pain Counseling';
            }
            if(patient.form['regform-headache-1']) {
                patient.procedure = 'Occipital Nerve Decompression Evaluation';
            }
        }
        console.log('procedure!!', patient.procedure);

    }

    [
        'relief_from_past_procedures',
        'sleep_disturbances',
        'phq_9_score',
        'dass_21_d_score',
        'dass_21_a_score',
        'dass_21_s_score'
    ].forEach(function(index) {
        if(!patient[index]) patient[index] = '';
    })



    if(patient.procedure && typeof patient.procedure.trim === 'function' && patient.procedure.trim() != '' && patient.procedure.indexOf('::') > -1) patient.procedure = patient.procedure.split('::')[1];


    // let procedure_codes = {
    //     "Bariatric/Weight-loss Surgery": "BAR",
    //     "Bariatric/Weight-loss Surgery (pre-operative evaluation)": "BAR",
    //     "Spinal Cord Stimulator, Peripheral Nerve Stimulator, Intrathecal Pump, Spinal Fusion, Spinal Laminectomy, or Artificial Disc Replacement Evaluation": "SCS",
    //     "Spinal Cord Stimulators/Peripheral Nerve Stimulators/Intrathecal Pump/Spinal Fusion/Laminectomy/Artificial Lumbar Disc Replacement (evaluation)": "SCS",
    //     "Spinal Cord Stimulators/Peripheral Nerve Stimulators/Intrathecal Pump": "SCS",
    //     "Spinal Cord Stimulators/Peripheral Nerve Stimulators/Intrathecal Pump/Spinal Fusion/Laminectomy": "SCS",
    //     "Spinal Cord Stimulators/Peripheral Nerve Stimulators/Intrathecal Pump/Spinal Fusion/Laminectomy/Artificial Lumbar Disc Replacement": "SCS",
    //     "Pain Procedures/Evaluations (Spinal Cord Stimulator, Peripheral Nerve Stimulator, Intrathecal Pump, Spinal Fusion, Spinal Laminectomy, Lumbar/Cervical Arthroplasty or Sacroiliac Joint Fusion Evaluation)": "SCS",
    //     "Pain Procedures/Evaluations (Spinal Cord Stimulator, Peripheral Nerve Stimulator, Intrathecal Pump, Spinal Fusion, Spinal Laminectomy, Lumbar/Cervical Arthroplasty or Sacroiliac Joint Fusion Evaluation, Dorsal Column Stimulator, Intracept Procedure)": "SCS",
    //     "Chronic Headache Screening": "CHS",
    //     "Occipital Nerve Decompression Evaluation": "CHS",
    //     "Chronic Pain Counseling": "CPC",
    //     "Spinal Fusion/Laminectomy": "SFL",
    //     "Depression Screening": "DEP",
    //     "Addiction Screening": "ADI",
    //     "Bariatric Post-Op Counseling": "POC",
    //     "Weight Loss Counseling": "WLC",
    //     "Bariatric Counseling": "POC",
    //     "Bariatric Counseling (post-op counseling)": "POC",
    //     "Opioid Risk Assessment": "ORA"
    // }

    // if(patient.procedure && !patient.procedure_code) {
    //     patient.procedure_code = procedure_codes[patient.procedure];
    // }
    if(!patient.cash_payment || patient.cash_payment.trim() == '') patient.cash_payment = '';
    if(!patient.status) {
        patient.status = 'Started'; // #TODO: add this
        if(patient.next_form === 'done') patient.status = 'Registered';
        if(patient.completed_intake) patient.status = 'Registered';
        if(patient.therapist) patient.status = 'Scheduled';
    }
    patient.appointment_date = patient.appointment_date 
        ? moment(patient.appointment_date)
            .tz(site.timezone)
            .format('MM/DD/YYYY') 
        : ''; 

    if(!patient.appointment_date_modified) patient.appointment_date_modified = _patient.appointment_date 
        ? moment(_patient.appointment_date)
            .tz(site.timezone)
            .format('YYYY-MM-DD') 
        : ''; 

    if(patient.appointment_date_modified) patient.appointment_date_modified_nice = moment(patient.appointment_date_modified).format('MM/DD/YYYY');
    else patient.appointment_date_modified_nice = '';

    if(!patient.appointment_time) patient.appointment_time = _patient.appointment_date 
        ? moment(_patient.appointment_date)
            .tz(site.timezone)
            .format('h:mm:ss a') 
        : ''; 

    patient.appointment_datetime = _patient.appointment_date 
        ? moment(_patient.appointment_date)
            .tz(site.timezone)
            .format('MM/DD/YYYY h:mm a') 
        : ''; 

    patient.createdAt = moment(patient.createdAt)
        .tz(site.timezone)
        .format('MM/DD/YYYY');

    if(patient.date_registered) patient.date_registered = moment(patient.date_registered)
        .tz(site.timezone)
        .format('MM/DD/YYYY h:mm a');

    patient.report_date = patient.report_date 
        ? moment(patient.report_date)
            .tz(site.timezone)
            .format('MM/DD/YYYY') 
        : '';

    patient.report_submitted = patient.report_submitted 
        ? moment(patient.report_submitted)
            .tz(site.timezone)
            .format('MM/DD/YYYY') 
        : '';

    patient.verification_date = patient.verification_date 
        ? moment(patient.verification_date)
            .tz(site.timezone)
            .format('MM/DD/YYYY') 
        : '';

    if(patient.invoicing_status === 'Received') {
        patient.invoicing_status = 'Paid';
    }
    if(patient.invoicing_status === 'Sent') {
        patient.invoicing_status = 'Unpaid';
    }


    patient.data = [];
    patient.data_horizontal = {};
   // let index = 1;
    if(patient.form && patient.form['regform-first'] && typeof patient.form['regform-first'] === 'object' && typeof patient.form['regform-first'].find === 'function') {
        Object.keys(patient.form).forEach((key) => {
            patient.data.push({key});
            patient.form[key].forEach(item => {
              //  index++;
              if(!item) return;
              
              let name = item.name.replace(/\./g, ''),
                    repeat = 0;
                patient.data.push(item);

                while(patient.data_horizontal[name]) {
                    repeat++;
                    name = item.name + ' (repeated x'+repeat+')'; 
                    if(repeat == 50) break;
                }
                if(patient._id == '5ce44fc94b9efb31aed49a56') {
                    console.log('escalera', name, item.value);
                }
                let v;
                if(item.value) v = item.value.toString().replace(/\n/g, "\\n").replace(/"/g, '”');
                patient.data_horizontal[name] = v;
            })
        })
        if(patient._id == '5ce44fc94b9efb31aed49a56') {
            console.log('escalera', patient.data_horizontal);
        }
    }
   // index = 1000;
    if(patient.bmi) {
        patient.data.push({key: 'bmi', name: 'bmi', value: patient.bmi});
        patient.data_horizontal['bmi'] = patient.bmi;
    } else {
        patient.data_horizontal['bmi'] = 'Not set';
    }
    let patient_info = Object.keys(patient).map((_index) => {
       // index++;
      let item = patient[_index];
      if(typeof item === 'array' || typeof item === 'object' || ['browser_information', 'form','data','data_horizontal','binge_eating','test_results','phq_15_scores','eat_26_scores','phq_9_scores','gad_7_scores','dass_21_s_scores','dass_21_a_scores','dass_21_d_scores','admin_notes','report_content'].indexOf(_index) > -1) return false;
        
        if(item) item = item.toString().replace(/\n/g, "\\n").replace(/"/g, '”');
        patient.data_horizontal[_index] = item;
      return {
        key: _index,
        name: '',
        value: item
      }
      //else return {key: index, name: '', value: ''};
    });

    let billing_fields = [
        '_id',
        'first_name',
        'last_name',
        'dob_string',
        'gender',
        'appointment_date_modified',
        'report_date',
        'report_submitted',
        'therapist',
        'insurance',
        'insurance_number',
        'insurance_phone',
        'name_on_card',
        'policy_holder',
        'insured_name',
        'insured_dob',
        'secondary_insurance',
        'secondary_insurance_number',
        'secondary_insurance_phone',
        'address',
        'city',
        'state',
        'zip',
        'verification_notes',
        'apb_notes',
        'cash_payment',
        'phone',
        'email',
        'network',
        'scheduling_status',
        'surgeon',
        'copay',
        'invoicing_status',
        'report_status',
        'mental_health',
        'telehealth',
        'procedure',
        'deductible',
        'deductible_applies',
        'deductible_remaining',
        'oop_max',
        'practice_name',
        'procedure_code',
        'submit_claim',
        'oon_benefits',
        'invoice_to',
        'claim_to',
        'allowable_amount',
        'estimated_surgery_date',
        'counseling',
        'recommendation',
        'session',
        'how_did_hear',
        'total_time_seconds',
        'sec_bill_secondary',
        'bcbs_type',
        'bluecard_holder',
        'bluecard_type'
    ];
    patient.billing_data_horizontal = {};
    billing_fields.forEach((field) => {
        let val = patient.data_horizontal[field],
            insurance_other;
        
        if(field === 'insurance' && val === 'Other') {
            insurance_other = patient.data_horizontal['Insurance Name (repeated x1)'] || '';
            val += ' ('+insurance_other+')';
        }
        if(field === 'secondary_insurance' && val === 'Other') {
            insurance_other = patient.secondary_insurance_other_name;
            val += ' ('+insurance_other+')';
        }
        if(field === 'insured_dob' && val === 'Invalid date') {
            val = '';
        }

        patient.billing_data_horizontal[field] = val;
    });

    let all_data = patient_info.concat(patient.data);
    all_data = all_data.filter(Boolean);

    patient.data = all_data;


    patient.genders = [
        {
            display: "Female",
            gender_display: "female",
            pronoun: "she",
            third: "her",
            possessive: "her"
        },
        {
            display: "Male",
            gender_display: "male",
            pronoun: "he",
            third: "him",
            possessive: "his"
        },
        {
            display: "Transgender",
            gender_display: "transgender individual",
            pronoun: "they",
            third: "them",
            possessive: "their"
        },
        {
            display: "Woman",
            gender_display: "woman",
            pronoun: "she",
            third: "her",
            possessive: "her"
        },
        {
            display: "Man",
            gender_display: "man",
            pronoun: "he",
            third: "him",
            possessive: "his"
        },
        {
            display: "Non-binary/non-conforming",
            gender_display: "non-binary/non-conforming individual",
            pronoun: "they",
            third: "them",
            possessive: "their"
        },
        {
            display: "Prefer not to respond",
            gender_display: "person",
            pronoun: "they",
            third: "them",
            possessive: "their"
        }

    ];
    let gender = patient.genders.find(e => (patient.gender === e.display));

    console.log('gender', gender);
    patient.gender_display = gender ? gender.gender_display : 'person';
    patient.pronoun = gender ? gender.pronoun : 'they';
    patient.possessive = gender ? gender.possessive : 'their';
    patient.third = gender ? gender.third : 'their';
    patient.is_word = patient.pronoun === 'they' ? 'are' : 'is';
    patient.has_word = patient.pronoun === 'they' ? 'have' : 'has';
    if(patient.marital_status) {
        patient.marital_status_display = patient.marital_status.toLowerCase();
        if(patient.marital_status_display == 'domestic partnership') patient.marital_status_display = 'domestic partnered';
    }
    
    if(patient.scs_procedure && typeof patient.scs_procedure == 'string') patient.scs_procedure_title = toTitleCase(patient.scs_procedure)
    patient.networks = [
        '',
        'IN',
        'OON'
    ];

    patient.deductibles = [
        '',
        'Met',
        'Not-Met',
        'None',
        'Waived'
    ];
    patient.cancellation_reasons = [
        '',
        '0 - Call me to reschedule',
        '1 - Wants someone in network',
        '2 - Not having procedure',
        '3 - No tech',
        '4 - Other'
    ];
    patient.registration_statuses = [
        '',
        'Started',
        'Registered',
        'Scheduled'
    ];
    patient.scheduling_statuses = [
        '',
        'Cancelled',
        'No-Show',
        'Complete',
        'Confirmed',
    ];
    patient.invoicing_statuses = [
        '',
        'Unpaid',
        'Partial',
        'Paid'
    ];
    patient.billing_statuses = [
        '',
        'Pending',
        'Claim Sent',
        'Rejected-resubmit',
        'Claim resent',
        'Denied',
        'Complete'
    ];
    patient.report_statuses = [
        '',
        'Review',
        'Hold',
        'Complete',
        'Sent'
    ];
    patient.report_statuses_therapist = [
        '',
        'Review',
        'Complete'
    ];
    patient.verification_statuses = [
        '',
        'Review',
        'Missing Data',
        'Verified',
        'WOV'
    ];
    patient.portions_of_food = [
        '',
        'a normal portion of food',
        'a less than normal portion of food',
        'a more than normal portion of food'
    ];
    patient.types_of_eater = [
        'boredom eater',
        'stress eater',
        'emotional eater'
    ];
    patient.family_histories = [
        '',
        'denies any family history of obesity',
        'reports a significant family history of obesity'
    ];
    patient.binary = [
        '',
        'denies',
        'reports'
    ];
    patient.suicidal_ideations = [
        '',
        'reports past',
        'reports present',
        'reports past and present',
        'denies'
    ];
    patient.psych_factors_list = [
        '',
        'see',
        'do not see'
    ];
    patient.scs_procedures = [
        '',
        'artificial lumbar disc replacement',
        'spinal fusion/laminectomy',
        'spinal cord stimulator',
        'intrathecal pump',
        'peripheral nerve stimulation',
        'stimwave procedure',
        'dorsal root ganglion stimulation',
        'sacroiliac joint fusion',
        'lumbar arthroplasty',
        'cervical arthroplasty',
        'dorsal column stimulator',
        'intracept procedure',
        'other',
        'not sure'
    ];
    patient.relief_from_past_procedures_options = [
        '',
        'Minimal',
        'Average',
        'Complete'
    ];
    patient.sleep_disturbances_options = [
        '',
        'Does',
        'Does not'
    ];
    patient.endorse_deny = [
        '',
        'endorses',
        'denies'
    ];
    patient.invoice_tos = [
        'Patient',
        'Newport Care',
        'KC Pain Centers',
        'Atlantic Coast Pain Specialists',
        'Roller Weight Loss',
        'RUSH',
        'Sante',
        'TriWest',
        'Methodist Mansfield',
        'BARInet',
        'High Desert',
        'Fusion',
        'Lutfi Forward',
        'Lynx HealthCare',
        'Pain Treatment Centers of Georgia',
        'Midwest Anesthesia Pain Management',
        'Illinois Orthopaedic Institute',
        'Synergex Med',
        'Palm Medical Group',
        'Palm Research Center',
        'TouchStone Pain',
        'Bellavue Spine and Pain',
        'Swedish Medical Center - Pain Management',
        'Neurosurgical Associates Medical Group',
        'Other'
    ];
    patient.suitables = [
        '',
        'suitable',
        'not suitable'
    ];
    patient.supports = [
        '',
        'support',
        'do not support'
    ];
    patient.counseling_histories = [
        '',
        'has',
        'has not'
    ];
    patient.counseling_options = [
        '', 
        'Prospect', 
        'Lead', 
        'Counseling', 
        'Not Interested'
    ];
    patient.tobacco_uses = [
        '',
        'denies',
        'reports historical',
        'reports current'
    ];
    patient.insurances = [
        'Self-Pay/Cash',
        'Aetna',
        'Blue Cross Blue Shield',
        'Cigna',
        'Humana',
        'United',
        'Medicare',
        'Medicaid',
        'Tricare',
        'TriWest',
        'VA',
        'Workers Comp',
        'Other'
    ];

    return patient;
}
export default parsePatient;