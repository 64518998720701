import React from 'react';

class Downloading extends React.Component {
  render() {
    return (
      <div className="modal loading">
      	Your download is starting and should be finished within a few minutes.
      </div>
    );
  }
}

export default Downloading;