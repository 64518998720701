import React from "react";
import Cleave from "cleave.js/react";
import moment from "moment-timezone";

const VerificationView = ({ patient, onUpdateField, onSavePatient }) => {
  const counseling_options = patient.counseling_options
    ? patient.counseling_options.map((status) => {
        return (
          <option value={status} selected={status === patient.counseling}>
            {status}
          </option>
        );
      })
    : [];

  const verification_statuses = patient.verification_statuses
    ? patient.verification_statuses.map((status) => {
        return (
          <option
            value={status}
            selected={status === patient.verification_status}
          >
            {status}
          </option>
        );
      })
    : [];
  const networks = patient.networks
    ? patient.networks.map((status) => {
        return (
          <option value={status} selected={status === patient.network}>
            {status}
          </option>
        );
      })
    : [];
  const oon_benefits = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.oon_benefits}>
        {status}
      </option>
    );
  });
  const submit_claims = ["", "Yes", "No", "Review"].map((status) => {
    return (
      <option value={status} selected={status === patient.submit_claim}>
        {status}
      </option>
    );
  });
  const deductible_applies = ["", "Yes", "No"].map((status) => {
    return (
      <option value={status} selected={status === patient.deductible_applies}>
        {status}
      </option>
    );
  });
  const deductibles = patient.deductibles
    ? patient.deductibles.map((status) => {
        return (
          <option value={status} selected={status === patient.deductible}>
            {status}
          </option>
        );
      })
    : [];

  return (
    <div className="page admin form detail">
      <h1>
        Patient Detail: {patient.first_name} {patient.last_name}
      </h1>
      <button
        className="btn left"
        onClick={(e) => {
          e.preventDefault();
          onSavePatient();
        }}
      >
        Save
      </button>
      <a className="btn left" href="/admin">
        Cancel
      </a>
      <div class="detail-area">
        <table className="fullwidth">
          <tbody>
            <tr>
              <td>
                <h3>Verification</h3>
              </td>
            </tr>
            <tr>
              <td><strong>Payor Name</strong></td>
              <td>{patient.payor_name}</td>
            </tr>
            <tr>
              <td><strong>Contact #</strong></td>
              <td>{patient.contact_number}</td>
            </tr>
            <tr>
              <td><strong>Rep Name</strong></td>
              <td>{patient.rep_name}</td>
            </tr>
            <tr>
              <td><strong>Reference #</strong></td>
              <td>{patient.reference}</td>
            </tr>
            <tr>
              <td><strong>Advantum Team Member</strong></td>
              <td>{patient.advantum_team_member}</td>
            </tr>
            <tr>
              <td>
                <strong>Verification Status</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("verification_status", e.target.value);
                  }}
                >
                  {verification_statuses}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Verification Date</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={patient.verification_date}
                  onBlur={(e) =>
                    onUpdateField(
                      "verification_date",
                      moment(e.target.value).hour(10).format()
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Network</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("network", e.target.value);
                  }}
                >
                  {networks}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("deductible", e.target.value);
                  }}
                >
                  {deductibles}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("deductible_remaining", e.target.value)
                  }
                  value={patient.deductible_remaining}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Individual Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("ind_deductible_remaining", e.target.value)
                  }
                  value={patient.ind_deductible_remaining}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Family Deductible Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("fam_deductible_remaining", e.target.value)
                  }
                  value={patient.fam_deductible_remaining}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible Annual Reset Date</strong>
              </td>
              <td colspan="2">
                <Cleave
                  options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                  }}
                  placeholder="MM/DD/YYYY"
                  value={patient.deductible_annual_reset_date}
                  onBlur={(e) =>
                    onUpdateField(
                      "deductible_annual_reset_date",
                      e.target.value
                    )
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Deductible Applies</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("deductible_applies", e.target.value);
                  }}
                >
                  {deductible_applies}
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <strong>OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("oop_max", e.target.value)}
                  value={patient.oop_max}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Individual OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("ind_oop_max", e.target.value)}
                  value={patient.ind_oop_max}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Family OOP Max Remaining</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("fam_oop_max", e.target.value)}
                  value={patient.fam_oop_max}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>OON Benefits</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("oon_benefits", e.target.value);
                  }}
                >
                  {oon_benefits}
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Submit Claim</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("submit_claim", e.target.value);
                  }}
                >
                  {submit_claims}
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Amount Due</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) => onUpdateField("copay", e.target.value)}
                  value={patient.copay}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>Verification Notes</strong>
              </td>
              <td colspan="2">
                <textarea
                  onChange={(e) =>
                    onUpdateField("verification_notes", e.target.value)
                  }
                  value={patient.verification_notes}
                />
              </td>
            </tr>
            <tr>
              <td>
                <strong>DOB</strong>
              </td>
              <td colspan="2">{patient.dob_string}</td>
            </tr>
            <tr>
              <td>
                <strong>Authorization #</strong>
              </td>
              <td colspan="2">
                <input
                  type="text"
                  onChange={(e) =>
                    onUpdateField("authorization_number", e.target.value)
                  }
                  value={patient.authorization_number}
                />
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Counseling</strong>
              </td>
              <td colspan="2">
                <select
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    onUpdateField("counseling", e.target.value);
                  }}
                >
                  {counseling_options}
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Provider</strong>
              </td>
              <td colspan="2">{patient.therapist}</td>
            </tr>
            <tr>
              <td>
                <strong>Insurance Name</strong>
              </td>
              <td colspan="2">{patient.insurance}</td>
            </tr>
            <tr>
              <td>
                <strong>Insurance ID #</strong>
              </td>
              <td colspan="2">{patient.insurance_number}</td>
            </tr>
            <tr>
              <td>
                <strong>Provider Customer Service #</strong>
              </td>
              <td colspan="2">{patient.insurance_phone}</td>
            </tr>
            <tr>
              <td>
                <strong>Insured Name</strong>
              </td>
              <td colspan="2">{patient.insured_name}</td>
            </tr>
            <tr>
              <td>
                <strong>Insured DOB</strong>
              </td>
              <td colspan="2">{patient.insured_dob}</td>
            </tr>
            <tr>
              <td>
                <strong>Secondary Insurance</strong>
              </td>
              <td colspan="2">{patient.secondary_insurance}</td>
            </tr>
            <tr>
              <td>
                <strong>Address</strong>
              </td>
              <td colspan="2">
                {patient.address}
                <br />
                {patient.city}, {patient.state} {patient.zip}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VerificationView;
