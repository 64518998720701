import React from 'react';
import moment from 'moment';
import { CSVLink, CSVDownload } from "react-csv";
import parseUser from './parseUser';
import Login from './Login';
// import {changeInvoicingStatus} from './patientMiddleware';

class UserList extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	filter: null,
    	users: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.filterResults = this.filterResults.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }
  componentDidMount() {
  	if(!this.state.users) this.loginToken();
  }
  filterResults(value) {
  	this.setState({filter: value});
  }
	loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/users', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	window.location.href = '/admin';
              throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.data) {
        	//localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
        	that.setState({
        		users: res.data
        	})
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  deleteUser(id) {
    if(!window.confirm('Are you sure you want to delete this user?')) return;
    fetch(window.server_url + '/admin/users/'+id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+localStorage.getItem('token'),
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
       window.location.href = '/admin/users';
      });
  }
  
  render() {

    if(!this.state.users) return '';
    else {
    	let users = [];
    	let that = this;
    	this.state.users.sort((a,b) => (moment(b.createdAt).format('X') - moment(a.createdAt).format('X'))).forEach(user => {
    		user = parseUser(user);
        if(!user) return;


    		if(that.state.filter && that.state.filter.trim() !== '') {
    			let shards = that.state.filter.toLowerCase().split(' ');
    			let ok = true;
    			shards.forEach(function(shard) {
	    			let email_match = user.email && user.email.toLowerCase().indexOf(shard) > -1;
	    			if(!(email_match)) ok = false;
	    		});
	    		if(!ok) return;
    		}
    	// 	let forms = [];
  			// let data = [['question','answer']];
    	// 	for(var property in patient.form) {
    	// 		let form = patient.form[property];
    	// 		if(!form.length) continue;
    	// 		for (var x in form) {
    	// 			let value = form[x];
    	// 			if(!value) continue;
    	// 			data.push([value.name, value.value]);
    	// 		}

    	// 		console.log(data);
    	// 	}
    		//let email_span = patient.email ? <span class="email">({patient.email})</span> : '';
  			// forms.push(<CSVLink className="download-link" data={data}>Download patient intake form</CSVLink>);
  		  users.push(
          <tr className="item" onClick={e => {window.location.href = '/admin/user/' + user._id}}>
            <td>{user.email}</td>
            <td>{user.level}</td>
            <td>{user.created}</td>
            <td><a onClick={e => {e.stopPropagation(); this.deleteUser(user._id)}}>Delete User</a></td>
          </tr>);
    	});
    	return (
	    	<div className="page admin form">
		    	<h1>Users</h1>
		    	<input name="search" placeholder="Filter users" onChange={e => {this.filterResults(e.target.value)}} />
		    	<br/>
          <div className="results-area">
          <table id="results">
            <tbody>
              <tr className="header">
                <th class="registration">Email</th>
                <th class="registration">Level</th>
                <th class="registration">Created</th>
                <th class="registration">Delete</th>
              </tr>
    		    	{users}
              <tr class="header">
                <td>
                  <a href="/admin/users/add">Add User</a>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
		    </div>
	    );
    }
  }
}

export default UserList;