import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class TherapistPatientForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	patient: null,
      loading: false,
      hide_confirm: false,
      therapists: null,
      level: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.savePatient = this.savePatient.bind(this);
    this.formRef = React.createRef();
  }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
	savePatient(auto = false) {
    if(auto) console.log('autosave!');
    let token = localStorage.getItem('token'),
        that = this;

    let data = new FormData(this.formRef.current);
    let object = {};

    if(!auto) {
      let messages = [];
      if(data.get('suitable_for_surgery') === 'not suitable' || data.get('recommendation') === 'do not support') {
        data.set('patient_fail', true);
        messages.push('You have marked this patient as a FAIL, please confirm.');
      }
      if(data.get('suitable_for_surgery') === 'not suitable' && data.get('recommendation') === 'support') {
        messages.push('You have marked this patient as not suitable for surgery, but marked your recommendation as "support", please confirm.');
      }
      if(data.get('suitable_for_surgery') === 'suitable' && data.get('recommendation') === 'do not support') {
        messages.push('You have marked this patient as suitable for surgery, but marked your recommendation as "do not support", please confirm.');
      }
      if(messages.length) {
        if(!window.confirm(messages.join("\n"))) {
          return;
        }

      }
    }
    data.forEach((value, key) => {
      if (key === 'report_date' && value.trim() !== '') {
        value = moment(value).hour(10).format();
      }
     
      if(object[key]) {
        if(typeof object[key] === 'array') object[key].push(value)
        else object[key] = [object[key], value];
      } else object[key] = value
    });
    if(object['type_of_eater'] && typeof object['type_of_eater'].join === 'function') object['type_of_eater'] = object['type_of_eater'].join(',');
    let json = JSON.stringify(object);

    console.log('object', json);



    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: json,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!auto) {
          window.location.href='/admin/patient/' + that.props.match.params.patient_id;
        } else {
          window.autoSave = window.setTimeout(function() { that.savePatient(true) }, 30000);
        }
        // if(!res.data) {
        //   localStorage.removeItem('token');
        //   window.location.href = '/admin';
        //   return false;
        // } else {
        //   // that.setState({
        //   //   patient: res.data
        //   // })
        // }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      if(!auto) {
        this.setState({loading: true});
      } 
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!res.data) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
        	that.setState({
            patient: res.data,
        		therapists: res.therapists,
            level: res.level
        	})
          window.autoSave = window.setTimeout(function() { that.savePatient(true) }, 30000);
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  componentWillUnmount() {
    window.clearTimeout(window.autoSave);
  }
  getField(form, field) {
    let match;

    if(!form) return 'None';
    
    match = form.find(e => (e && e.name === field));
    if(match) return match.value;
  }
  update(key, value) {
    let _patient = Object.assign({}, this.state.patient);
    if(key === 'therapist') {
      let parts = value.split('|');
      _patient['therapist_id'] = parts[0];
      _patient['therapist'] = parts[1];
    } else if (key === 'report_date') {
      _patient[key] = moment(value).hour(10).format();
    } else {
      _patient[key] = value;
    }
    this.setState({
      patient: _patient
    })
  }
  therapist_scs(patient) {


    let results_table;
    let results = patient.test_results;

    console.log('test_results', patient.test_results);
    console.log('phq_15_score', patient.phq_15_score);
    
    let genders = patient.genders.map(status => {
      return <option value={status.display} selected={status.display === patient.gender}>{status.display}</option>
    });

    let scs_procedures = patient.scs_procedures.map(status => {
      return <option value={status} selected={status === patient.scs_procedure}>{status}</option>
    });
    let relief_from_past_procedures_options = patient.relief_from_past_procedures_options.map(status => {
      return <option value={status} selected={status === patient.relief_from_past_procedures}>{status}</option>
    });
    let sleep_disturbances_options = patient.sleep_disturbances_options.map(status => {
      return <option value={status} selected={status === patient.sleep_disturbances}>{status}</option>
    });
    let clinically_relevant_catastrophizings = ['', 'does', 'does not'].map(status => {
      return <option value={status} selected={status === patient.clinically_relevant_catastrophizing}>{status}</option>
    });
    let appetite_last_30_days = ['', 'poor', 'average', 'good'].map(status => {
      return <option value={status} selected={status === patient.appetite_last_30_days}>{status}</option>
    });
    let long_term_memory = ['', 'poor', 'average', 'good'].map(status => {
      return <option value={status} selected={status === patient.long_term_memory}>{status}</option>
    });
    let short_term_memory = ['', 'poor', 'average', 'good'].map(status => {
      return <option value={status} selected={status === patient.short_term_memory}>{status}</option>
    });
    let sexual_pleasures = ['', 'absence of', 'less than normal', 'normal'].map(status => {
      return <option value={status} selected={status === patient.sexual_pleasure}>{status}</option>
    });
    let past_treatments = ['', 'denies', 'reports'].map(status => {
      return <option value={status} selected={status === patient.past_treatment}>{status}</option>
    });
    
    let family_histories = patient.family_histories.map(status => {
      return <option value={status} selected={status === patient.family_history}>{status}</option>
    });
    let social_histories_trauma = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_trauma}>{status}</option>
    });
    let social_histories_physical = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_physical}>{status}</option>
    });
    let social_histories_emotional = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_emotional}>{status}</option>
    });
    let social_histories_sexual = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_sexual}>{status}</option>
    });
    let social_histories_substance = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_substance}>{status}</option>
    });
    let social_histories_eating = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_eating}>{status}</option>
    });
    let mental_illnesses = patient.binary.map(status => {
      return <option value={status} selected={status === patient.mental_illness}>{status}</option>
    });
    let mental_illnesses_symptoms = ['', 'are', 'are not'].map(status => {
      return <option value={status} selected={status === patient.mental_illness_symptoms_manageable}>{status}</option>
    });
    let expected_pain_reliefs = ['', 'mild', 'moderate', 'complete'].map(status => {
      return <option value={status} selected={status === patient.expected_pain_relief}>{status}</option>
    });
    let is_revisions = ['', 'yes', 'no'].map(status => {
      return <option value={status} selected={status === patient.is_revision}>{status}</option>
    });
    let litigation_pendings = ['', 'yes', 'no'].map(status => {
      return <option value={status} selected={status === patient.litigation_pending}>{status}</option>
    });
    let personality_disorders = ['', 'reports', 'denies'].map(status => {
      return <option value={status} selected={status === patient.personality_disorders}>{status}</option>
    });
    let suicidal_ideations = patient.suicidal_ideations.map(status => {
      return <option value={status} selected={status === patient.suicidal_ideation}>{status}</option>
    });
    let tobacco_uses = patient.tobacco_uses.map(status => {
      return <option value={status} selected={status === patient.tobacco_use_form}>{status}</option>
    });
    let phq_psych_factors = patient.psych_factors_list.map(status => {
      return <option value={status} selected={status === patient.phq_psych_factors}>{status}</option>
    });
    // let whympi_psych_factors = patient.psych_factors_list.map(status => {
    //   return <option value={status} selected={status === patient.whympi_psych_factors}>{status}</option>
    // });
    let panic_attacks = patient.endorse_deny.map(status => {
      return <option value={status} selected={status === patient.panic_attacks}>{status}</option>
    });

    let phq_15_scores = patient.phq_15_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.phq_15_score}>{status.score}</option>
    });
    let gad_7_scores = patient.gad_7_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.gad_7_score}>{status.score}</option>
    });
    let score = patient.phq_9_score;
    console.log('scorescore', score)
    if(!score) score = patient.test_results.phq_9_score;
    console.log('scorescore', score)

    let phq_9_scores = patient.phq_9_scores.map(status => {
      return <option value={status.score} selected={status.score === score}>{status.score}</option>
    });
    let dass_21_d_scores = patient.dass_21_d_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.dass_21_d_score}>{status.score}</option>
    });
    let dass_21_a_scores = patient.dass_21_a_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.dass_21_a_score}>{status.score}</option>
    });
    let dass_21_s_scores = patient.dass_21_s_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.dass_21_s_score}>{status.score}</option>
    });
    let dass_d_psych_factors = patient.psych_factors_list.map(status => {
      return <option value={status} selected={status === patient.dass_d_psych_factors}>{status}</option>
    });
    let dass_a_psych_factors = patient.psych_factors_list.map(status => {
      return <option value={status} selected={status === patient.dass_a_psych_factors}>{status}</option>
    });
    let dass_s_psych_factors = patient.psych_factors_list.map(status => {
      return <option value={status} selected={status === patient.dass_s_psych_factors}>{status}</option>
    });

    let suitables = patient.suitables.map(status => {
      return <option value={status} selected={status === patient.suitable_for_surgery}>{status}</option>
    });
    let supports = patient.supports.map(status => {
      return <option value={status} selected={status === patient.recommendation}>{status}</option>
    });
    let counseling_histories = patient.counseling_histories.map(status => {
      return <option value={status} selected={status === patient.counseling_history}>{status}</option>
    });
    let currently_seeing_nutritionists = ['Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.currently_seeing_nutritionist}>{status}</option>
    });

    return (
        <div className="page admin form detail">
          <h1>Patient Notes: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href={`/admin/patient/${patient._id}`}>Cancel</a>
          <div class="detail-area">
          <form ref={this.formRef}>
          <table className="fullwidth notes">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{patient.first_name} {patient.last_name}</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>{patient.age}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='gender' >
                    {genders}
                  </select>
                </td>
              </tr>
              <tr>
                <td>DOB</td>
                <td>{patient.dob_string}</td>
              </tr>
              <tr>
                <td>Date of Interview</td>

                <td>
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    className="date-field"
                    placeholder="MM/DD/YYYY"
                    value={patient.appointment_date_modified_nice} 
                    name="appointment_date_modified" />
                </td>
              </tr>
              <tr>
                <td>Procedure</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='scs_procedure' >
                    {scs_procedures}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Date of Report</td>
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    className="date-field"
                    placeholder="MM/DD/YYYY"
                    value={moment(patient.report_date).format('MM/DD/YYYY')} 
                    name="report_date" />
              </tr>
              <tr>
                <td>Marital Status</td>
                  <input type="text" name='marital_status' defaultValue={patient.marital_status} />
              </tr>
              <tr>
                <td>Highest Level of Education</td>
                  <input type="text" name='education' defaultValue={patient.education} />
              </tr>
              <tr>
                <td>Employed as</td>
                  <input type="text" name='occupation' defaultValue={patient.occupation} />
              </tr>
              <tr>
                <td>Height:</td>
                <td></td>
              </tr>
              <tr>
                <td><span class="space"></span>Feet</td>
                <td>{patient.height_feet}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Inches</td>
                <td>{patient.height_inches}</td>
              </tr>
              <tr>
                <td>Weight</td>
                <td>{patient.weight}</td>
              </tr>
              <tr>
                <td>Onset of pain</td>
                  <input type="text" name='onset_of_pain' defaultValue={patient.onset_of_pain} />
              </tr>
              <tr>
                <td>Pain increasing conditions</td>
                  <input type="text" name='pain_increasing_conditions' defaultValue={patient.pain_increasing_conditions} />
              </tr>
              <tr>
                <td>Pain decreasing conditions</td>
                  <input type="text" name='pain_decreasing_conditions' defaultValue={patient.pain_decreasing_conditions} />
              </tr>
              <tr>
                <td>Pain site</td>
                  <input type="text" name='pain_site' defaultValue={patient.pain_site} />
              </tr>
              <tr>
                <td>Past spinal procedures</td>
                  <input type="text" name='past_surgeries' defaultValue={patient.past_surgeries} />
              </tr>
              <tr>
                <td>Relief from past procedures:</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='relief_from_past_procedures'>
                    {relief_from_past_procedures_options}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Current pain medications, including over-the-counter</td>
                  <input type="text" name='current_pain_medications' defaultValue={patient.current_pain_medications} />
              </tr>
              <tr>
                <td>Non-spine medical problems</td>
                  <input type="text" name='non_spine_medical_problems' defaultValue={patient.non_spine_medical_problems} />
              </tr>
              <tr>
                <td>Daytime activities</td>
                  <input type="text" name='daytime_activities' defaultValue={patient.daytime_activities} />
              </tr>
              <tr>
                <td>Hours of sleep per night</td>
                  <input type="text" name='hours_of_sleep_per_night' defaultValue={patient.hours_of_sleep_per_night} />
              </tr>
              <tr>
                <td>Does the patient report sleep disturbances?</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='sleep_disturbances' >
                    {sleep_disturbances_options}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Appetite in last 30 days</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='appetite_last_30_days' >
                    {appetite_last_30_days}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Long-term memory</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='long_term_memory' >
                    {long_term_memory}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Short-term memory</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='short_term_memory' >
                    {short_term_memory}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Sexual pleasure</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='sexual_pleasure' >
                    {sexual_pleasures}
                  </select>
                </td>
              </tr>
              <tr>
                <td>Average daily mood</td>
                  <input type="text" name='average_daily_mood' defaultValue={patient.average_daily_mood} />
              </tr>
              <tr>
                <td>Current stressors</td>
                  <input type="text" name='current_stressors' defaultValue={patient.current_stressors} />
              </tr>
              <tr>
                <td>Non-medical coping mechanisms</td>
                  <input type="text" name='non_medical_coping_mechanisms' defaultValue={patient.non_medical_coping_mechanisms} />
              </tr>


              <tr><td>Social History</td></tr>
              <tr>
                <td><span class="space"></span>Trauma</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_trauma' >
                    {social_histories_trauma}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Sexual Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_sexual' >
                    {social_histories_sexual}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Emotional Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_emotional' >
                    {social_histories_emotional}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Physical Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_physical' >
                    {social_histories_physical}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Substance Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_substance' >
                    {social_histories_substance}
                  </select>
              </tr>

              <tr>
                <td><span class="space"></span>Additional notes for social history</td>
                  <input type="text" name='social_history_notes' defaultValue={patient.social_history_notes} />
              </tr>
              <tr>
                <td>Mental Illness</td>
                  <select onClick={e => {e.stopPropagation()}} name='mental_illness' >
                    {mental_illnesses}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Diagnosis & medications</td>
                  <input type="text" name='mental_illness_diagnosis_medications' defaultValue={patient.mental_illness_diagnosis_medications} />
              </tr>
              <tr>
                <td><span class="space"></span>Symptoms manageable with current medication</td>
                  <select onClick={e => {e.stopPropagation()}} name='mental_illness_symptoms_manageable' >
                    {mental_illnesses_symptoms}
                  </select>
              </tr>
              <tr>
                <td>Suicidal Ideation: Detailed notes - full sentence(s)</td>
                  <select onClick={e => {e.stopPropagation()}} name='suicidal_ideation' >
                    {suicidal_ideations}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Detailed notes</td>
                  <input type="text" name='suicidal_ideation_notes' defaultValue={patient.suicidal_ideation_notes} />
              </tr>
              <tr>
                <td>Past inpatient or outpatient treatment:</td>
                  <select onClick={e => {e.stopPropagation()}} name='past_treatment' >
                    {past_treatments}
                  </select>
              </tr>
              <tr>
                <td>Additional notes:</td>
                  <input type="text" name='past_treatment_notes' defaultValue={patient.past_treatment_notes} />
              </tr>
              <tr>
                <td><span class="space"></span>Current recreational activities</td>
                  <input type="text" name='current_recreational_activities' defaultValue={patient.current_recreational_activities} />
              </tr>
              <tr>
                <td><span class="space"></span>Past recreational activities</td>
                  <input type="text" name='past_recreational_activities' defaultValue={patient.past_recreational_activities} />
              </tr>
              <tr>
                <td>Litigation pending</td>
                  <select onClick={e => {e.stopPropagation()}} name='litigation_pending' >
                    {litigation_pendings}
                  </select>
              </tr>
              <tr>
                <td>Additional notes:</td>
                  <input type="text" name='litigation_pending_notes' defaultValue={patient.litigation_pending_notes} />
              </tr>
              <tr>
                <td>Personality disorders</td>
                  <select onClick={e => {e.stopPropagation()}} name='personality_disorders' >
                    {personality_disorders}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Detailed notes</td>
                  <input type="text" name='personality_disorders_notes' defaultValue={patient.personality_disorders_notes} />
              </tr>

              <tr>
                <td>Tobacco Use</td>
                  <select onClick={e => {e.stopPropagation()}} name='tobacco_use_form' >
                    {tobacco_uses}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Detailed notes</td>
                  <input type="text" name='tobacco_use_notes' defaultValue={patient.tobacco_use_notes} />
              </tr>
              <tr>
                <td><span class="space"></span>NIDA Quick Screen score</td>
                  <input type="text" name='nida_quick_screen_score' defaultValue={patient.nida_quick_screen_score} />
              </tr>
              <tr>
                <td><span class="space"></span>Chronic pain questionnaire</td>
                  <input type="text" name='chronic_pain_questionnaire' defaultValue={patient.chronic_pain_questionnaire} />
              </tr>
              <tr><td></td></tr>
              <tr>
                <td>PHQ-9</td>
                <td>{patient.test_results.phq_9_raw_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='phq_9_score' >
                    {phq_9_scores}
                  </select>
              </tr>

              <tr>
                <td>Psychological Factors</td>
                  <select onClick={e => {e.stopPropagation()}} name='phq_psych_factors' >
                    {phq_psych_factors}
                  </select>
              </tr>

              <tr><td></td></tr>
              <tr>
                <td>DASS-21 - Depression Scale</td>
                <td>{patient.test_results.dass_21_d_adjusted_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_21_d_score' >
                    {dass_21_d_scores}
                  </select>
              </tr>
              <tr>
                <td>Psychological Factors</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_d_psych_factors' >
                    {dass_d_psych_factors}
                  </select>
              </tr>

              <tr>
                <td>Anxiety Scale</td>
                <td>{patient.test_results.dass_21_a_adjusted_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_21_a_score' >
                    {dass_21_a_scores}
                  </select>
              </tr>
              <tr>
                <td>Psychological Factors</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_a_psych_factors' >
                    {dass_a_psych_factors}
                  </select>
              </tr>

              <tr>
                <td>Stress</td>
                <td>{patient.test_results.dass_21_s_adjusted_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_21_s_score' >
                    {dass_21_s_scores}
                  </select>
              </tr>
              <tr>
                <td>Psychological Factors</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_s_psych_factors' >
                    {dass_s_psych_factors}
                  </select>
              </tr>
              <tr><td></td></tr>

              <tr>
                <td><strong>Pain Catastrophizing Scale Score</strong></td>
                <td>{patient.test_results.pain_catastrophizing_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Indicate a clinically relevant level of catastrophizing</td>
                  <select onClick={e => {e.stopPropagation()}} name='clinically_relevant_catastrophizing' >
                    {clinically_relevant_catastrophizings}
                  </select>
              </tr>

              <tr><td></td></tr>
              <tr>
                <td>Expected pain relief:</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='expected_pain_relief'>
                    {expected_pain_reliefs}
                  </select>
                </td>
              </tr>


              <tr>
                <td>Support Network</td>
                  <input type="text" name='support_network' defaultValue={patient.support_network} />
              </tr>
              <tr>
                <td>Is patient suitable for surgery?</td>
                  <select onClick={e => {e.stopPropagation()}} name='suitable_for_surgery' >
                    {suitables}
                  </select>
              </tr>
              <tr>
                <td>Recommendation</td>
                  <select onClick={e => {e.stopPropagation()}} name='recommendation' >
                    {supports}
                  </select>
              </tr>

            </tbody>
          </table>
          </form>
          </div>
        </div>
      );
  
  }
  therapist(patient) {

    let results_table;
    let results = patient.test_results;

    console.log('test_results', patient.test_results);
    console.log('phq_15_score', patient.phq_15_score);
    
    let genders = patient.genders.map(status => {
      return <option value={status.display} selected={status.display === patient.gender}>{status.display}</option>
    });
    let portions_of_food = patient.portions_of_food.map(status => {
      return <option value={status} selected={status === patient.portion_of_food}>{status}</option>
    });
    let types_of_eater = patient.types_of_eater.map(status => {
      let checked = '';
      if(patient.type_of_eater && patient.type_of_eater.split(',').indexOf(status) > -1) {
        checked = 'checked';
      }
      return (
        <div className="checkbox-list">
          <input type="checkbox" name="type_of_eater" value={status} defaultChecked={checked} /> 
          <label>{status}</label>
        </div>
      );
    });
    let family_histories = patient.family_histories.map(status => {
      return <option value={status} selected={status === patient.family_history}>{status}</option>
    });
    let social_histories_physical = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_physical}>{status}</option>
    });
    let social_histories_sexual = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_sexual}>{status}</option>
    });
    let social_histories_substance = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_substance}>{status}</option>
    });
    let social_histories_eating = patient.binary.map(status => {
      return <option value={status} selected={status === patient.social_history_eating}>{status}</option>
    });
    let mental_illnesses = patient.binary.map(status => {
      return <option value={status} selected={status === patient.mental_illness}>{status}</option>
    });
    let mental_illnesses_symptoms = ['', 'are', 'are not'].map(status => {
      return <option value={status} selected={status === patient.mental_illness_symptoms_manageable}>{status}</option>
    });
    let is_revisions = ['', 'yes', 'no'].map(status => {
      return <option value={status} selected={status === patient.is_revision}>{status}</option>
    });
    let suicidal_ideations = patient.suicidal_ideations.map(status => {
      return <option value={status} selected={status === patient.suicidal_ideation}>{status}</option>
    });
    let tobacco_uses = patient.tobacco_uses.map(status => {
      return <option value={status} selected={status === patient.tobacco_use_form}>{status}</option>
    });
    let phq_psych_factors = patient.psych_factors_list.map(status => {
      return <option value={status} selected={status === patient.phq_psych_factors}>{status}</option>
    });
    let panic_attacks = patient.endorse_deny.map(status => {
      return <option value={status} selected={status === patient.panic_attacks}>{status}</option>
    });

    let phq_15_scores = patient.phq_15_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.test_results.phq_15_score}>{status.score}</option>
    });
    let gad_7_scores = patient.gad_7_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.test_results.gad_7_score}>{status.score}</option>
    });
    let phq_9_scores = patient.phq_9_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.test_results.phq_9_score}>{status.score}</option>
    });
    let dass_21_d_scores = patient.dass_21_d_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.test_results.dass_21_d_score}>{status.score}</option>
    });
    let dass_21_a_scores = patient.dass_21_a_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.test_results.dass_21_a_score}>{status.score}</option>
    });
    let dass_21_s_scores = patient.dass_21_s_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.test_results.dass_21_s_score}>{status.score}</option>
    });
    let dass_psych_factors = patient.psych_factors_list.map(status => {
      return <option value={status} selected={status === patient.dass_psych_factors}>{status}</option>
    });
    let binge_eating = patient.endorse_deny.map(status => {
      return <option value={status} selected={status === patient.binge_eating_form}>{status}</option>
    });
    let self_induced_vomiting = patient.endorse_deny.map(status => {
      return <option value={status} selected={status === patient.self_induced_vomiting}>{status}</option>
    });
    let laxative_use = patient.endorse_deny.map(status => {
      return <option value={status} selected={status === patient.laxative_use}>{status}</option>
    });
    let eating_disorder_treatment = patient.endorse_deny.map(status => {
      return <option value={status} selected={status === patient.eating_disorder_treatment}>{status}</option>
    });
    let eating_disorder_behaviors = patient.psych_factors_list.map(status => {
      return <option value={status} selected={status === patient.eating_disorder_behaviors}>{status}</option>
    });

    let eat_26_scores = patient.eat_26_scores.map(status => {
      return <option value={status.score} selected={status.score === patient.test_results.eat_26_score}>{status.score}</option>
    });
    let suitables = patient.suitables.map(status => {
      return <option value={status} selected={status === patient.suitable_for_surgery}>{status}</option>
    });
    let supports = patient.supports.map(status => {
      return <option value={status} selected={status === patient.recommendation}>{status}</option>
    });
    let counseling_histories = patient.counseling_histories.map(status => {
      return <option value={status} selected={status === patient.counseling_history}>{status}</option>
    });
    let currently_seeing_nutritionists = ['Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.currently_seeing_nutritionist}>{status}</option>
    });
    let how_often_miss_medications = ['','never', 'rarely', 'often'].map(status => {
      return <option value={status} selected={status === patient.how_often_miss_medications}>{status}</option>
    });
    let how_remember_take_medications = ['','sorter', 'timer', 'alarm', 'written notes', 'family member'].map(status => {
      return <option value={status} selected={status === patient.how_remember_take_medications}>{status}</option>
    });
    let compliant_conditions = ['','Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.compliant_conditions}>{status}</option>
    });
    let bariatric_fields = '';
    let alcohol_question = '';

    if(patient.procedure_code === 'BAR') {
      bariatric_fields = [
        <tr><td>&nbsp;</td></tr>,
        <tr>
          <th>Medication Compliance</th>
        </tr>,
        <tr>
          <td>How often do you miss or delay medications?</td>
            <select onClick={e => {e.stopPropagation()}} name='how_often_miss_medications' >
              {how_often_miss_medications}
            </select>
        </tr>,
        <tr>
          <td>How do you remember to take your medications?</td>
            <select onClick={e => {e.stopPropagation()}} name='how_remember_take_medications' >
              {how_remember_take_medications}
            </select>
        </tr>,
        <tr>
          <td>Are you compliant with relevant regimens to manage any chronic conditions such as sleep apnea, diabetes, etc?</td>
            <select onClick={e => {e.stopPropagation()}} name='compliant_conditions' >
              {compliant_conditions}
            </select>
        </tr>

      ];
      alcohol_question = (
        <tr>
          <td>Number of alcoholic drinks per week:</td>
            <input type="text" name='drinks_per_week' defaultValue={patient.drinks_per_week} />
        </tr>
      )
    }

    return (
        <div className="page admin form detail">
          <h1>Patient Notes: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href={`/admin/patient/${patient._id}`}>Cancel</a>
          <div class="detail-area">
          <form ref={this.formRef}>
          <table className="fullwidth notes">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{patient.first_name} {patient.last_name}</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>{patient.age}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} name='gender' >
                    {genders}
                  </select>
                </td>
              </tr>
              <tr>
                <td>DOB</td>
                <td>{patient.dob_string}</td>
              </tr>
              <tr>
                <td>Date of Interview</td>
                <td>{patient.appointment_date_modified_nice}</td>
              </tr>
              <tr>
                <td>Date of Report</td>
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    className="date-field"
                    placeholder="MM/DD/YYYY"
                    value={moment(patient.report_date).format('MM/DD/YYYY')} 
                    name="report_date" />
              </tr>
              <tr>
                <td>Marital Status</td>
                  <input type="text" name='marital_status' defaultValue={patient.marital_status} />
              </tr>
              <tr>
                <td>Highest Level of Education</td>
                  <input type="text" name='education' defaultValue={patient.education} />
              </tr>
              <tr>
                <td>Employed as</td>
                  <input type="text" name='occupation' defaultValue={patient.occupation} />
              </tr>
              <tr>
                <td>Height:</td>
                <td></td>
              </tr>
              <tr>
                <td><span class="space"></span>Feet</td>
                <td>{patient.height_feet}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Inches</td>
                <td>{patient.height_inches}</td>
              </tr>
              <tr>
                <td>Weight</td>
                <td>{patient.weight}</td>
              </tr>
              <tr>
                <td>BMI</td>
                <td>{patient.bmi}</td>
              </tr>
              <tr>
                <td>How long has patient struggled with weight?</td>
                  <input type="text" name='weight_history' defaultValue={patient.weight_history} />
              </tr>
              <tr>
                <td>Reasons for weight issues</td>
                  <input type="text" name='reasons' defaultValue={patient.reasons || "poor food choices, lack of exercise and genetics"} />
              </tr>
              <tr>
                <td>Other ways to lose weight</td>
                  <input type="text" name='other_ways_to_lose_weight' defaultValue={patient.other_ways_to_lose_weight || "diet and exercise"} />
              </tr>
              <tr>
                <td>Breakfast</td>
                  <input type="text" name='breakfast' defaultValue={patient.breakfast} />
              </tr>
              <tr>
                <td>Lunch</td>
                  <input type="text" name='lunch' defaultValue={patient.lunch} />
              </tr>
              <tr>
                <td>Dinner</td>
                  <input type="text" name='dinner' defaultValue={patient.dinner} />
              </tr>
              <tr>
                <td>Snacks</td>
                  <input type="text" name='snacks' defaultValue={patient.snacks} />
              </tr>
              <tr>
                <td>Drinks</td>
                  <input type="text" name='drinks' defaultValue={patient.drinks} />
              </tr>
              <tr>
                <td>Portion of Food</td>
                  <select onClick={e => {e.stopPropagation()}} name='portion_of_food' >
                    {portions_of_food}
                  </select>
              </tr>
              <tr>
                <td>Type of Eater (check all that apply)</td>
                <td><input type="hidden" name="type_of_eater" value="" />{types_of_eater}</td>
              </tr>
              <td>Are you currently seeing a dietician or nutritionist?</td>
                  <select onClick={e => {e.stopPropagation()}} name='currently_seeing_nutritionist' >
                    {currently_seeing_nutritionists}
                  </select>
              <tr>
                <td>If yes, how long? Full sentence(s)</td>
                  <input type="text" name='currently_seeing_nutritionist_how_long' defaultValue={patient.currently_seeing_nutritionist_how_long} />
              </tr>

              <tr>
                <td>Medical Issues</td>
                  <input type="text" name='medical_issues' defaultValue={patient.medical_issues} />
              </tr>
              {bariatric_fields}
              <tr>
                <td>Past Surgeries</td>
                  <input type="text" name='past_surgeries' defaultValue={patient.past_surgeries} />
              </tr>
              <tr>
                <td>Is this surgery a revision?</td>
                  <select onClick={e => {e.stopPropagation()}} name='is_revision' >
                    {is_revisions}
                  </select>
              </tr>
              <tr>
                <td>Family history of obesity</td>
                  <select onClick={e => {e.stopPropagation()}} name='family_history' >
                    {family_histories}
                  </select>
              </tr>
              <tr><td>Social History</td></tr>
              <tr>
                <td><span class="space"></span>Physical Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_physical' >
                    {social_histories_physical}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Sexual Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_sexual' >
                    {social_histories_sexual}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Substance Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_substance' >
                    {social_histories_substance}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Eating Disorders</td>
                  <select onClick={e => {e.stopPropagation()}} name='social_history_eating' >
                    {social_histories_eating}
                  </select>
              </tr>
              {alcohol_question}
              <tr>
                <td><span class="space"></span>Additional notes for social history</td>
                  <input type="text" name='social_history_notes' defaultValue={patient.social_history_notes} />
              </tr>
              <tr>
                <td><span class="space"></span>Past history of counseling:</td>
                  <select onClick={e => {e.stopPropagation()}} name='counseling_history' >
                    {counseling_histories}
                  </select>
              </tr>
              <tr>
                <td>Mental Illness</td>
                  <select onClick={e => {e.stopPropagation()}} name='mental_illness' >
                    {mental_illnesses}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Diagnosis & medications</td>
                  <input type="text" name='mental_illness_diagnosis_medications' defaultValue={patient.mental_illness_diagnosis_medications} />
              </tr>
              <tr>
                <td><span class="space"></span>Symptoms manageable with current medication</td>
                  <select onClick={e => {e.stopPropagation()}} name='mental_illness_symptoms_manageable' >
                    {mental_illnesses_symptoms}
                  </select>
              </tr>
              <tr>
                <td>Suicidal Ideation: Detailed notes - full sentence(s)</td>
                  <select onClick={e => {e.stopPropagation()}} name='suicidal_ideation' >
                    {suicidal_ideations}
                  </select>
              </tr>
              <tr>
                <td><span class="space"></span>Detailed notes</td>
                  <input type="text" name='suicidal_ideation_notes' defaultValue={patient.suicidal_ideation_notes} />
              </tr>
              <tr>
                <td>Tobacco Use</td>
                  <select onClick={e => {e.stopPropagation()}} name='tobacco_use_form' >
                    {tobacco_uses}
                  </select>
              </tr>
              <tr>
                <td>Tobacco Quit Date (if historical)</td>
                  <td>                 
                   <input type="text" name='tobacco_quit_date' defaultValue={patient.tobacco_quit_date} />

                  </td>
              </tr>
              <tr>
                <td>Efforts to stop smoking (if current) - full sentence(s)</td>
                  <input type="text" name='tobacco_quit_efforts' defaultValue={patient.tobacco_quit_efforts} />
              </tr>
              <tr><td></td></tr>

              <tr>
                <td>PHQ 15</td>
                <td>{patient.test_results.phq_15_raw_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity level</td>
                  <select onClick={e => {e.stopPropagation()}} name='phq_15_score' >
                    {phq_15_scores}
                  </select>
              </tr>
              <tr>
                <td>GAD-7</td>
                <td>{patient.test_results.gad_7_raw_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='gad_7_score' >
                    {gad_7_scores}
                  </select>
              </tr>
              <tr>
                <td>PHQ-9</td>
                <td>{patient.test_results.phq_9_raw_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='phq_9_score' >
                    {phq_9_scores}
                  </select>
              </tr>
              <tr>
                <td>Psychological Factors</td>
                  <select onClick={e => {e.stopPropagation()}} name='phq_psych_factors' >
                    {phq_psych_factors}
                  </select>
              </tr>
              <tr>
                <td>Panic Attacks</td>
                  <select onClick={e => {e.stopPropagation()}} name='panic_attacks' >
                    {panic_attacks}
                  </select>
              </tr>
              <tr><td></td></tr>

              <tr>
                <td>DASS-21</td>
                <td>{patient.test_results.dass_21_d_adjusted_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_21_d_score' >
                    {dass_21_d_scores}
                  </select>
              </tr>
              <tr>
                <td>Anxiety Scale</td>
                <td>{patient.test_results.dass_21_a_adjusted_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_21_a_score' >
                    {dass_21_a_scores}
                  </select>
              </tr>
              <tr>
                <td>Stress</td>
                <td>{patient.test_results.dass_21_s_adjusted_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Severity</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_21_s_score' >
                    {dass_21_s_scores}
                  </select>
              </tr>
              <tr>
                <td>Psychological Factors</td>
                  <select onClick={e => {e.stopPropagation()}} name='dass_psych_factors' >
                    {dass_psych_factors}
                  </select>
              </tr>
              <tr><td></td></tr>

              <tr>
                <td>EAT26</td>
                <td>{patient.test_results.eat_26_raw_score}</td>
              </tr>
              <tr>
                <td><span class="space"></span>Range</td>
                  <select onClick={e => {e.stopPropagation()}} name='eat_26_score' >
                    {eat_26_scores}
                  </select>
              </tr>
              <tr>
                <td>Binge Eating</td>
                  <select onClick={e => {e.stopPropagation()}} name='binge_eating_form' >
                    {binge_eating}
                  </select>
              </tr>
              <tr>
                <td>Self-induced vomiting</td>
                  <select onClick={e => {e.stopPropagation()}} name='self_induced_vomiting' >
                    {self_induced_vomiting}
                  </select>
              </tr>
              <tr>
                <td>Laxative Abuse</td>
                  <select onClick={e => {e.stopPropagation()}} name='laxative_use' >
                    {laxative_use}
                  </select>
              </tr>
              <tr>
                <td>Eating Disorder Treatment</td>
                  <select onClick={e => {e.stopPropagation()}} name='eating_disorder_treatment' >
                    {eating_disorder_treatment}
                  </select>
              </tr>
              <tr>
                <td>Eating Disorder Behaviors</td>
                  <select onClick={e => {e.stopPropagation()}} name='eating_disorder_behaviors' >
                    {eating_disorder_behaviors}
                  </select>
              </tr>
              <tr><td></td></tr>

              <tr>
                <td>Weight goal</td>
                  <input type="text" name='weight_goal' defaultValue={patient.weight_goal} />
              </tr>
              <tr>
                <td>Support Network</td>
                  <input type="text" name='support_network' defaultValue={patient.support_network} />
              </tr>
              <tr>
                <td>Is patient suitable for surgery?</td>
                  <select onClick={e => {e.stopPropagation()}} name='suitable_for_surgery' >
                    {suitables}
                  </select>
              </tr>
              <tr>
                <td>Recommendation</td>
                  <select onClick={e => {e.stopPropagation()}} name='recommendation' >
                    {supports}
                  </select>
              </tr>

            </tbody>
          </table>
          </form>
          </div>
        </div>
      );
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.patient) return '';
    else {
    	//let patient = this.state.patient;
    	let that = this;
  		
      let patient = parsePatient(this.state.patient);  		

      if(this.state.level === 'therapist' || this.state.level === 'super_therapist') {
        if(patient.procedure.indexOf('SCS') > -1 || patient.procedure.indexOf('Spinal Cord Stimulator') > -1 || patient.procedure.indexOf('Opioid Risk') > -1)
          return this.therapist_scs(patient);
        else 
          return this.therapist(patient);
      }
      else {
        window.location.href = window.location.href.replace('_form','')
      }
    }
  }
}

export default TherapistPatientForm;